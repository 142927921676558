import {get} from "../../httpclient/http-client";
import store from "../../store";
import ErrorInfo from "../../classes/util/ErrorInfo";

export default {
    downloadReport(assignedTestId) {
        let request = get('/api/manager/tests/assigned/' + assignedTestId + "/report", {
            headers: {Accept: 'application/pdf'},
            responseType: 'blob'
        });
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить отчет.',
                    request.id)))
    },
    getReport(assignedTestId) {
        let request = get('/api/manager/tests/assigned/' + assignedTestId + "/report", {
            headers: {Accept: 'application/pdf'},
            responseType: 'blob'
        });
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить отчет.',
                    request.id)))
    }
}