<template>
  <v-container
      class="fill-height"
      fluid
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
          xl="3"
      >
        <v-form lazy-validation
                ref="form">
          <v-card flat>
            <v-layout justify-center>
              <v-img max-width="300" src="/images/PsyScanner.png"/>
            </v-layout>
            <v-card-title class="justify-center">
              Форма смена пароля
            </v-card-title>
            <v-card-text class="pt-0 text-center">
              <v-form ref="addingForm">
                <v-text-field
                    :rules="[rules.isRequired, rules.isStringShort]"
                    color="primary"
                    label="Новый пароль"
                    name="password"
                    v-model="newPass"
                    :prepend-icon="equalsPass ? 'mdi-lock' :'mdi-lock-alert'"
                    type="password"/>
                <v-text-field
                    :rules="[rules.isRequired, passwordConfirmationRule, rules.isStringShort]"
                    color="primary"
                    label="Повторите пароль"
                    name="repeatedPassword"
                    v-model="rePass"
                    :prepend-icon="equalsPass ? 'mdi-lock' :'mdi-lock-alert'"
                    type="password"/>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn :loading="isLoading" color="primary" small dark class="btn" @click="changePass">Сменить</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapMutations} from "vuex";
import {post} from "../../httpclient/http-client";
import ErrorInfo from "../../classes/util/ErrorInfo";
import rules from "../../scripts/rules";

export default {
  name: "ResetPassword",
  props: ['uuid'],
  computed: {
    equalsPass() {
      if (this.newPass === '') {
        return false
      }
      return this.newPass === this.rePass
    },
    passwordConfirmationRule() {
      return () => this.equalsPass || 'Новые пароли не совпадают'
    },
  },
  data() {
    return {
      isOpenDialog: false,
      credName: '',
      newPass: '',
      rePass: '',
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT_BASED_ON_CODE', 'ACTIVATE_INFO_ALERT']),
    openDialog() {
      this.isOpenDialog = true;
    },
    changePass() {
      if (this.$refs.addingForm.validate()) {
        this.isLoading = true
        let request = post('/api/manager/operations/' + this.uuid + '/reset/password', {
          newPassword: this.newPass,
        });
        request.call
            .then(() => {
              this.ACTIVATE_INFO_ALERT('Пароль успешно изменен!')
              this.rePass = ''
              this.newPass = ''
              this.$router.push({path: '/login', addToHistory: false})
            })
            .catch(er =>
                this.ACTIVATE_ALERT_BASED_ON_CODE(new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке изменить пароль.',
                    request.id)))
            .finally(() => this.isLoading = false)
      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для изменения пароля, проверьте данные " +
            "и попробуйте снова.");
      }
    },
    closeDialog() {
      this.isOpenDialog = false;
      this.$refs.addingForm.reset()
      this.$emit('resetState')
    }
  }
}
</script>

<style scoped>

</style>
