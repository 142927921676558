var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"lg":"10","offset-lg":"1"}},[_c('v-container',[_c('h1',{staticClass:"display-1 pb-3"},[_vm._v("Назначенные тесты")]),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-spacer'),_c('v-text-field',{staticClass:"ml-10 shrink",attrs:{"prepend-icon":"mdi-magnify","clearable":"","single-line":"","label":"Поиск:"},on:{"click:clear":_vm.clearSearch,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.initRequestAssignedTests.apply(null, arguments)},"click:prepend":_vm.initRequestAssignedTests},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"mt-5 mb-5",attrs:{"flat":"","loading":_vm.isTestsLoading,"headers":_vm.headers,"items":_vm.assignedTests,"hide-default-footer":""},on:{"click:row":_vm.openAssignedTest},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-end":""}},[(item.status.value ==='ASSIGNED' && !_vm.isDeletedEntities(item))?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.cancelAssignedTest(item)}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Отменить назначенный тест")])]):_vm._e()],1)]}},{key:"item.user.login",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.login)+" "),(_vm.utils.isDeletedEntity(item.user))?_c('tooltip-user-deleted',{attrs:{"size":'small',"location":'bottom'}}):_vm._e()]}},{key:"item.test.name",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"align-center":"","justify-start":""}},[_vm._v(" "+_vm._s(item.test.name)+" "),(item.test.weight===0)?_c('tooltip-trial-test',{attrs:{"size":20}}):_vm._e(),(_vm.utils.isDeletedEntity(item.test))?_c('tooltip-test-deleted',{attrs:{"size":'small',"location":'bottom'}}):_vm._e(),(item.test.isPublic)?_c('tooltip-public-test',{attrs:{"size":25}}):_vm._e()],1)]}},{key:"item.status.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.utils.resolveAssignedTestStatus(item.status.value))+" ("+_vm._s(_vm.utils.preparedViewDate(item.status.datetime, 'datetime'))+") ")]}}])})],1),_c('confirmation-dialog',{ref:"confirmationDialog"})],1)],1),_c('v-bottom-navigation',{attrs:{"absolute":"","hide-on-scroll":"","horizontal":"","grow":""}},[(_vm.pageCount>1)?_c('v-pagination',{attrs:{"length":_vm.pageCount},on:{"input":_vm.requestAssignedTests},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }