export default class OrganizationDTO {
    constructor(id, name, email, phoneNumber, legalAddress, taxpayerNumber, kpp) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.legalAddress = legalAddress;
        this.taxpayerNumber = taxpayerNumber;
        this.kpp = kpp;
    }
}
