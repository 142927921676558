<template>
  <v-container
      class="fill-height"
      fluid
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          style="text-align: center"
          cols="12"
          xl="6"
          lg="6"
          sm="12"
          md="9"
      >
        <v-img contain max-height="120"
               src="/images/PsyScannerLogo.png"/>
        <br>
        <v-btn color="primary" small dark class="mb-5" @click="openAboutMePage">Личный кабинет</v-btn>
        <h3>{{ confirmStatus }}</h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import emailApi from '../../scripts/api/email-api'
import {mapState} from "vuex";

export default {
  props: ['confirmationId'],
  name: "ConfirmationEmailPage",
  computed: {...mapState(['currentUser'])},
  data: () => {
    return {
      confirmStatus: '',
      timeoutTask: null
    }
  },
  methods: {
    openAboutMePage() {
      this.$router.push({path: '/about-me', addToHistory: false})
      clearTimeout(this.timeoutTask)
    }
  },
  mounted() {
    emailApi.confirmEmail(this.confirmationId).call
        .then(() => {
          this.confirmStatus = 'Почта успешно подтверждена'
          this.timeoutTask = setTimeout(() => this.openAboutMePage(), 5_000)
        })
        .catch(er => this.confirmStatus = er.response.status === 404 ?
            'Ссылка подтверждения почты не действительна' :
            'Произошла ошибка при подтверждение почты, попробуйте позже')
  }
}
</script>

<style scoped>

</style>