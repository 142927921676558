<template>
  <v-tooltip
      :right="location === 'right'"
      :left="location === 'left'"
      :bottom="location === 'bottom'"
      :top="location === 'top'"
      color="grey">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" style="color: #e00000" :large="size==='large'" :small="size==='small'">
        mdi-domain-remove
      </v-icon>
    </template>
    <span class="white--text">Организация удалена</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "TooltipTestDeleted",
  props: ['size', 'location']
}
</script>

<style scoped>

</style>