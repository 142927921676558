export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const PHONE_REGEX = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){6,14}(\s*)?$/
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#\\$%\\^\\&*\\')\\(+=._-]{8,}$/

function isLength128(value) {
    return value !== null && value.length <= 128 || 'Длина поля не должна превышать ' + 128 + ' символов. ' +
        'Пробелы в подсчете учитываются.'
}

function isRequired(value) {
    return !!value || 'Обязательное поле.'
}

function isInt32(value) {
    return value < 2_147_483_647 || 'Значение не должно превышать ' + 2_147_483_647 + ' символов. ' +
        'Пробелы в подсчете учитываются.'
}

function checkStimulusLength(value) {
    return value !== null && value.length <= 13 || 'Длина стимула не должна превышать 13 символов. ' +
        'Пробелы в подсчете учитываются.'
}

function isStringShort(value) {
    return value !== null && value.length <= 255 || 'Длина поля не должна превышать ' + 255 + ' символов. ' +
        'Пробелы в подсчете учитываются.'
}

function isStringLong(value) {
    return value !== null && value.length <= 2048 || 'Длина поля не должна превышать ' + 2048 + ' символов. ' +
        'Пробелы в подсчете учитываются.'
}

function isSuccessEmail(value) {
    if (value === null || value === undefined || value.length === 0) {
        return true
    }
    return EMAIL_REGEX.test(value) || 'Неправильный формат e-mail.'
}

function isSuccessPhoneNumber(value) {
    if (value === null || value === undefined || value.length === 0) {
        return true
    }
    return PHONE_REGEX.test(value) || 'Неправильный формат номера телефона (Пример: 8-910-998-99-88, 93-99-43)'
}

function isValidPassword(value) {
    if (value === null || value === undefined || value.length === 0) {
        return true
    }
    return value.length >= 8 || 'Пароль должен состоять минимум из 8 символов'
}

function minSum(value) {
    return (value && value > 0) || "Сумма должны быть больше 0"
}

function notNegative(value) {
    return (value >= 0 && value !== '') || "Должно быть больше 0"
}

function notEmptyList(value) {
    if (value === null || value === undefined) {
        return true
    }
    return value.length > 0 || 'Список не должен быть пуст'
}

export default {
    isInt32,
    isLength128,
    isRequired,
    isStringShort,
    isStringLong,
    isSuccessEmail,
    isSuccessPhoneNumber,
    isValidPassword,
    minSum,
    notNegative,
    notEmptyList,
    checkStimulusLength
}