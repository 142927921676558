<template>
  <div>
    <v-dialog persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-title>
            Форма создания новой темы теста
            <v-spacer/>
            <v-tooltip max-width="350" left color="grey darken-1">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-information-outline</v-icon>
              </template>
              <span class="white--text">Для создания темы,<br>необходимо задать 5 стимулов.</span>
            </v-tooltip>
            <v-btn class="ml-5 mr-3" icon color="primary" @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-stepper v-model="currentStep">
              <v-stepper-header>
                <v-stepper-step :complete="currentStep > 1" step="1">
                  <b>Основные данные</b>
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete="currentStep > 2" step="2">
                  <b>Стимулы</b>
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete="currentStep > 3" step="3">
                  <b>Уровни риска</b>
                </v-stepper-step>
                <v-stepper-step :complete="currentStep > 4" step="4">
                  <b>Подтверждение</b>
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card flat>
                    <small>
                      Заполните основные данные о топике
                    </small>
                    <v-form ref="mainForm">
                      <v-text-field :rules="[rules.isRequired, rules.isStringShort]" v-model="topicName"
                                    label="Имя темы"/>
                    </v-form>
                    <v-card-actions>
                      <v-spacer/>
                      <v-btn :disabled="topicName == null || topicName.length === 0" small color="primary"
                             @click="currentStep = 2">
                        Далее
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-form ref="stimulusForm" v-model="isValidStimulusForm">
                    <v-card flat>
                      <small>
                        Заполните стимулы, размер стимула не должен превышать 13 символов.
                      </small>

                      <v-text-field
                          v-for="(item, index) in listStimulus"
                          :key="index"
                          :rules="[rules.isRequired, rules.checkStimulusLength]"
                          color="primary"
                          v-model="item.stimulus"
                          :label='`Стимул: `+ incrementIndex(index)'
                      />

                      <v-card-actions>
                        <v-btn small @click="currentStep = 1">Назад</v-btn>
                        <v-spacer/>
                        <v-btn small :disabled="!isValidStimulusForm" color="primary"
                               @click="currentStep = 3">Далее
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-stepper-content>


                <v-stepper-content step="3">
                  <v-form ref="risksForm" v-model="isValidRisksForm">
                    <v-card flat>
                      <small>
                        Заполните интерпретации уровня риска
                      </small>
                      <v-textarea rows="1" auto-grow :rules="[rules.isRequired, rules.isStringLong]"
                                  v-model="lowRiskLevel"
                                  label="Интерпретация низкого уровня риска"/>
                      <br>
                      <v-textarea rows="1" auto-grow :rules="[rules.isRequired, rules.isStringLong]"
                                  v-model="midRiskLevel"
                                  label="Интерпретация среднего уровня риска"/>
                      <br>
                      <v-textarea rows="1" auto-grow :rules="[rules.isRequired, rules.isStringLong]"
                                  v-model="highRiskLevel"
                                  label="Интерпретация высокого уровня риска"/>
                      <v-card-actions>
                        <v-btn small @click="currentStep = 2">Назад</v-btn>
                        <v-spacer/>
                        <v-btn small :disabled="!isValidRisksForm" color="primary"
                               @click="currentStep = 4">Далее
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content step="4">
                  <v-card flat>
                    <small>
                      Вы хотите создать топик со следующими параметрами
                    </small>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th v-for="header in headers"
                              :key="header" class="text-left">
                            {{ header }}
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>{{ topicName }}</td>
                          <td>{{ listStringStimulus }}</td>
                          <td>{{ lowRiskLevel }}</td>
                          <td>{{ midRiskLevel }}</td>
                          <td>{{ highRiskLevel }}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <br>
                    <v-card-actions>
                      <v-btn small @click="currentStep = 3">Назад</v-btn>
                      <v-spacer/>
                      <v-btn small color="primary" @click="createTopic" :loading="isLoading">Создать</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import TopicDTO from "../../../../classes/dto/TopicDTO";
import topicsApi from "../../../../scripts/api/topics-api";
import rules from "../../../../scripts/rules";

export default {
  name: "UserCreateForm",
  computed: {
    listStringStimulus() {
      if (this.currentStep !== 4) {
        return ''
      }
      let stimulus = ''
      this.listStimulus.forEach(item => {
        stimulus += " " + item.stimulus
      })
      return stimulus
    }
  },
  data() {
    return {
      menu: false,
      currentStep: 1,
      isOpenDialog: false,
      isValidStimulusForm: false,
      isValidRisksForm: false,
      lastIndex: 0x7fffffff,
      listStimulus: [
        {orders: [1], stimulus: ''},
        {orders: [2], stimulus: ''},
        {orders: [3], stimulus: ''},
        {orders: [4], stimulus: ''},
        {orders: [5], stimulus: ''}],
      topicName: '',
      highRiskLevel: '',
      midRiskLevel: '',
      lowRiskLevel: '',
      rules: rules,
      isValidStepStimulus: false,
      isLoading: false,
      headers: [
        'Имя темы',
        'Список стимулов',
        'Интерпретация низкого уровня риска',
        'Интерпретация среднего уровня риска',
        'Интерпретация высокого уровня риска'
      ]
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT']),
    openDialog() {
      if (this.$refs.mainForm !== undefined) {
        this.$refs.mainForm.reset()
      }
      if (this.$refs.risksForm !== undefined) {
        this.$refs.risksForm.reset()
      }
      if (this.$refs.stimulusForm !== undefined) {
        this.$refs.stimulusForm.reset()
      }
      this.isOpenDialog = true
    },
    createTopic() {
      this.isLoading = true
      let topicDTO = new TopicDTO(null,
          this.topicName,
          this.listStimulus,
          this.highRiskLevel,
          this.midRiskLevel,
          this.lowRiskLevel);
      topicsApi.addTopic(topicDTO).then(response => {
        this.$emit('successCreate', response.data)
        this.isOpenDialog = false
        this.listStimulus = [
          {orders: [1], stimulus: ''},
          {orders: [2], stimulus: ''},
          {orders: [3], stimulus: ''},
          {orders: [4], stimulus: ''},
          {orders: [5], stimulus: ''}]
        this.topicName = ''
        this.currentStep = 1
      }).finally(() => this.isLoading = false)
    },
    incrementIndex(index) {
      return ++index
    },
    closeDialog() {
      this.isOpenDialog = false
      this.$refs.mainForm.reset()
      this.$refs.risksForm.reset()
      this.$refs.stimulusForm.reset()
      this.currentStep = 1
    }
  }
}
</script>

<style scoped>
.v-stepper, .v-stepper__header {
  box-shadow: 0px 0px !important;
}
</style>
