<template>
  <v-card class="pb-5 ma-0" flat min-height=500>
    <v-data-table
        flat
        :loading="isLoading"
        :headers="headers"
        :items="tests"
        @click:row="openAssignedTest"
        hide-default-footer
    >
      <template v-slot:top>
        <v-row>
          <v-spacer/>
          <v-text-field
              prepend-icon="mdi-magnify"
              label="Поиск"
              clearable
              @keydown.enter="initRequestAssignedTests"
              @click:prepend="initRequestAssignedTests"
              @click:clear="clearSearch"
              class="shrink"
              v-model="search"/>
        </v-row>
      </template>
      <template v-slot:item.test.name="{ item }">
        <v-layout align-center justify-start>
          {{ item.test.name }}
          <tooltip-trial-test :size="20" v-if="item.test.weight === 0"/>
          <tooltip-public-test :size="'small'" v-if="item.test.isPublic"/>
          <tooltip-test-deleted :size="'small'" v-if="item.test.state==='DELETED'" :location="'bottom'"/>
        </v-layout>


      </template>
      <template v-if="role!=='admin'" v-slot:item.actions="{ item }">
        <v-layout justify-end>
          <v-tooltip v-if="item.status.value ==='ASSIGNED' && !isDeletedEntities(item)" bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-btn small icon @click.stop="cancelAssignedTest(item)" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span class="white--text">Отменить назначенный тест</span>
          </v-tooltip>
        </v-layout>
      </template>
      <template v-slot:item.expiredDate="{item}">
        {{ utils.preparedViewDate(item.expiredDate, 'date') }}
      </template>
      <template v-slot:item.status.value="{item}">
        {{ utils.resolveAssignedTestStatus(item.status.value) }} ({{ utils.preparedViewDate(item.status.datetime, 'datetime') }})
      </template>
    </v-data-table>
    <v-bottom-navigation
        absolute
        hide-on-scroll
        horizontal
        grow
    >
      <v-pagination v-if="pageCount>1" @input="requestAssignedTests" v-model="page" :length="pageCount"/>
    </v-bottom-navigation>
  </v-card>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import assignedApi from '../../../../scripts/api/assigned-api'
import utils from "../../../../scripts/utils";

import TooltipTestDeleted from "../../../common/tooltips/TooltipTestDeleted";
import TooltipTrialTest from "../../../common/tooltips/TooltipTrialTest";
import TooltipPublicTest from "../../../common/tooltips/TooltipPublicTest";

export default {
  components: {TooltipTestDeleted, TooltipTrialTest, TooltipPublicTest},
  name: "AssignedTestTable",
  props: ['userId'],
  computed: {
    ...mapState(['role'])
  },
  data() {
    return {
      utils: utils,
      tests: [],
      limit: 7,
      search: '',
      page: 1,
      pageCount: 0,
      isLoading: false,
      headers: [
        {text: 'ID', align: ' d-none', value: 'id', sortable: false},
        {text: 'Тест', value: 'test.name', sortable: false},
        {text: 'Текущий статус', value: 'status.value', sortable: false},
        {text: 'Действителен до', value: 'expiredDate', sortable: false},
        {text: '', value: 'actions', sortable: false}
      ],
    }
  },
  mounted() {
    this.requestAssignedTests()
  },
  methods: {
    ...mapMutations('confirmationModule', ['openConfirmationDialog']),
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT_BASED_ON_CODE', 'ACTIVATE_INFO_ALERT']),
    clearSearch() {
      this.search = ''
      this.initRequestAssignedTests()
    },
    initRequestAssignedTests() {
      this.resetPage()
      this.requestAssignedTests()
    },
    resetPage() {
      this.page = 1
    },
    requestAssignedTests() {
      if (this.userId === undefined) {
        return
      }
      this.isLoading = true
      let offset = this.page - 1
      assignedApi.getTestsAssignedToUser(this.userId, this.search, this.limit, offset)
          .then(response => {
            this.pageCount = response.data.count
            this.tests = response.data.content
          })
          .finally(() => this.isLoading = false)
    },
    cancelAssignedTest(item) {
      let action = () => assignedApi.cancelAssignedTest(item.id)
          .then(response => {
            let needIndex = this.tests.findIndex(at => at.id === item.id)
            this.tests = [
              ...this.tests.slice(0, needIndex),
              response.data,
              ...this.tests.slice(needIndex + 1,)]
          })

      this.openConfirmationDialog({
        action: action,
        description: ' отменить назначенный тест "' + item.test.name + '" на пользователя "' + item.user.lastName + " " +
            item.user.firstName + '", который был назначен ' + utils.preparedViewDate(item.statuses[0].datetime, 'datetime')
      })
    },
    openAssignedTest(assignedTest) {
      this.$router.push('/manager/assigned-test/' + assignedTest.id);
    },
    isDeletedEntities(assignedTest) {
      return utils.isDeletedEntity(assignedTest.user) || utils.isDeletedEntity(assignedTest.test)
    },
  }
}
</script>

<style scoped>
>>> .v-data-table tr {
  cursor: pointer !important;
}

>>> .v-data-table th {
  font-size: 1.1em;
  font-weight: 700 !important;
}

>>> .v-data-table td {
  font-size: 1em !important;
}
</style>
