<template>
  <v-container
      class="fill-height"
      fluid
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
          xl="3"
      >


        <v-card class="pa-10" flat max-width="550">
          <v-layout justify-center>
            <v-img max-width="300" src="/images/PsyScanner.png"/>
          </v-layout>
          <v-tabs centered>
            <v-tab>Физическое лицо</v-tab>
            <v-tab>Юридическое лицо</v-tab>

            <v-tab-item>
              <individual-registration-form/>
            </v-tab-item>
            <v-tab-item>
              <legal-registration-form/>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapMutations} from "vuex";
import ErrorInfo from "../../classes/util/ErrorInfo";
import {post} from "../../httpclient/http-client";
import IndividualRegistrationForm from "./forms/IndividualRegistrationForm";
import LegalRegistrationForm from "./forms/LegalRegistrationForm";

export default {
  name: "RegistrationPage",
  computed: {
    passwordConfirmationRule() {
      return () => (this.pass === this.rePass) || 'Пароли не совпадают'
    },
    recaptchaSiteKey() {
      return process.env.VUE_APP_RE_CAPTCHA_SITE
    }
  },
  components: {IndividualRegistrationForm, LegalRegistrationForm},
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT_BASED_ON_CODE', 'ACTIVATE_ALERT']),
    cancel() {
      this.$refs.form.reset()
      this.$router.back()
    },
    afterVerifyCaptcha(recaptchaToken) {
      let registrationDTO = {
        username: this.username,
        password: this.pass,
        firstname: this.firstName,
        lastname: this.lastName,
        birthday: this.birthday,
        email: this.email,
        phoneNumber: this.phone,
        companyname: this.orgName
      };
      let request = post('/api/manager/auth/registration', registrationDTO, {
        headers: {
          'g-recaptcha-response': recaptchaToken
        }
      });
      request.call
          .then(() => {
            this.$router.push('/login')
            this.$refs.form.reset()
          })
          .catch(er => {
            if (er.response.status === 409) {
              this.ACTIVATE_ALERT("Пользователь с такой почтой или логином уже существует")
            } else {
              this.ACTIVATE_ALERT_BASED_ON_CODE(new ErrorInfo(er.response.status, er.response.data.message, request.id))
            }
          })
          .finally(() => this.captchaReset())
    },
    registration() {
      this.$refs.form.validate()
      if (this.valid) {
        this.$refs.recaptcha.execute()
      }
    },
    captchaReset() {
      this.$refs.recaptcha.reset()
    },
    saveDateBirthday(newV) {
      this.birthday = newV
    }
  }
}
</script>

<style scoped>

</style>