<template>
  <v-container
      class="fill-height"
      fluid
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
          xl="3"
      >
        <v-form lazy-validation
                ref="form">
          <v-card class="pa-10" flat>
            <v-layout justify-center>
              <v-img max-width="300" src="/images/PsyScanner.png"/>
            </v-layout>
            <v-card-text class="pr-0 pl-0 pb-0">
              <v-text-field
                  label="Пользователь (логин или email)"
                  v-model="username"
                  prepend-icon="mdi-account"
                  :rules="[rules.isRequired, rules.isLength128]"/>

              <v-text-field
                  class="pb-0"
                  @keyup.enter="login"
                  :rules="[rules.isRequired, rules.isStringShort]"
                  color="primary"
                  id="password"
                  label="Пароль"
                  v-model="pass"
                  prepend-icon="mdi-lock"
                  type="password"
                  ref="passwordEl"
              />
              <router-link
                  to="/account-recovery"
                  custom
              >
                <span style="padding-left:30px; text-decoration: underline; cursor: pointer; color: #008000">
                  Проблема со входом в систему?
                </span>
              </router-link>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn @click="registration" color="primary" class="mr-5" small>Зарегистрироваться</v-btn>
              <vue-recaptcha size="invisible"
                             @verify="afterVerifyCaptcha"
                             ref="recaptcha"
                             @expired="captchaReset"
                             :sitekey="recaptchaSiteKey"/>
              <v-btn :disabled="isLoading" @click="login" color="primary" small>
                <div class="pr-2" v-if="isLoading">
                  <v-progress-circular
                      indeterminate
                      width="2"
                      size="20"/>
                </div>
                Войти
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapMutations} from "vuex";

import ErrorInfo from "../../classes/util/ErrorInfo";
import VueRecaptcha from "vue-recaptcha";
import {get, post} from "../../httpclient/http-client";
import store from "../../store";
import rules from "../../scripts/rules";

export default {
  name: "LoginForm",
  components: {VueRecaptcha},
  computed: {
    recaptchaSiteKey() {
      return process.env.VUE_APP_RE_CAPTCHA_SITE
    }
  },
  data: () => {
    return {
      username: '',
      pass: '',
      isLoading: false,
      rules: rules
    }
  },
  methods: {
    ...mapMutations(['setToken', 'setCurrentUser']),
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT_BASED_ON_CODE', 'ACTIVATE_ALERT', 'DEACTIVATE_ALERT']),
    login() {
      this.DEACTIVATE_ALERT()
      let form = this.$refs.form;
      if (form.validate()) {
        this.$refs.recaptcha.execute()
      }
    },
    afterVerifyCaptcha(recaptchaToken) {
      this.isLoading = true
      let loginPostRequest = post('/api/manager/auth/login', {username: this.username, password: this.pass}, {
        headers: {
          'g-recaptcha-response': recaptchaToken
        }
      });
      loginPostRequest.call
          .then(response => {
            this.setToken(response.data.token)
            this.$refs.form.reset()
            this.$refs.passwordEl.blur()
            get('/api/manager/auth/me').call
                .then(response => {
                  store.commit('setCurrentUser', response.data)
                  this.$router.replace({path: '/about-me'})
                })
                .catch(() => {
                  store.commit('clearSession', null, {root: true})
                  this.$router.push({path: '/login', addToHistory: false})
                })
                .finally(() => this.isLoading = false)
          })
          .catch(er => {
            this.isLoading = false
            if (er.response.status && er.response.data.message === 'Bad credentials') {
              this.ACTIVATE_ALERT("Неверный логин или пароль")
            } else {
              this.ACTIVATE_ALERT_BASED_ON_CODE(
                  new ErrorInfo(er.response.status,
                      er.response.data.message,
                      loginPostRequest.id))
            }
          })
          .finally(() => this.captchaReset())
    },
    captchaReset() {
      this.$refs.recaptcha.reset()
    },
    registration() {
      this.$router.push('/registration')
    }
  }
}
</script>

<style scoped>

</style>