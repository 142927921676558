<template>
  <div>
    <v-dialog persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-title>
            Изменение данных об орагиназации {{ editedOrg.name }}
          </v-card-title>
          <v-card-text>
            <v-form ref="updateForm">
              <v-text-field label="Наименование *" v-model="name"
                            :rules="[rules.isRequired]"/>
              <v-text-field label="Телефон *" v-model="phoneNumber"
                            :rules="[rules.isRequired, rules.isSuccessPhoneNumber]"/>
              <v-text-field label="Адрес электронной почты *" v-model="email"
                            :rules="[rules.isRequired, rules.isSuccessEmail]"/>
              <!--              <v-text-field label="Юридический адрес:" v-model="editedOrg.legalAddress"-->
              <!--                            :rules="[rules.notEmptyRul]"/>-->
              <!--              <v-text-field label="ИНН:" v-model="editedOrg.taxpayerNumber"/>-->
              <!--              <v-text-field label="КПП:" v-model="editedOrg.kpp"/>-->
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" small dark @click="closeDialog">Отменить</v-btn>
            <v-btn color="primary" small dark class="btn" @click="updateOrg" :loading="isLoading">Сохранить</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OrganizationDTO from "../../../classes/dto/OrganizationDTO";

import rules from "../../../scripts/rules";
import orgApi from '../../../scripts/api/org-api'
import {mapMutations} from "vuex";

export default {
  name: "OrgUpdateForm",
  data() {
    return {
      isOpenDialog: false,
      id: '',
      name: '',
      phoneNumber: '',
      email: '',
      rules: rules,
      editedOrg: {},
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT']),
    updateOrg() {
      if (this.$refs.updateForm.validate()) {
        this.isLoading = true
        let updatedOrg = new OrganizationDTO(this.id, this.name, this.email, this.phoneNumber, null,
            null, null);
        orgApi.updateOrg(updatedOrg)
            .then(response => {
              console.log("Updated org. Status: ", response.status)
              this.$emit('successUpdate', updatedOrg)
              this.closeDialog();
            }).finally(() => this.isLoading = false)
      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для обновления данных об организации: " + this.editedOrg.name +
            ", проверьте данные и попробуйте снова.");
      }
    },
    clearData() {
      this.id = ''
      this.name = ''
      this.phoneNumber = ''
      this.email = ''
    },
    openDialog(editedOrg) {
      this.editedOrg = editedOrg
      this.isOpenDialog = true
      this.id = editedOrg.id
      this.name = editedOrg.name
      this.phoneNumber = editedOrg.phoneNumber
      this.email = editedOrg.email
    },
    closeDialog() {
      this.isOpenDialog = false;
      this.clearData()
    }
  }
}
</script>

<style scoped>

</style>
