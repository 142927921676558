<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <v-skeleton-loader :loading="isLoading" type="article">
          <v-row>
            <v-col xl="12" lg="12" md="12" sm="12">
              <h1 v-if="currentStatus === 'CANCEL'" class="display-1">Тест отменен</h1>
              <h1 class="display-1">{{ currentStatus === 'ASSIGNED' ? 'Тест назначен' : 'Результаты теста' }}</h1>
            </v-col>
            <v-col xl="12" lg="12" md="12" sm="12">
              <v-btn v-if="currentStatus === 'ASSIGNED' && !isDeletedEntities" small color="primary" @click="cancel">
                <v-icon>mdi-orbit-variant</v-icon>
                Отменить
              </v-btn>
              <download-report-button v-if="isAnalyzedTest" :assignedTestId="assignedTestId"/>
            </v-col>
          </v-row>
          <br>
          <v-row>
            <v-col lg="12">
              <br>
              <v-row>
                <v-col lg="3" md="6" sm="6">
                  <b>Название теста</b>
                </v-col>
                <v-col lg="9" md="6" sm="6">
                  <v-layout align-center justify-start>
                    <router-link :class="utils.isDeletedEntity(assignedTest.test)? 'deletedColor':'successColor'"
                                 :to="'/manager/test-info/'+assignedTest.test.id">
                      <b>{{ assignedTest.test.name }}</b>
                    </router-link>
                    <tooltip-trial-test :size="30" v-if="assignedTest.test.weight === 0"/>
                    <tooltip-public-test v-if="assignedTest.test.isPublic"/>
                    <tooltip-test-deleted v-if="utils.isDeletedEntity(assignedTest.test)" :location="'bottom'"/>
                  </v-layout>
                </v-col>
                <v-col v-if="currentStatus ==='ASSIGNED' && !isDeletedEntities" lg="3" md="6" sm="6">
                  <b>Код доступа</b>
                </v-col>
                <v-col v-if="currentStatus ==='ASSIGNED' && !isDeletedEntities" lg="9" md="6" sm="6">
                  {{ assignedTest.code }}
                  <v-tooltip max-width="350" bottom color="grey darken-1">
                    <template v-slot:activator="{ on }">
                      <v-btn class="mr-1" @click="refreshCode" v-on="on" x-small icon>
                        <v-icon color="primary">mdi-update</v-icon>
                      </v-btn>
                    </template>
                    <span class="white--text">Обновить код доступа</span>
                  </v-tooltip>
                  <v-tooltip max-width="350" bottom color="grey darken-1">
                    <template v-slot:activator="{ on }">
                      <v-btn @click="copyCode" v-on="on" x-small icon>
                        <v-icon color="primary">mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span class="white--text">Скопировать в буфер обмена.</span>
                  </v-tooltip>
                </v-col>
                <v-col lg="3" md="6" sm="6">
                  <b>Испытуемый</b>
                </v-col>
                <v-col lg="9" md="6" sm="6">
                  <router-link :class="utils.isDeletedEntity(assignedTest.user)? 'deletedColor':'successColor'"
                               :to="'/manager/user-info/'+assignedTest.user.id">
                    <b>{{ assignedTest.user.firstName }} {{ assignedTest.user.lastName }}</b>
                  </router-link>
                  <tooltip-user-deleted v-if="utils.isDeletedEntity(assignedTest.user)" :location="'bottom'"/>
                </v-col>
                <v-col lg="3" md="6" sm="6">
                  <b>Дата рождения</b>
                </v-col>
                <v-col lg="9" md="6" sm="6">
                  {{ utils.preparedViewDate(assignedTest.user.birthday, 'date') }}
                </v-col>
                <v-col lg="3" md="6" sm="6">
                  <b>Email</b>
                </v-col>
                <v-col lg="9" md="6" sm="6">
                  {{ assignedTest.user.email }}
                </v-col>
                <v-col lg="3" md="6" sm="6">
                  <b>Номер телефона</b>
                </v-col>
                <v-col lg="9" md="6" sm="6">
                  {{ assignedTest.user.phoneNumber }}
                </v-col>
              </v-row>
            </v-col>
            <v-col lg="12" md="12" sm="12">
              <br>
              <b>История назначенного теста</b>
              <v-timeline class="mr-5 ml-5" align-top reverse>
                <v-timeline-item v-for="status in listStatuses" :key="status.value"
                                 :color="status.value==='CANCELED'||status.value==='TIMEOUT'?'red darken-3':'primary'">
                  <v-card>
                    <v-card-text><b>{{ utils.resolveAssignedTestStatus(status.value) }}
                      {{ utils.preparedViewDate(status.datetime, 'datetime') }}</b><br>
                      <span v-if="role !== 'individual' && status.manager !== null">
                                      <router-link
                                          :class="utils.isDeletedEntity(status.manager)? 'deletedColor':'successColor'"
                                          :to="'/manager/user-info/'+status.manager.id">
                                        <b>Менеджер: {{ status.manager.lastName }} {{
                                            status.manager.firstName
                                          }}</b></router-link>
                      <tooltip-user-deleted v-if="utils.isDeletedEntity(status.manager)" :size="'small'"
                                            :location="'bottom'"/>
                    </span>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
              <v-expansion-panels v-if="assignedTest.resultTextInfo!==null" v-model="panels" flat accordion multiple>
                <v-expansion-panel>
                  <v-expansion-panel-header class="ma-0 pa-0"><b>Текстовая интепретация</b></v-expansion-panel-header>
                  <v-expansion-panel-content>
                  <span v-for="topicResult in assignedTest.resultTextInfo.topicResults" :key="topicResult.name">
                    <b>{{ topicResult.name }}</b><br><span>{{ topicResult.info }}</span><br><br>
                  </span>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="role === 'admin'">
                  <v-expansion-panel-header class="ma-0 pa-0"><b>Подбробные результаты теста</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th v-for="(header, index) in headers" :key="index">
                            {{ header }}
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(item, index)  in convertedResult"
                            :key="index" :style="{ 'background-color': resolveRowColor(item.result.riskLevel)}"
                        >
                          <td>{{ item.type === 'topic' ? item.result.name : '' }}</td>
                          <td>{{ item.type === 'stimulus' ? item.result.name : '' }}</td>
                          <td>{{ item.result.uValue }}</td>
                          <td>{{ item.result.dValue }}</td>
                          <td>{{ item.result.count }}</td>
                          <td>{{ item.result.controlAvg }}</td>
                          <td>{{ item.result.stimulusAvg }}</td>
                          <td>{{ item.result.stimulusMin }}</td>
                          <td>{{ item.result.stimulusMax }}</td>
                          <td>{{ item.result.pValue }}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-skeleton-loader>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import {mapMutations, mapState} from "vuex";

import assignedTestApi from '../../../scripts/api/assigned-api'
import assignedApi from "../../../scripts/api/assigned-api";
import utils from "../../../scripts/utils";
import DownloadReportButton from "./DownloadReportButton.vue";
import TooltipUserDeleted from "../../common/tooltips/TooltipUserDeleted";
import TooltipTestDeleted from "../../common/tooltips/TooltipTestDeleted";
import TooltipTrialTest from "../../common/tooltips/TooltipTrialTest";
import TooltipPublicTest from "../../common/tooltips/TooltipPublicTest";

export default {
  name: "AssignedTestPage",
  components: {DownloadReportButton, TooltipUserDeleted, TooltipTestDeleted, TooltipTrialTest, TooltipPublicTest},
  props: ['assignedTestId'],
  computed: {
    ...mapState(['role']),
    isDeletedEntities() {
      return utils.isDeletedEntity(this.assignedTest.user) || utils.isDeletedEntity(this.assignedTest.test)
    },
    currentStatus() {
      if (this.assignedTest.status.value === undefined) {
        return ''
      }
      return this.assignedTest.status.value
    },
    listStatuses() {
      if (this.assignedTest.statuses.length === 0) {
        return []
      }
      let statuses = this.assignedTest.statuses
      return statuses.sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
    },
    convertedResult() {
      let results = []
      if (this.assignedTest.resultDetails === null) {
        return results
      }
      this.assignedTest.resultDetails.topicResults.forEach(topicResult => {
            let stimulusResults = topicResult.stimulusResults;
            if (stimulusResults !== null) {
              results.push({result: topicResult.result, type: 'topic'})
              stimulusResults.forEach(stimulutsResult => results.push({
                result: stimulutsResult,
                type: 'stimulus'
              }))
            }
          }
      )
      return results
    },
    isAnalyzedTest() {
      return this.assignedTest.status.value === 'ANALYSED'
    }
  }
  ,
  data: () => {
    return {
      isLoading: false,
      utils: utils,
      assignedTest: {
        user: {},
        test: {},
        statuses: [],
        resultTextInfo: {},
        resultDetails: {topicResults: []},
        status: {}
      },
      panels: [0, 1],
      headers: [
        'Тема',
        'Стимул',
        'U',
        'D',
        'Количество',
        'ср. контр',
        'ср. стимул',
        'мин. стимул',
        'макс. стимул',
        'p-value']
    }
  },
  methods: {
    ...mapMutations(['setIsMainLoading']),
    ...mapMutations('confirmationModule', ['openConfirmationDialog']),
    ...mapMutations('customAlertModule', ['ACTIVATE_INFO_ALERT']),
    resolveRowColor(riskLevel) {
      switch (riskLevel) {
        case "LOW": {
          return '#7ed07e'
        }
        case "MEDIUM": {
          return '#e0e78c'
        }
        case "HIGH" : {
          return '#e86666'
        }
      }
    },
    cancel() {
      let action = () => assignedApi.cancelAssignedTest(this.assignedTest.id)
          .then(response => this.assignedTest = response.data)

      this.openConfirmationDialog({
        action: action,
        description: ' отменить назначенный тест "' + this.assignedTest.test.name + '" на пользователя "' + this.assignedTest.user.lastName +
            " " + this.assignedTest.user.firstName + '", который был назначен ' +
            utils.preparedViewDate(this.assignedTest.statuses[0].datetime, 'datetime')
      })
    },
    refreshCode() {
      assignedTestApi.refreshCodeAssignTest(this.assignedTest.id)
          .then(response => this.assignedTest.code = response.data)
    },
    copyCode() {
      let fakeElement = document.createElement("input");
      document.body.appendChild(fakeElement);
      fakeElement.value = this.assignedTest.code;
      fakeElement.select();
      document.execCommand("copy");
      document.body.removeChild(fakeElement);
      this.ACTIVATE_INFO_ALERT('Код доступа "' + this.assignedTest.code + '" к тесту "' + this.assignedTest.test.name +
          '" успешно скопирован в буфер обмена.')
    }
  },
  watch: {
    $route(to) {
      if (to.path === '/manager/assigned-test/' + this.assignedTestId) {
        this.isLoading = true
        this.setIsMainLoading(true)
        assignedTestApi.getAssignedTest(to.params.assignedTestId).then(response => this.assignedTest = response.data)
            .finally(() => {
              this.isLoading = false
              this.setIsMainLoading(false)
            })
      }
    }
  },
  mounted() {
    this.isLoading = true
    this.setIsMainLoading(true)
    assignedTestApi.getAssignedTest(this.assignedTestId).then(response => this.assignedTest = response.data)
        .finally(() => {
          this.isLoading = false
          this.setIsMainLoading(false)
        })
  }
}
</script>

<style scoped>
.v-application--is-ltr .v-expansion-panel-header__icon {
  margin-left: unset !important;
}

>>> .v-timeline:before {
  bottom: 0;
  content: "";
  height: 75%;
  position: absolute;
  top: 25px;
  width: 2px;
}
</style>