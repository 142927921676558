<template>
  <div>
    <v-data-table height="50vh"
                  class="mt-5 mb-5"
                  :loading="isLoading"
                  :headers="headers"
                  :items="transactions"
                  hide-default-footer
                  dense
    >
      <template v-slot:top>
        <v-layout justify-end align-center>
          <v-text-field class="ml-10 shrink"
                        v-model="search"
                        clearable
                        @click:clear="clearSearch"
                        @keydown.enter="initGetTransactionHistory"
                        @click:prepend="initGetTransactionHistory"
                        prepend-icon="mdi-magnify"
                        single-line
                        label="Поиск:"/>
        </v-layout>
      </template>
      <template v-slot:item.test.test.name="{item}">
        <router-link style="color: black; text-decoration: none" v-if="item.test!==null"
                     :to="'/manager/assigned-test/'+item.test.id">
          <v-layout align-center justify-start>
            {{ item.test.test.name }}
            <tooltip-trial-test :size="30" v-if="item.test.test.weight === 0"/>
            <tooltip-public-test v-if="item.test.test.isPublic"/>
            <tooltip-test-deleted v-if="utils.isDeletedEntity(item.test.test)" :size="'small'" :location="'bottom'"/>
          </v-layout>
        </router-link>
      </template>
      <template v-slot:item.test.user="{item}">
        <router-link style="color: black; text-decoration: none" v-if="item.test!==null"
                     :to="'/manager/user-info/'+item.test.user.id">
          {{ item.test.user.lastName }} {{ item.test.user.firstName }}
          <tooltip-user-deleted v-if="utils.isDeletedEntity(item.test.user)" :size="'small'" :location="'bottom'"/>
        </router-link>
      </template>
      <template v-slot:item.manager="{item}">
        <router-link style="color: black; text-decoration: none" v-if="item.manager!==null"
                     :to="'/manager/user-info/'+item.manager.id">
          {{ item.manager.lastName }} {{ item.manager.firstName }}
          <tooltip-user-deleted v-if="utils.isDeletedEntity(item.manager)" :size="'small'" :location="'bottom'"/>
        </router-link>
      </template>
      <template v-slot:item.datetime="{item}">
        {{ utils.preparedViewDate(item.datetime, 'datetime') }}
      </template>
      <template v-slot:item.test.user.lastName="{item}">
        <span id="lastNameTestableUser" v-if="item.test!==null">{{ item.test.user.lastName }}</span>
      </template>
      <template v-slot:item.amount="{item}">
        <span v-if="item.amount>0" style="color:#008000;">+{{ utils.viewAmount(item.amount) }} </span>
        <span v-else style="color: darkred">{{ utils.viewAmount(item.amount) }}</span>
      </template>
    </v-data-table>
    <v-pagination v-if="pageCount>1" @input="getTransactionHistory" v-model="page" :length="pageCount"/>
  </div>

</template>

<script>
import billingApi from "../../../scripts/api/billing-api";
import utils from "../../../scripts/utils";

import TooltipUserDeleted from "../../common/tooltips/TooltipUserDeleted";
import TooltipTestDeleted from "../../common/tooltips/TooltipTestDeleted";
import TooltipPublicTest from "../../common/tooltips/TooltipPublicTest";
import TooltipTrialTest from "../../common/tooltips/TooltipTrialTest";

export default {
  components: {TooltipUserDeleted, TooltipTestDeleted, TooltipPublicTest, TooltipTrialTest},
  name: "TransactionalHistoryTable",
  props: ['orgId'],
  data: () => {
    return {
      utils: utils,
      search: '',
      page: 1,
      pageCount: 0,
      limit: 10,
      isLoading: false,
      transactions: [],
      headers: [
        {text: 'ID', sortable: false, align: ' d-none', value: 'id'},
        {text: 'Дата/Время', value: 'datetime', sortable: false},
        {text: 'Тест', value: 'test.test.name', sortable: false},
        {text: 'Тестируемый', value: 'test.user', sortable: false},
        {text: 'Тестируемый', align: ' d-none', value: 'test.user.lastName', sortable: false},
        {text: '', align: ' d-none', value: 'test.user.firstName', sortable: false},
        {text: 'Менеджер', value: 'manager', sortable: false},
        {text: 'Менеджер', align: ' d-none', value: 'manager.lastName', sortable: false},
        {text: '', align: ' d-none', value: 'manager.firstName', sortable: false},
        {text: 'Операция', value: 'status', sortable: false},
        {text: 'Комментарий', value: 'comment', sortable: false},
        {text: 'Количество', value: 'amount', sortable: false},
      ],
    }
  },
  methods: {
    initGetTransactionHistory() {
      this.resetPage()
      this.getTransactionHistory()
    },
    getTransactionHistory() {
      this.isLoading = true
      let offset = this.page - 1
      billingApi.getTransactionHistory(this.orgId, this.search, this.limit, offset)
          .then(response => {
            this.pageCount = response.data.count
            this.transactions = response.data.content
          })
          .finally(() => this.isLoading = false)
    },
    resetPage() {
      this.page = 1
    },
    clearSearch() {
      this.search = ''
      this.initGetTransactionHistory()
    }
  }
}
</script>

<style scoped>
.replenishment {
  color: #008000;
}

.write-off {
  color: darkred;
}

td > #lastNameTestableUser {
  padding: 0px;
}
</style>