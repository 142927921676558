<template>
  <div>
    {{ user.email }}
    <v-tooltip v-if="user.emailStatus === 'CONFIRMED'" max-width="350" bottom color="primary">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="primary">mdi-check-bold</v-icon>
      </template>
      <span class="white--text">Адрес электронной почты подтвержден</span>
    </v-tooltip>
    <div v-if="user.emailStatus !== 'CONFIRMED'"
         style="color: #c22a28;   line-height: 1.2em !important; font-size: .7em;">
      Почта не подтверждена.<br>Результаты не будут отправлены на почту, не возможно восстановить пароль в случае
      утери.<br>
      <v-btn class="mt-1" x-small color="#c22a28" dark @click="sendEmailConfirmation">
        <v-icon x-small class="pr-2">mdi-send-check-outline</v-icon>
        Отправить подтверждение
      </v-btn>
    </div>
  </div>
</template>

<script>
import emailApi from "../../scripts/api/email-api";
import {mapMutations} from "vuex";

export default {
  name: "EmailUserInfo",
  props: ['user'],
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_INFO_ALERT']),
    sendEmailConfirmation() {
      emailApi.sendEmailConfirmation(this.user.id)
    }
  }
}
</script>

<style scoped>

</style>