<template>
  <v-snackbar
      v-model="isActiveAlert"
      center
      :timeout=-1
      top
      :color="color"
  >
    <span class="white--text" style="white-space: pre-line;">{{ messageAlert }}</span>

    <template v-slot:action="{ attrs }">
      <v-btn
          dark
          v-bind="attrs"
          @click="closeAlert"
          icon
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>

  </v-snackbar>
</template>

<script>
import {mapMutations, mapState} from 'vuex'

export default {
  name: "CustomAlert",
  computed: {
    ...mapState('customAlertModule', ['isActiveAlert', 'messageAlert', 'color'])
  },
  methods: {
    ...mapMutations('customAlertModule', ['DEACTIVATE_ALERT']),
    closeAlert() {
      this.DEACTIVATE_ALERT();
    }
  }
}
</script>

<style scoped>

</style>
