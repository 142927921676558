<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <v-row>
          <v-col class="pb-0" xl="12" lg="12" md="12" sm="12">
            <h1 class="display-1">Организация: "{{ currentViewOrg.name }}"
              <tooltip-org-deleted v-if="utils.isDeletedEntity(currentViewOrg)" :location="'bottom'" :size="'large'"/>
            </h1>
          </v-col>
          <v-col xl="12" lg="12" md="12" sm="12">
            <v-btn
                v-if="(role === 'admin' || (role==='sup_manager'&& currentViewOrg.id===currentUser.orgId)) &&
                !utils.isDeletedEntity(currentViewOrg)"
                small color=" primary
            " @click="openOrgUpdateForm" class="mr-5">
              <v-icon class="pr-2">mdi-pencil-outline</v-icon>
              Редактировать
            </v-btn>

          </v-col>
          <v-col lg="6" md="12" sm="12">
            <v-row>
              <v-col lg="6" md="6" sm="6">
                <b>Телефон: </b>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                {{ currentViewOrg.phoneNumber }}
              </v-col>
              <v-col lg="6" md="6" sm="6">
                <b>Адрес электроной почты: </b>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                {{ currentViewOrg.email }}
              </v-col>
              <!--              <v-col lg="3" md="6" sm="6">-->
              <!--                <b>Юридический адрес:</b>-->
              <!--              </v-col>-->
              <!--              <v-col lg="9" md="6" sm="6">-->
              <!--                {{ currentViewOrg.legalAddress }}-->
              <!--              </v-col>-->
              <!--              <v-col lg="3" md="6" sm="6">-->
              <!--                <b>ИНН:</b>-->
              <!--              </v-col>-->
              <!--              <v-col lg="9" md="6" sm="6">-->
              <!--                {{ currentViewOrg.taxpayerNumber }}-->
              <!--              </v-col>-->
              <!--              <v-col lg="3" md="6" sm="6">-->
              <!--                <b>КПП</b>-->
              <!--              </v-col>-->
              <!--              <v-col lg="9" md="6" sm="6">-->
              <!--                {{ currentViewOrg.kpp }}-->
              <!--              </v-col>-->
            </v-row>
          </v-col>
          <v-col class="pt-0 mt-0" offset-xl="2" xl="4"  lg="6" md="12" sm="12">
            <billing-account-card v-if="!utils.isDeletedEntity(currentViewOrg)" :orgId="orgId"
                                  ref="billingAccountCard"/>
          </v-col>
        </v-row>
        <v-tabs v-model="currentTab" centered grow class="primary ml-3 mt-5">
          <v-tab>
            <v-icon left>mdi-account-tie</v-icon>
            Сотрудники
          </v-tab>
          <v-tab>
            <v-icon left>mdi-account</v-icon>
            Испытуемые
          </v-tab>
          <v-tab>
            <v-icon left>mdi-clipboard-check-multiple-outline</v-icon>
            Тесты
          </v-tab>
          <v-tab-item>
            <org-staff-table ref="orgStaffTable" :orgId="orgId"/>
          </v-tab-item>
          <v-tab-item>
            <org-examinees-table ref="orgExamineesTable" :orgId="orgId"/>
          </v-tab-item>
          <v-tab-item>
            <available-tests-table ref="availableTestsTable" :orgId="orgId"/>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-col>
    <org-update-form @successUpdate="updateOrg" ref="orgUpdateForm"/>
  </v-row>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import OrgStaffTable from "./tables/OrgStaffTable.vue";
import OrgExamineesTable from "./tables/OrgExamineesTable.vue";
import AvailableTestsTable from "./tests/tables/AvailableTestsTable.vue";
import BillingAccountCard from "../biling/PaymentAccountCard"
import TooltipOrgDeleted from "../common/tooltips/TooltipOrgDeleted";
import OrgUpdateForm from "./forms/OrgUpdateForm";

import {translateRoles} from "../../utils/DataUtils";
import orgApi from '../../scripts/api/org-api'
import utils from "../../scripts/utils";

export default {
  components: {
    OrgStaffTable,
    OrgExamineesTable,
    AvailableTestsTable,
    BillingAccountCard,
    TooltipOrgDeleted,
    OrgUpdateForm
  },
  name: "OrgInfoPage",
  props: ['orgId'],
  computed: {
    ...mapState(['role', 'currentUser', 'currentViewOrg']),
  },
  data() {
    return {
      utils: utils,
      currentTab: 0,
      page: 1,
      pageCount: 0,
      headers: [
        {text: 'ID', sortable: false, align: ' d-none', value: 'id'},
        {text: 'Логин', value: 'login'},
        {text: 'Фамилия', value: 'lastName'},
        {text: 'Имя', value: 'firstName'},
        {text: 'Роль', value: 'roles'},
        {text: 'Дата рождения', value: 'birthday'},
        {text: 'Email', value: 'email'},
        {text: '', value: 'actions', sortable: false},
      ],
      search: '',
    }
  },
  methods: {
    ...mapMutations(['setIsMainLoading']),
    ...mapMutations(['setCurrentViewOrg']),
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT_BASED_ON_CODE']),
    openUserPage(user) {
      this.$router.push('/manager/user-info/' + user.id)
    },
    translateRoles(roles) {
      return translateRoles(roles)
    },
    updateStaffTable() {
      let orgStaffTable = this.$refs.orgStaffTable
      if (orgStaffTable != null) {
        orgStaffTable.initRequestStaff()
      }
    },
    updateExamineesTable() {
      let orgExamineesTable = this.$refs.orgExamineesTable;
      if (orgExamineesTable != null) {
        orgExamineesTable.initRequestExaminess();
      }
    },
    updateAvailableTestsTable() {
      let availableTestsTable = this.$refs.availableTestsTable;
      if (availableTestsTable != null) {
        availableTestsTable.initRequestTestsCompany()
      }
    },
    updateBillingAccount() {
      this.$refs.billingAccountCard.getBillingAccount()
    },
    openOrgUpdateForm() {
      this.$refs.orgUpdateForm.openDialog(this.currentViewOrg);
    },
    updateOrg(data) {
      this.setCurrentViewOrg(data)
    }
  },
  mounted() {
    this.isLoading = true
    this.setIsMainLoading(true)
    orgApi.getOrgById(this.orgId).then(response => {
      this.setCurrentViewOrg(response.data)
      this.updateBillingAccount()
    }).finally(() => {
      this.isLoading = false
      this.setIsMainLoading(false)
    })
  },
  watch: {
    currentTab(newVal) {
      switch (newVal) {
        case 0: {
          this.updateStaffTable()
          break;
        }
        case 1: {
          this.updateExamineesTable()
          break;
        }
        case 2: {
          this.updateAvailableTestsTable()
          break
        }
      }
    },
    $route(to) {
      if (to.path === '/organization/' + this.orgId) {
        this.isLoading = true
        this.setIsMainLoading(true)
        orgApi.getOrgById(this.orgId).then(response => {
          this.setCurrentViewOrg(response.data)
          this.updateBillingAccount()
        }).finally(() => {
          this.isLoading = false
          this.setIsMainLoading(false)
        })
      }
    }
  }
}

</script>

<style scoped>
.v-stepper {
  box-shadow: none;
}
</style>
