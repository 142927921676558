export default class UserDTO {
    constructor(id, login, firstName, lastName, birthday, email, phoneNumber, roles, orgId, org) {
        this.id = id;
        this.login = login;
        this.firstName = firstName;
        this.lastName = lastName;
        this.birthday = birthday;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.roles = roles;
        this.orgId = orgId;
        this.org = org;
    }
}
