<template>
  <v-container
      class="fill-height"
      fluid
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
          xl="3"
      >
        <v-form lazy-validation
                ref="form">
          <v-card flat max-width="460px">
            <v-layout justify-center>
              <v-img max-width="300" src="/images/PsyScanner.png"/>
            </v-layout>
            <v-card-title class="justify-center">
              Как снова войти в учетную запись
            </v-card-title>
            <v-card-text class="pt-0 text-center">
              <v-form ref="addingForm">
                <v-text-field
                    :rules="[rules.isRequired, rules.isSuccessEmail]"
                    color="primary"
                    label="Введите адрес электронной почты или login"
                    name="password"
                    v-model="credName"
                    prepend-icon='mdi-account'
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-row
                  align="center"
                  justify="center"
              >
                <v-btn :loading="isLoading"
                       color="primary"
                       small
                       dark
                       class="btn"
                       @click="changePass">
                  Отправить ссылку для восстановления пароля
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapMutations} from "vuex";
import {post} from "../../httpclient/http-client";
import ErrorInfo from "../../classes/util/ErrorInfo";
import rules from "../../scripts/rules";

export default {
  name: "ResetPassword",
  data() {
    return {
      isOpenDialog: false,
      credName: '',
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT_BASED_ON_CODE', 'ACTIVATE_INFO_ALERT']),
    changePass() {
      if (this.$refs.addingForm.validate()) {
        this.isLoading = true
        let request = post('/api/manager/public/operations/reset-password', {
          username: this.credName,
        });
        request.call
            .then(() => {
              this.ACTIVATE_INFO_ALERT('Проверьте почту и щелкните ссылку для сброса пароля, которую мы отправили для ' +
                  'вашей учетной записи PSYSCAN.')
              this.credName = ''
              this.$router.push({path: '/login', addToHistory: false})
            })
            .catch(er => this.ACTIVATE_ALERT_BASED_ON_CODE(new ErrorInfo(er.response.status,
                'Произошла ошибка при попытке отправить письмо с ссылкой для восстановления пароля.',
                request.id)))
            .finally(() => this.isLoading = false)
      } else {
        this.ACTIVATE_ALERT("Вы не указали пользователя, проверьте данные и попробуйте снова.")
      }
    }
  }
}
</script>

<style scoped>

</style>
