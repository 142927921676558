<template>
  <div>
    <v-dialog persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-title>
            Изменение данных теста: "{{ testInfo.name }}"
          </v-card-title>
          <v-card-text>
            <v-form ref="addingForm">
              <v-text-field label="Наименование *" v-model="name" class="pb-3"
                            :rules="[rules.isRequired]"/>
              <v-autocomplete
                  class="pb-3"
                  multiple
                  topics
                  dense
                  return-object
                  :items="topics"
                  v-model="selectedTopics"
                  :rules="[rules.notEmptyList]"
                  item-text="name"
                  label="Список тем *"/>
              <v-text-field
                  label="Длительность теста *"
                  v-model="estimateTime"
                  type="number"
                  suffix="мин."
              />
              <v-textarea class="pb-3"
                          v-model="description"
                          auto-grow
                          rows="1"
                          :rules="[rules.isRequired, rules.isStringLong]"
                          clearable
                          clear-icon="mdi-close"
                          label="Описание *"/>
              <v-text-field
                  :disabled="isFree"
                  label="Вес *"
                  v-model="weight"
                  min="0"
                  :rules="[rules.notNegative]"
                  type="number"
                  class="pb-3"
              />
              <v-layout align-center justify-start>
                <v-img contain :max-height="40" :max-width="40" :src="require('@/assets/images/free-icon.png')"/>
                <v-checkbox v-model="isFree" class=" ml-1 mr-10" label="Пробный тест"/>
<!--                <v-icon large color="primary">mdi-alpha-p-box</v-icon>-->
<!--                <v-checkbox v-model="isPublic" class="ml-1 " label="Публичный тест"/>-->
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" small dark @click="closeDialog">Отменить</v-btn>
            <v-btn color="primary" small dark class="btn" @click="updateTest" :loading="isLoading">Сохранить</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import TestDTO from "../../../../classes/dto/TestDTO";
import apiTopics from "../../../../scripts/api/topics-api";
import apiTests from '../../../../scripts/api/tests-api'
import rules from "../../../../scripts/rules";

export default {
  computed: {
    estimateTime() {
      return this.selectedTopics.length
    }
  },
  name: "TestUpdateForm",
  data() {
    return {
      isFree: false,
      testInfo: {},
      topics: [],
      name: '',
      weight: '',
      description: '',
      selectedTopics: [],
      isOpenDialog: false,
      isPublic: false,
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT']),
    openDialog(testInfo) {
      this.testInfo = testInfo
      this.id = testInfo.id
      this.name = testInfo.name
      this.selectedTopics = testInfo.topics
      this.description = testInfo.description
      this.weight = testInfo.weight
      this.isFree = this.weight === 0
      this.isPublic = testInfo.isPublic
      apiTopics.getTopics().then((response) => {
        this.topics = response.data.content
        this.isOpenDialog = true
      })
    },
    updateTest() {
      if (this.$refs.addingForm.validate()) {
        this.isLoading = true
        let testDTO = new TestDTO(this.testInfo.id, this.name,
            this.selectedTopics.map(topic => topic.id), this.description, this.estimateTime, [], this.weight,
            this.isPublic);
        apiTests.updateTest(testDTO).then(response => {
              console.log("Updated test. Status: ", response.status)
              testDTO.topics = this.selectedTopics
              this.$emit('successUpdate', testDTO)
              this.closeDialog()
            }
        ).finally(() => this.isLoading = false)
      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для добавления нового теста, проверьте данные " +
            "и попробуйте снова.");
      }
    },
    closeDialog() {
      this.isOpenDialog = false;
      this.testInfo = {}
      this.name = ''
      this.weight = ''
      this.selectedTopics = []
      this.description = ''
    }
  },
  watch: {
    isFree(newVal) {
      if (newVal) {
        this.weight = 0
      } else {
        this.weight = 1
      }
    },
    weight(newVal) {
      if (newVal == 0 && newVal !== '') {
        this.isFree = true
      }
    }
  }
}
</script>

<style scoped>

</style>