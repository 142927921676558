<template>
  <div>
    <v-data-table
        height="40vh"
        :loading="isLoading"
        :headers="headers"
        :items="assignedTests"
        @click:row="openAssignedTest"
        hide-default-footer
    >
      <template v-slot:item.user.login="{item}">
        {{ item.user.login }}
        <tooltip-user-deleted v-if="utils.isDeletedEntity(item.user)" :size="'small'" :location="'bottom'"/>
      </template>
      <template v-slot:item.user.org.name="{item}">
        {{ item.user.org.name }}
        <tooltip-org-deleted v-if="utils.isDeletedEntity(item.user.org)" :size="'small'" :location="'bottom'"/>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-layout justify-end>
          <v-tooltip v-if="role!=='admin' && item.status.value ==='ASSIGNED' && !isDeletedEntities(item)" bottom
                     color="primary">
            <template v-slot:activator="{ on }">
              <v-btn small icon @click.stop="cancelAssignedTest(item)" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span class="white--text">Отменить назначенный тест</span>
          </v-tooltip>
        </v-layout>
      </template>
      <template v-slot:item.expiredDate="{item}">
        {{ utils.preparedViewDate(item.expiredDate, 'date') }}
      </template>
      <template v-slot:item.status.value="{item}">
        {{ utils.resolveAssignedTestStatus(item.status.value) }}
        ({{ utils.preparedViewDate(item.status.datetime, 'datetime') }})
      </template>
      <template v-slot:top>
        <v-row class="pt-2">
          <v-spacer/>
          <v-text-field
              clearable
              @click:clear="clearSearch"
              label="Поиск"
              class="shrink"
              prepend-icon="mdi-magnify"
              @click:prepend="initRequestAssignedTests"
              @keydown.enter="initRequestAssignedTests"
              v-model="search"/>
        </v-row>
      </template>
    </v-data-table>
    <v-pagination v-if="pageCount>1" @input="requestAssignedTests" v-model="page" :length="pageCount"/>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import assignedApi from '../../../../scripts/api/assigned-api'
import orgApi from '../../../../scripts/api/org-api'
import utils from "../../../../scripts/utils"
import TooltipUserDeleted from "../../../common/tooltips/TooltipUserDeleted";
import TooltipOrgDeleted from "../../../common/tooltips/TooltipOrgDeleted";

export default {
  name: "AssignedTestTable",
  components: {
    TooltipUserDeleted,
    TooltipOrgDeleted
  },
  props: ['testInfo'],
  computed: {
    ...mapState(['role', 'currentUser'])
  },
  mounted() {
    if (Object.keys(this.currentUser).length === 0) {
      return
    }
    this.requestAssignedTests()
  },
  data() {
    return {
      utils: utils,
      assignedTests: [],
      search: '',
      page: 1,
      limit: 7,
      pageCount: 0,
      isLoading: false,
      headers: [
        {text: 'ID', sortable: false, align: ' d-none', value: 'id'},
        {text: 'Фамилия', value: 'user.lastName', sortable: false},
        {text: 'Имя', value: 'user.firstName', sortable: false},
        {text: 'Текущий статус', value: 'status.value', sortable: false},
        {text: 'Действителен до', value: 'expiredDate', sortable: false},
        {text: '', value: 'actions', sortable: false}
      ]
    }
  },
  methods: {
    ...mapMutations('confirmationModule', ['openConfirmationDialog']),
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT_BASED_ON_CODE', 'ACTIVATE_INFO_ALERT']),
    clearSearch() {
      this.search = ''
      this.initRequestAssignedTests()
    },
    resetPage() {
      this.page = 1
    },
    initRequestAssignedTests() {
      this.resetPage()
      this.requestAssignedTests()
    },
    requestAssignedTests() {
      if (this.testInfo.id === "") {
        return
      }
      this.isLoading = true
      let offset = this.page - 1
      if (this.role === 'admin') {
        assignedApi.getUsersAssignedToTest(this.testInfo, this.search, this.limit, offset)
            .then(response => {
              this.pageCount = response.data.count
              this.assignedTests = response.data.content
            })
            .finally(() => this.isLoading = false)
      } else {
        orgApi.getAssignedTests(this.currentUser.orgId, this.search, this.limit, offset)
            .then(response => {
              this.pageCount = response.data.count
              this.assignedTests = response.data.content
                  .filter(item => item.test.id === this.testInfo.id)
            })
            .finally(() => this.isLoading = false)
      }

    },
    cancelAssignedTest(item) {
      let action = () => assignedApi.cancelAssignedTest(item.id)
          .then(response => {
            let needIndex = this.assignedTests.findIndex(at => at.id === item.id)
            this.assignedTests = [
              ...this.assignedTests.slice(0, needIndex),
              response.data,
              ...this.assignedTests.slice(needIndex + 1,)]
          })

      this.openConfirmationDialog({
        action: action,
        description: ' отменить назначенный тест "' + item.test.name + '" на пользователя "' + item.user.lastName + " " + item.user.firstName +
            '", который был назначен ' + utils.preparedViewDate(item.statuses[0].datetime, 'datetime')
      })
    },
    openAssignedTest(assignedTest) {
      this.$router.push('/manager/assigned-test/' + assignedTest.id);
    },
    isDeletedEntities(assignedTest) {
      return utils.isDeletedEntity(assignedTest.user) || utils.isDeletedEntity(assignedTest.test)
    },
  },
  watch: {
    testInfo() {
      this.requestAssignedTests()
    }
  }
}
</script>

<style scoped>
>>> .v-data-table tr {
  cursor: pointer !important;
}

>>> .v-data-table th {
  font-size: 1.1em;
  font-weight: 700 !important;
}

>>> .v-data-table td {
  font-size: 1em !important;
}
</style>
