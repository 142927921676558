<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <h1 class="display-1 pb-3">Испытуемые</h1>
        <v-container>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ExamineesPages"
}
</script>

<style scoped>

</style>