<template>
  <v-dialog
      v-model="isOpenDialog"
      width="700"
  >
    <v-card>
      <v-container>
        <v-card-text class="pb-0">
          <h3>Баланс организации: <span v-if="resolveAmount>0" style="color:#008000;">{{ resolveAmount }} </span>
            <span v-else style="color: darkred">{{ resolveAmount }}</span></h3><br>
          <div class="mb-5">Срок действия назначенных тестов 1 месяц</div>
          <v-form ref="assigmentTestForm">
            <v-autocomplete v-model="selectedUsers"
                            dense
                            :readonly="testableUsers == null || testableUsers.length < 1"
                            :items="testableUsers"
                            label="Список тестируемых *"
                            prepend-icon="mdi-account-box-multiple-outline"
                            return-object
                            :item-text="text"
                            multiple
                            :rules="[rules.notEmptyList]"/>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small dark @click="closeDialog">
            Отмена
          </v-btn>
          <v-btn
              :disabled="(resolveAmount <= 0 && selectedUsers.length === 0) || (selectedUsers.length > 0 && resolveAmount<0)"
              color="primary"
              small
              :dark="resolveAmount>0"
              class="btn"
              @click="assigmentTest"
              :loading="isLoading"
          >
            Назначить
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import ErrorInfo from "../../../../classes/util/ErrorInfo";

import testsApi from "../../../../scripts/api/tests-api";
import assignedApi from "../../../../scripts/api/assigned-api";
import billingApi from "../../../../scripts/api/billing-api";
import rules from "../../../../scripts/rules";
import {getDateThatWillBeInMonth} from "../../../../utils/DataUtils";

export default {
  name: "AssigmentUserForm",
  computed: {
    ...mapState(['currentUser']),
    resolveAmount() {
      return this.amount - this.selectedUsers.length * this.test.weight
    }
  },
  data() {
    return {
      amount: 0,
      testableUsers: [],
      test: {},
      selectedUsers: [],
      isOpenDialog: false,
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT', 'ACTIVATE_ALERT_BASED_ON_CODE', 'ACTIVATE_INFO_ALERT']),
    text(item) {
      let email = item.email === null || item.email === undefined || item.email.length === 0 ? '' : ' (Email: ' + item.email + ')'
      return item.firstName + ' ' + item.lastName + email
    },
    assigmentTest() {
      if (this.$refs.assigmentTestForm.validate()) {
        this.isLoading = true

        let tests = []
        this.selectedUsers.forEach(user => tests.push({
          userId: user.id,
          testId: this.test.id,
          company: user.org,
          expiredDate: getDateThatWillBeInMonth()
        }))


        let logins = []
        this.selectedUsers.forEach(user => logins += '  "' + user.lastName + " " + user.firstName + '"')


        let request = assignedApi.assignTests(tests);
        request.call
            .then(() => {
              this.ACTIVATE_INFO_ALERT('Тест "' + this.test.name + '" успешно назначен на пользователей ' + logins + '.');
              this.$emit('successAssign');
            })
            .catch(er => {
              this.ACTIVATE_ALERT_BASED_ON_CODE(new ErrorInfo(er.response.status,
                  'Произошла ошибка при попытке назначить тест "' + this.test.name + '" на пользователей ' + logins + '.',
                  request.id))
            })
            .finally(() => {
              this.closeDialog()
              this.isLoading = false
            })
      } else {
        this.ACTIVATE_ALERT("Вы не указали тест, выберите категорию теста, тест и после кпопробуйте снова.");
      }
    },
    requestTests() {
      testsApi.getTests().then(response => this.testableUsers = response.data.content)
    },
    openDialog(test, testableUsers) {
      billingApi.getBillingAccount(this.currentUser.orgId).then(response => {
        this.amount = response.data.amount
        this.testableUsers = testableUsers
        this.test = test
        this.isOpenDialog = true
      })
    },
    closeDialog() {
      this.isOpenDialog = false
      this.test = []
      this.testableUsers = []
      this.selectedUsers = []
      this.$refs.assigmentTestForm.reset()
    }
  }
}
</script>

<style scoped>

</style>
