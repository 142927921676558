<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <h1 class="display-1 pb-0">Тесты</h1>
        <v-card class="pb-10" flat>
          <v-card-title class="pa-0">
            <v-btn small color="primary" @click="openAddingTestForm">
              Добавить
            </v-btn>
            <v-spacer/>
            <v-text-field class="ml-10 shrink"
                          v-model="search"
                          @click:clear="clearSearch"
                          @keydown.enter="findTest"
                          @click:prepend="findTest"
                          clearable
                          prepend-icon="mdi-magnify"
                          single-line
                          label="Поиск:"/>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="testList"
              hide-default-footer
              :loading="isTestsLoading"
              @click:row="openTestPage"
          >
            <template v-slot:item.statuses="{ item }">
              <v-layout align-center justify-start>
                <tooltip-trial-test :size="25" v-if="item.weight === 0"/>
                <tooltip-public-test v-if="item.isPublic" :size="25"/>
              </v-layout>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-layout justify-end>
                <v-btn x-small icon @click.stop="openTestUpdateForm(item)">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn x-small icon @click.stop="removeTest(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-layout>
            </template>
            <template v-slot:item.testList="{item}">
              <span v-for="(topic, index) in item.topics" :key="index">{{ topic.name }}<br></span>
            </template>
          </v-data-table>
        </v-card>
        <confirmation-dialog ref="confirmationDialog"/>
      </v-container>
    </v-col>
    <v-bottom-navigation
        absolute
        hide-on-scroll
        horizontal
        grow
    >
      <v-pagination v-if="pageCount>1" @input="getTests" v-model="page" :length="pageCount"/>
    </v-bottom-navigation>
    <test-update-form @successUpdate="updateTest" ref="testUpdateForm"/>
    <test-add-form @successCreate="createTest" ref="testAddForm"/>
  </v-row>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'

import TestAddForm from "./forms/TestAddForm.vue";
import TestUpdateForm from "./forms/TestUpdateForm.vue";
import ConfirmationDialog from "../../common/ConfirmationDialog.vue";
import TooltipTrialTest from "../../common/tooltips/TooltipTrialTest";
import TooltipPublicTest from "../../common/tooltips/TooltipPublicTest";

import testsApi from '../../../scripts/api/tests-api'

export default {
  components: {TestAddForm, TestUpdateForm, ConfirmationDialog, TooltipTrialTest, TooltipPublicTest},
  name: "Tests",
  computed: {
    ...mapState(['role'])
  },
  data() {
    return {
      limit: 10,
      search: '',
      testList: [],
      page: 1,
      pageCount: 0,
      isTestsLoading: false,
      headers: [
        {text: 'ID', sortable: false, align: ' d-none', value: 'id'},
        {text: '', value: 'statuses', sortable: false},
        {text: 'Имя теста', value: 'name', sortable: false},
        {text: 'Темы', value: 'testList', sortable: false},
        {text: '', value: 'actions', sortable: false}
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.resetPage()
      vm.getTests()
    })
  },
  methods: {
    ...mapMutations('confirmationModule', ['openConfirmationDialog']),
    ...mapActions('managerTests', ['REMOVE_TEST_BY_MANAGER']),
    getTests() {
      this.isTestsLoading = true
      let offset = this.page - 1
      testsApi.getTests(this.search, this.limit, offset)
          .then(response => {
            this.pageCount = response.data.count
            this.testList = response.data.content
          })
          .finally(() => this.isTestsLoading = false)
    },
    findTest() {
      this.resetPage()
      this.getTests()
    },
    clearSearch() {
      this.search = ''
      this.resetPage()
      this.getTests()
    },
    resetPage() {
      this.page = 1
    },
    updateTest(test) {
      let needIndex = this.testList.findIndex(t => t.id === test.id)
      this.testList = [
        ...this.testList.slice(0, needIndex),
        test,
        ...this.testList.slice(needIndex + 1,)]
    },
    createTest(test) {
      if (this.testList.length === this.limit) {
        this.testList = [
          test,
          ...this.testList.slice(1, this.limit - 1)]
      } else {
        this.testList.push(test)
      }
    },
    removeTest(test) {
      this.openConfirmationDialog({
        description: ' удалить тест "' + test.name + '"',
        action: () => testsApi.removeTest(test).then(() => {
          let needIndex = this.testList.findIndex(t => t.id === test.id)
          this.testList = [
            ...this.testList.slice(0, needIndex),
            ...this.testList.slice(needIndex + 1,)]
        })
      })
    },
    openAddingTestForm() {
      this.$refs.testAddForm.openDialog();
    },
    openTestPage(test) {
      this.$router.push('/manager/test-info/' + test.id);
    },
    openTestUpdateForm(test) {
      this.$refs.testUpdateForm.openDialog(test);
    }
  }
}
</script>

<style scoped>

</style>
