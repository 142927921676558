<template>
  <div>
    <v-dialog :retain-focus="false" persistent max-width="400" v-model="isActiveConfirmationDialog">
      <v-card>
        <v-container>
          <v-card-text>
            Вы действительно хотите {{ actionDescription }}?
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary"  small dark class="btn" @click="confirm">Да</v-btn>
            <v-btn color="primary"  small dark @click="closeConfirmationDialog">Нет</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {mapMutations, mapState} from "vuex";

export default {
  name: "ConfirmationDialog",
  computed: {
    ...mapState('confirmationModule', ['action', 'actionDescription', 'isActiveConfirmationDialog'])
  },
  methods: {
    ...mapMutations('confirmationModule', ['closeConfirmationDialog']),
    confirm() {
      this.action()
      this.closeConfirmationDialog()
    }
  }
}
</script>

<style scoped>

</style>
