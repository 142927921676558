<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <v-row>
          <v-col class="pb-0" xl="12" lg="12" md="12" sm="12">
            <v-layout align-center justify-start>
              <h1 class="display-1 mr-5">Тест: {{ currentViewTest.name }}</h1>
              <tooltip-trial-test v-if="currentViewTest.weight===0" :size="38"/>
              <tooltip-public-test :size="'large'" v-if="currentViewTest.isPublic"/>
              <tooltip-test-deleted v-if="utils.isDeletedEntity(currentViewTest)" :location="'bottom'" :size="'large'"/>
            </v-layout>
          </v-col>
          <v-col xl="12" lg="12" md="12" sm="12">
            <v-btn
                v-if="role === 'admin' && !utils.isDeletedEntity(currentViewTest)"
                small color="primary" @click="openTestUpdateForm" class="mr-5">
              <v-icon class="pr-2">mdi-pencil-outline</v-icon>
              Редактировать
            </v-btn>
          </v-col>
        </v-row>

        <br>
        <v-row>
          <v-col lg="12" md="12" sm="12">
            <span v-if="role==='admin'"><b>Вес:</b> {{ currentViewTest.weight }}<br><br>
            <b>Темы:</b>
            <router-link style="text-decoration: none"
                         v-for="(topic, index) in sortedAlphabeticallyTopics"
                         :key="topic.id"
                         :to="'/admin/topic-info/'+topic.id">
              <b> {{ topic.name }}<span v-if="index !== currentViewTest.topics.length-1">,</span></b>
            </router-link>
                <br><br>
            </span>
            <b>Описание:</b> {{ currentViewTest.description }}<br><br>

            <b>Длительность теста:</b>
            <v-progress-linear
                color="green lighten-3"
                :value="progressValue"
                height="15"
            >
              {{ Math.ceil(currentViewTest.estimateTime) }} мин.
            </v-progress-linear>
          </v-col>
        </v-row>
        <br>
        <v-tooltip v-if="role!=='admin' &&  currentViewTest.state!== 'DELETED'" :disabled="countAvailableTests > 0"
                   bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="d-inline-block">
              <v-btn class="mr-10" :disabled="countAvailableTests === 0" color="primary" small
                     @click="openAssigmentTestForm">
                Назначить пользователям
              </v-btn>
            </div>
          </template>
          <span>В вашей организации нет испытуемых.</span>
        </v-tooltip>
        <!--        <div v-if="role==='admin'">-->
        <!--          <v-btn @click="openAssignTestToCompaniesForm" color="primary" small class="mt-10 mr-10">-->
        <!--            Выдать тест организации-->
        <!--          </v-btn>-->
        <!--        </div>-->
        <v-tabs centered grow @change="updateTabInfo" class="primary mt-5">
          <!--          <v-tab v-if="role==='admin'">-->
          <!--            <v-icon left>mdi-domain</v-icon>-->
          <!--            Доступен организациям-->
          <!--          </v-tab>-->
          <v-tab>
            <v-icon left>mdi-file-chart</v-icon>
            Статус тестов
          </v-tab>

          <!--          <v-tab-item v-if="role==='admin'" transition="scale-y-transition">-->
          <!--            <available-companies-table/>-->
          <!--          </v-tab-item>-->
          <v-tab-item transition="scale-y-transition">
            <assigned-user-table ref="assignedUserTable" :test-info="currentViewTest"/>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-col>
    <assigment-user-form @successAssign="successAssign" ref="assigmentUserForm"/>
    <assign-test-to-companies-form ref="assignTestToCompaniesForm"/>
    <test-update-form @successUpdate="updateTest" ref="testUpdateForm"/>

  </v-row>
</template>

<script>
import {mapMutations, mapState} from 'vuex'

import utils from "../../../scripts/utils";
import AssigmentUserForm from "../assigned/forms/AssigmentUserForm";
import AssignedUserTable from "../assigned/tables/AssignedUserTable.vue";
import AssignTestToCompaniesForm from "../../orgs/tests/forms/AssignTestToCompaniesForm.vue";
import TooltipTrialTest from "../../common/tooltips/TooltipTrialTest";
import TooltipTestDeleted from "../../common/tooltips/TooltipTestDeleted";
import TooltipPublicTest from "../../common/tooltips/TooltipPublicTest";
//todo расскоментировать, когда сделаем задачу  #126
// import AvailableCompaniesTable from "../../orgs/tests/tables/AvailableCompaniesTable.vue";

import testsApi from '../../../scripts/api/tests-api'
import orgApi from '../../../scripts/api/org-api'
import TestUpdateForm from "./forms/TestUpdateForm";

export default {
  name: "TestInfo",
  props: ['testId'],
  components: {
    AssigmentUserForm,
    AssignedUserTable,
    AssignTestToCompaniesForm,
    TooltipTrialTest,
    TooltipTestDeleted,
    TooltipPublicTest,
    TestUpdateForm
    // AvailableCompaniesTable
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (Object.keys(vm.currentUser).length === 0) {
        return
      }
      testsApi.getTestById(to.params.testId).then(response => vm.setCurrentViewTest(response.data))
      vm.getTestableUsers()
      vm.updateAssignedTestsTable()
    })
  },
  computed: {
    ...mapState(['currentUser', 'currentViewTest', 'role']),
    sortedAlphabeticallyTopics(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.currentViewTest.topics.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    },
    progressValue() {
      let progressValue = this.currentViewTest.estimateTime / 60 * 100;
      return progressValue > 100 ? 100 : progressValue;
    },
    countAvailableTests() {
      if (this.testableUsers === null || this.testableUsers === undefined) {
        return 0
      }
      return this.testableUsers.length
    }
  },
  mounted() {
    this.getTestableUsers()
  },
  data() {
    return {
      utils: utils,
      currentTab: '',
      testableUsers: [],
      resultTest: []
    }
  },
  methods: {
    ...mapMutations(['setCurrentViewTest']),
    updateAssignedTestsTable() {
      let assignedUserTable = this.$refs.assignedUserTable;
      if (assignedUserTable != null) {
        assignedUserTable.initRequestAssignedTests();
      }
    },
    openAssigmentTestForm() {
      this.$refs.assigmentUserForm.openDialog(this.currentViewTest, this.testableUsers)
    },
    getTestableUsers() {
      if (this.role !== 'admin') {
        orgApi.getCompanyStaff(this.currentUser.orgId, 'TESTABLE').then(response => this.testableUsers = response.data.content)
      }
    },
    updateTabInfo() {
      this.updateAssignedTestsTable()
    },
    successAssign() {
      this.updateAssignedTestsTable()
    },
    openTestUpdateForm() {
      this.$refs.testUpdateForm.openDialog(this.currentViewTest);
    },
    updateTest(newVal) {
      this.setCurrentViewTest(newVal)
    }
  },
}
</script>

<style scoped>
>>> .v-data-table tr {
  cursor: pointer !important;
}

>>> .v-data-table th {
  font-size: 1.1em;
  font-weight: 700 !important;
}

>>> .v-data-table td {
  font-size: .85em;
}

>>> .scale-y-transition {
  transition: all 2s ease;
}
</style>
