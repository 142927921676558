import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import {get} from '../httpclient/http-client'

Vue.use(VueRouter);

import Tests from '../components/tests/simple/Tests.vue'
import AssignedTests from '../components/tests/assigned/AssignedTests.vue'
import Users from '../components/users/Users.vue'
import UserInfoPage from "../components/users/UserInfoPage.vue";
import TestingPage from '../components/user/TestingPage.vue'
import NotFoundPage from '../components/common/pages/NotFoundPage.vue'
import TestInfoPage from "../components/tests/simple/TestInfoPage.vue";
import AssignedTestPage from "../components/tests/assigned/AssignedTestPage.vue";
import Organizations from "../components/orgs/Organizations.vue";
import Topics from "../components/tests/topics/Topics.vue";
import OrgInfoPage from "../components/orgs/OrgInfoPage.vue";
import LoginForm from "../components/auth/LoginPage.vue";
import RegistrationPage from "../components/auth/RegistrationPage.vue";
import BillingPage from "../components/biling/BillingPage.vue";
import ExamineesPages from "../components/users/ExamineesPages.vue";
import AboutMePage from "../components/user/AboutMePage.vue";
import ConfirmationEmailPage from "../components/user/ConfirmationEmailPage.vue";
import AccountRecovery from "../components/auth/AccountRecovery";
import ResetPassword from "../components/auth/ResetPassword";
import IndividualAccountPage from "../components/individual/IndividualAccountPage";
import TopicInfoPage from "../components/tests/topics/TopicInfoPage";
import AcquiringSettingsPage from "../components/acquiring/AcquiringSettingsPage";
import FailedPaymentUrl from "../components/acquiring/FailedPaymentUrl";
import SuccessPaymentUrl from "../components/acquiring/SuccessPaymentUrl";

const routes = [
    {path: '/', name: 'login', component: LoginForm},
    {path: '/login', name: 'login', component: LoginForm},
    {path: '/registration', name: 'registration', component: RegistrationPage},
    {
        path: '/email-confirmation/:confirmationId',
        name: 'confirmationEmail',
        component: ConfirmationEmailPage,
        props: true
    }, {path: '/registration', name: 'registration', component: RegistrationPage},
    {
        path: '/account-recovery',
        name: 'accountRecovery',
        component: AccountRecovery
    },
    {
        path: '/reset-password/:uuid',
        name: 'resetPassword',
        component: ResetPassword,
        props: true
    },
    {path: '/about-me', name: 'about-me', component: AboutMePage, meta: {requiresAuth: true}},
    {
        path: '/individual/account/:id',
        props: true,
        name: 'account',
        component: IndividualAccountPage,
        meta: {requiresAuth: true}
    },
    {path: '/admin/tests', name: 'tests', component: Tests, meta: {requiresAuth: true}},
    {
        path: '/admin/acquiring-settings',
        name: 'acquiringSettings',
        component: AcquiringSettingsPage,
        meta: {requiresAuth: true}
    },
    {
        path: '/success-payment/count/:countTests',
        props: true,
        name: 'successPayment',
        component: SuccessPaymentUrl,
        meta: {requiresAuth: true}
    },
    {
        path: '/failed-payment',
        name: 'failedPayment',
        component: FailedPaymentUrl,
        meta: {requiresAuth: true}
    },
    {path: '/users', name: 'users', component: Users, meta: {requiresAuth: true}},
    {
        path: '/manager/test-info/:testId',
        name: 'testInfoPage',
        component: TestInfoPage,
        props: true,
        meta: {requiresAuth: true}
    },
    {
        path: '/manager/assigned-test/:assignedTestId',
        name: 'assignedTestPage',
        component: AssignedTestPage,
        props: true,
        meta: {requiresAuth: true}
    },
    {
        path: '/manager/user-info/:userId',
        name: 'userInfoPage',
        component: UserInfoPage,
        props: true,
        meta: {requiresAuth: true}
    },
    {path: '/user/testing', name: 'testing', component: TestingPage, meta: {requiresAuth: true}},
    {path: '/admin/organizations', name: 'organizations', component: Organizations, meta: {requiresAuth: true}},
    {
        path: '/organization/:orgId',
        name: 'organizationsInfo',
        component: OrgInfoPage,
        props: true,
        meta: {requiresAuth: true}
    },
    {path: '/admin/topics', name: 'topics', component: Topics, meta: {requiresAuth: true}},
    {
        path: '/admin/topic-info/:topicId',
        name: 'topicInfoPage',
        component: TopicInfoPage,
        props: true,
        meta: {requiresAuth: true}
    },
    {
        path: '/manager/billing/:orgId',
        name: 'PaymentPage',
        props: true,
        component: BillingPage,
        meta: {requiresAuth: true}
    },
    {
        path: '/manager/assigned-tests/:orgId',
        name: 'AssignedTests',
        component: AssignedTests,
        props: true,
        meta: {requiresAuth: true}
    },
    {path: '/manager/examinees', name: 'ExamineesPages', component: ExamineesPages, meta: {requiresAuth: true}},
    {path: '*', name: '404', component: NotFoundPage, meta: {requiresAuth: true}},
];

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && (from.name === null || from.name === 'confirmationEmail')) {
        let tokenLocalStorage = localStorage.getItem('token');

        if (tokenLocalStorage === null) {
            tokenLocalStorage = 'null'
        }

        if (tokenLocalStorage !== 'null') {
            store.commit('setToken', tokenLocalStorage)
        }

        get('/api/manager/auth/me').call
            .then(response => {
                store.commit('setCurrentUser', response.data)
                next()
            })
            .catch(() => {
                store.commit('clearSession', null, {root: true})
                next('/login')
            })
    } else {
        next()
    }
})

export default router