<template>
  <div>
      <v-data-table
          height="45vh"
          fixed-header
          :loading="isLoading"
          :headers="headers"
          :items="testable"
          hide-default-footer
          @click:row="openUserPage">
        <template v-slot:top>
          <v-layout justify-space-between align-center>
            <v-btn v-if="role!=='testable' && !utils.isDeletedEntity(currentViewOrg)" small color="primary"
                   @click="openDialogAddTestable">
              Добавить испытуемого
            </v-btn>

            <v-text-field class="shrink"
                          v-model="search"
                          clearable
                          @click:clear="clearSearch"
                          @click:prepend="initRequestExaminess"
                          @keydown.enter="initRequestExaminess"
                          prepend-icon="mdi-magnify"
                          single-line
                          label="Поиск:"/>
          </v-layout>
        </template>
        <template v-slot:item.birthday="{item}">
          {{ utils.preparedViewDate(item.birthday, 'date') }}
        </template>
        <template v-slot:item.roles="{item}">
          {{ translateRoles(item.roles) }}
        </template>
      </v-data-table>
    <testable-add-form @successAdded="successAdded" ref="testableAddForm" :orgId="orgId"/>
    <v-pagination @input="requestExaminess" v-if="pageCount>1" v-model="page" :length="pageCount"/>
  </div>

</template>

<script>
import {mapState, mapMutations} from 'vuex'

import usersApi from "../../../scripts/api/users-api"
import utils from "../../../scripts/utils";

import {translateRoles} from "../../../utils/DataUtils";
import TestableAddForm from "../forms/TestableAddForm.vue";
import UserRoleEnum from "@/classes/enums/UserRoleEnum";

export default {
  name: "OrgExamineesTable",
  props: ['orgId'],
  components: {TestableAddForm},
  computed: {
    ...mapState(['role', 'currentUser', 'currentViewOrg']),
  },
  mounted() {
    this.requestExaminess()
  },
  data: () => {
    return {
      testable: [],
      utils: utils,
      isLoading: false,
      limit: 7,
      page: 1,
      pageCount: 0,
      headers: [
        {text: 'ID', sortable: false, align: ' d-none', value: 'id'},
        {text: 'Фамилия', value: 'lastName', sortable: false},
        {text: 'Имя', value: 'firstName', sortable: false},
        {text: 'Дата рождения', value: 'birthday', sortable: false},
        {text: 'Email', value: 'email', sortable: false},
        {text: 'Номер телефона', value: 'phoneNumber', sortable: false},
        {text: '', value: 'actions', sortable: false},
      ],
      search: '',
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT_BASED_ON_CODE']),
    initRequestExaminess() {
      this.resetPage()
      this.requestExaminess()
    },
    resetPage() {
      this.page = 1
    },
    requestExaminess() {
      this.isLoading = true
      let offset = this.page - 1
      usersApi.getUsersForCompany(this.orgId, this.search, UserRoleEnum.TESTABLE, this.limit, offset)
          .then(response => {
            this.pageCount = response.data.count
            this.testable = response.data.content
          })
          .finally(() => this.isLoading = false)
    },
    clearSearch() {
      this.search = ''
      this.initRequestExaminess()
    },
    successAdded(user) {
      if (this.testable.length === this.limit) {
        this.testable = [
          user,
          ...this.testable.slice(1, this.limit - 1)]
      } else {
        this.testable.push(user)
      }
    },
    openUserPage(user) {
      this.$router.push('/manager/user-info/' + user.id);
    },
    openDialogAddTestable() {
      this.$refs.testableAddForm.openDialog()
    },
    translateRoles(roles) {
      return translateRoles(roles)
    }
  }
}
</script>

<style scoped>
</style>
