import {get, post} from "../../httpclient/http-client";
import store from "../../store";
import ErrorInfo from "../../classes/util/ErrorInfo";

export default {
    getPriceBaseTest() {
        let request = get('/api/manager/priceBaseTest');
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить стоимость базового теста.',
                    request.id)))
    },
    updatePriceBaseTest(newPrice) {
        let request = post('/api/manager/operations/priceBaseTest', newPrice);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке обновить стоимость базового теста.',
                    request.id)))
    }
}


