<template>
  <v-form v-model="valid"
          ref="form">
    <v-card>
      <v-card-text>
        <v-text-field
            label="Логин *"
            type=""
            v-model="username"
            prepend-icon="mdi-account"
            :rules="[rules.isRequired, rules.isLength128]"/>
        <v-text-field
            label="Адрес электронной почты *"
            type=""
            v-model="email"
            prepend-icon="mdi-at"
            :rules="[rules.isRequired, rules.isLength128, rules.isSuccessEmail]"/>
        <v-text-field
            :rules="[rules.isRequired, rules.isStringShort]"
            color="primary"
            label="Ваша фамилия *"
            name="login"
            v-model="lastName"
            prepend-icon="mdi-account"
            type="text"/>
        <v-text-field
            :rules="[rules.isRequired, rules.isStringShort]"
            color="primary"
            label="Ваше имя *"
            name="login"
            v-model="firstName"
            prepend-icon="mdi-account"
            type="text"/>
        <date-picker label="Дата рождения *" :max="new Date().toISOString().substr(0, 10)"
                     @saveDate="saveDateBirthday"/>
        <v-text-field
            label="Телефон *"
            v-model="phone"
            prepend-icon="mdi-phone"
            :rules="[rules.isSuccessPhoneNumber, rules.isRequired]"/>
        <v-text-field
            label="Название организации *"
            v-model="orgName"
            prepend-icon="mdi-domain"
            :rules="[rules.isRequired, rules.isStringShort]"
        />
        <v-text-field
            :rules="[rules.isRequired, rules.isStringShort, rules.isValidPassword]"
            color="primary"
            id="password"
            label="Пароль *"
            name="password"
            v-model="pass"
            prepend-icon="mdi-lock"
            type="password"/>
        <v-text-field
            :rules="[rules.isRequired, passwordConfirmationRule, rules.isStringShort]"
            color="primary"
            id="password"
            label="Повторите пароль *"
            name="repeatedPassword"
            v-model="rePass"
            prepend-icon="mdi-lock"
            type="password"/>
        <v-layout justify-center class="text-center caption">
          <v-checkbox v-model="isAcceptAgreement">
            <template v-slot:label>
              <span class="caption">
                Настоящим подтверждаю, что я ознакомлен и согласен
                <a target="_blank"
                   href="https://lk.psyscan.ru/files/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf"> на обработку персональных данных.</a></span>

            </template>
          </v-checkbox>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel" color="primary" small>Отмена</v-btn>
        <v-btn :disabled="!isAcceptAgreement"
               @click="registration"
               :loading="isLoading"
               color="primary"
               small>
          Зарегистрироваться
        </v-btn>
        <vue-recaptcha size="invisible"
                       @verify="afterVerifyCaptcha"
                       ref="recaptcha"
                       @expired="captchaReset"
                       :sitekey="recaptchaSiteKey"/>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {mapMutations} from "vuex";
import {post} from "../../../httpclient/http-client";
import ErrorInfo from "../../../classes/util/ErrorInfo";
import VueRecaptcha from "vue-recaptcha";
import DatePicker from "../../common/DatePicker";
import rules from "../../../scripts/rules";

export default {
  name: "IndividualRegistrationForm",
  computed: {
    passwordConfirmationRule() {
      return () => (this.pass === this.rePass) || 'Пароли не совпадают'
    },
    recaptchaSiteKey() {
      return process.env.VUE_APP_RE_CAPTCHA_SITE
    }
  },
  components: {VueRecaptcha, DatePicker},
  data: () => {
    return {
      isAcceptAgreement: false,
      username: '',
      pass: '',
      rePass: '',
      firstName: '',
      lastName: '',
      birthday: null,
      email: '',
      phone: '',
      orgName: '',
      valid: false,
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT_BASED_ON_CODE', 'ACTIVATE_ALERT']),
    cancel() {
      this.$refs.form.reset()
      this.isAcceptAgreement = false
      this.$router.back()
    },
    afterVerifyCaptcha(recaptchaToken) {
      this.isLoading = true
      let registrationDTO = {
        username: this.username,
        password: this.pass,
        firstname: this.firstName,
        lastname: this.lastName,
        birthday: this.birthday,
        email: this.email,
        phoneNumber: this.phone,
        companyname: this.orgName
      };
      let request = post('/api/manager/auth/registration', registrationDTO, {
        headers: {
          'g-recaptcha-response': recaptchaToken
        }
      });
      request.call
          .then(() => {
            this.$router.push('/login')
            this.$refs.form.reset()
          })
          .catch(er => {
            if (er.response.status === 409) {
              this.ACTIVATE_ALERT("Пользователь с такой почтой или логином уже существует")
            } else {
              this.ACTIVATE_ALERT_BASED_ON_CODE(new ErrorInfo(er.response.status, er.response.data.message, request.id))
            }
          })
          .finally(() => {
            this.isLoading = false
            this.captchaReset()
          })
    },
    registration() {
      this.$refs.form.validate()
      if (this.valid) {
        this.$refs.recaptcha.execute()
      }
    },
    captchaReset() {
      this.$refs.recaptcha.reset()
    },
    saveDateBirthday(newV) {
      this.birthday = newV
    }
  }
}
</script>

<style scoped>

</style>