<template>
  <div>
    <v-data-table
        height="45vh"
        :loading="isLoading"
        :headers="headers"
        :items="staff"
        hide-default-footer
        @click:row="openUserPage">
      <template v-slot:top>
        <v-layout justify-space-between align-center>
          <v-btn v-if="(role==='sup_manager' || role==='admin') && !utils.isDeletedEntity(currentViewOrg)" small
                 color="primary"
                 @click="openDialogAddEmployee">
            Добавить сотрудника
          </v-btn>

          <v-text-field class="ml-10 shrink"
                        v-model="search"
                        clearable
                        @click:clear="clearSearch"
                        @click:prepend="initRequestStaff"
                        @keydown.enter="initRequestStaff"
                        prepend-icon="mdi-magnify"
                        single-line
                        label="Поиск:"/>
        </v-layout>
      </template>
      <template v-slot:item.birthday="{item}">
        {{ utils.preparedViewDate(item.birthday, 'date') }}
      </template>
      <template v-slot:item.roles="{item}">
        {{ translateRoles(item.roles) }}
      </template>
    </v-data-table>

    <employee-add-form @successAdded="successAdded" ref="employeeAddForm" :orgId="orgId"/>
    <v-pagination v-if="pageCount>1" @input="requestStaff" v-model="page" :length="pageCount"/>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import utils from "../../../scripts/utils";

import EmployeeAddForm from "../forms/EmployeeAddForm.vue";
import {translateRoles} from "../../../utils/DataUtils";
import usersApi from "../../../scripts/api/users-api";
import UserRoleEnum from "../../../classes/enums/UserRoleEnum";

export default {
  name: "OrgStaffTable",
  props: ['orgId'],
  components: {EmployeeAddForm},
  computed: {
    ...mapState(['role', 'currentUser', 'currentViewOrg']),
  },
  data: () => {
    return {
      staff: [],
      utils: utils,
      page: 1,
      pageCount: 0,
      limit: 7,
      isLoading: false,
      headers: [
        {text: 'ID', sortable: false, align: ' d-none', value: 'id'},
        {text: 'Логин', value: 'login', sortable: false},
        {text: 'Фамилия', value: 'lastName', sortable: false},
        {text: 'Имя', value: 'firstName', sortable: false},
        {text: 'Роль', value: 'roles', sortable: false},
        {text: 'Дата рождения', value: 'birthday', sortable: false},
        {text: 'Email', value: 'email', sortable: false},
        {text: 'Номер телефона', value: 'phoneNumber', sortable: false},
        {text: '', value: 'actions', sortable: false},
      ],
      search: '',
    }
  },
  mounted() {
    this.initRequestStaff()
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT_BASED_ON_CODE']),
    initRequestStaff() {
      this.resetPage()
      this.requestStaff()
    },
    resetPage() {
      this.page = 1
    },
    requestStaff() {
      let offset = this.page - 1
      this.isLoading = true
      usersApi.getUsersForCompany(this.orgId, this.search, UserRoleEnum.MANAGER, this.limit, offset)
          .then(response => {
            this.pageCount = response.data.count
            this.staff = response.data.content
          })
          .finally(() => this.isLoading = false)
    },
    clearSearch() {
      this.search = ''
      this.initRequestStaff()
    },
    successAdded(user) {
      this.staff.push(user)
    },
    openUserPage(user) {
      this.$router.push('/manager/user-info/' + user.id)
    },
    openDialogAddEmployee() {
      this.$refs.employeeAddForm.openDialog()
    },
    translateRoles(roles) {
      return translateRoles(roles)
    }
  }
}
</script>

<style scoped>
</style>
