import '@mdi/font/css/materialdesignicons.css'

import Vue from 'vue'
import Vuetify from 'vuetify'
//Настройка поддерживаемых языков vuetify (по умолчанию английский для компонентов от библиотеки vuetify)
import ru from 'vuetify/lib/locale/ru'
import en from 'vuetify/lib/locale/en'
//Таблицы стилей приложения
import '../public/css/psy-vue.css'
import 'vuetify/dist/vuetify.min.css'
//Для конвертации кода в js код, поддерживаемый старыми браузерами
import 'babel-polyfill'
//Реактивное хранилище данных приложения
import store from '@/store'
//Навигация приложения
import router from '@/router'
//Главный компонент приложения
import App from './App'

Vue.config.productionTip = false

Vue.use(Vuetify, {iconfont: 'mdi'})
let vuetify = new Vuetify({
    lang: {
        locales: {ru, en},
        current: 'ru'
    },
    theme: {
        themes: {
            light: {
                primary: '#008000'
            },
            dark: {
                primary: '#008000'
            }
        }
    }
});

//Инициализируем клиентское приложение
new Vue({
    el: '#app',
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')