export default class TestDTO {
    constructor(id, name, topicsId, description, estimateTime, topics, weight, isPublic) {
        this.id = id;
        this.name = name;
        this.topicsId = topicsId;
        this.description = description;
        this.estimateTime = estimateTime;
        this.topics = topics;
        this.weight = weight;
        this.isPublic = isPublic;
    }
}
