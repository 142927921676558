<template>
  <div>
    <v-dialog eager persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-title>
            Изменение данных о пользователе {{ user.login }}
          </v-card-title>
          <v-card-text>
            <v-form ref="updateForm">
              <v-text-field label="Имя *" v-model="firstName"
                            :rules="[rules.isRequired]"/>
              <v-text-field label="Фамилия *" v-model="lastName"
                            :rules="[rules.isRequired]"/>
              <v-text-field label="Телефон" v-model="phone"
                            :rules="[rules.isSuccessPhoneNumber()]"/>
              <date-picker label="Дата рождения *" :max="new Date().toISOString().substr(0, 10)"
                           @saveDate="save" :startValue="birthday" ref="datePicker"/>
              <v-select v-if="role === 'admin'" label="Роль пользователя *" multiple :items="ROLES"
                        item-value="rawValue"
                        item-text="textValue" v-model="user.roles" :rules="[rules.notEmptyList]"/>
              <!--                            <v-file-input show-size counter v-model="user.avatarFile" label="Аватар профиля"/>-->
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" small dark @click="closeDialog">Отменить</v-btn>
            <v-btn color="primary" small dark class="btn" @click="updateUser" :loading="isLoading">Сохранить</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'

import UserDTO from "../../../classes/dto/UserDTO";
import UserRole from "../../../classes/util/UserRole";
import usersApi from "../../../scripts/api/users-api"

import DatePicker from "../../common/DatePicker";
import rules from "../../../scripts/rules";

export default {
  components: {
    DatePicker
  },
  name: "UserUpdateForm",
  computed: {
    ...mapState(['role'])
  },
  data() {
    return {
      ROLES: [new UserRole('TESTABLE'),
        new UserRole('MANAGER'),
        new UserRole('SUPER_MANAGER'),
        new UserRole('ADMINISTRATOR')],
      isOpenDialog: false,
      login: '',
      lastName: '',
      firstName: '',
      email: '',
      birthday: null,
      phone: '',
      user: {login: ''},
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT']),
    openDialog(user) {
      this.user = user
      this.login = user.login
      this.lastName = user.lastName
      this.firstName = user.firstName
      this.email = user.email
      this.birthday = user.birthday
      this.$refs.datePicker.updateStartValue(user.birthday)
      this.phone = user.phoneNumber
      this.isOpenDialog = true
    },
    updateUser() {
      if (this.$refs.updateForm.validate()) {
        this.isLoading = true
        let userDTO = new UserDTO(
            this.user.id,
            this.login,
            this.firstName,
            this.lastName,
            this.birthday,
            this.email,
            this.phone,
            this.user.roles,
            this.user.orgId,
            this.user.org);
        usersApi.updateUser(userDTO)
            .then(response => {
              console.log("Updated user. Status: ", response.status)
              this.$emit('successUpdate', userDTO)
            }).finally(() => this.isLoading = false)
        this.closeDialog();
      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для обновления пользователя " + this.user.login +
            ", проверьте данные и попробуйте снова.");
      }
    },
    closeDialog() {
      this.isOpenDialog = false;
    },
    save(date) {
      this.birthday = date
    }
  },
}
</script>

<style scoped>
</style>
