<template>
    <v-row>
        <v-col lg="10" offset-lg="1">
            <v-container>
                <v-row>
                    <v-col class="pt-0 " lg="9">
                        <h1 class="display-1 pb-3">Назначенные тесты</h1>
                        <v-card>
                            <v-card-title>Наименование теста</v-card-title>
                            <v-card-text>
                                Описание:<br>
                                Длительность теста {{timing}} мин.
                                <v-progress-linear
                                        :value="progressValue"
                                        height="3"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click="loadTest" color="primary">Пройти тест</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col lg="2" offset-lg="1">
                        <v-btn color="primary">
                            История тестов
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "Testing",
        computed: {
            progressValue() {
                let progressValue = this.timing / 60 * 100;
                return progressValue > 100 ? 100 : progressValue;
            }
        },
        data() {
            return {
                timing: 20
            }
        },
        methods: {
            loadTest() {
                alert('Будет скачивание теста на машину пользователя');
            }
        }
    }
</script>

<style scoped>

</style>
