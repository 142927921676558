<template>
  <div>
    <!--        <v-text-field class="ml-10 shrink"-->
    <!--                      v-model="search"-->
    <!--                      clearable-->
    <!--                      @click:clear="clearSearch"-->
    <!--                      @keydown.enter="getPayments"-->
    <!--                      prepend-icon="mdi-magnify"-->
    <!--                      single-line-->
    <!--                      label="Поиск:"/>-->
    <v-data-table height="50vh"
                  :loading="isLoading"
                  class="mt-5 mb-5"
                  :headers="headers"
                  :items="payments"
                  hide-default-footer
                  :search="search"
                  dense
    >

      <template v-slot:item.expiredDate="{item}">
        {{ utils.preparedViewDate(item.expiredDate, 'date') }}
      </template>
      <template v-slot:item.amount="{item}">
        {{ (utils.viewAmount(item.amount)) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-layout v-if="role==='admin'&& item !== undefined && item.status ==='Активный'" justify-end>
          <v-btn icon @click.stop="update(item)">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn icon @click.stop="cancel(item)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>
      </template>
    </v-data-table>

    <v-pagination v-if="pageCount>1" @input="getPayments" v-model="page" :length="pageCount"/>

    <canceled-payment-form :orgId="orgId" ref="canceledPaymentForm" @increaseBalance="increaseBalance"
                           @successCancel="updatePayments"/>
    <update-payment-form :orgId="orgId" ref="updatePaymentForm" @increaseBalance="increaseBalance"
                         @successUpdate="updatePayments"/>
  </div>

</template>

<script>
import billingApi from "../../../scripts/api/billing-api";
import utils from "../../../scripts/utils";
import {mapState} from "vuex";

import CanceledPaymentForm from "../forms/CanceledPaymentForm.vue"
import UpdatePaymentForm from "../forms/UpdatePaymentForm.vue"

export default {
  name: "PaymentsTable",
  props: ['orgId'],
  components: {CanceledPaymentForm, UpdatePaymentForm},
  computed: {
    ...mapState(['role']),
  },
  data: () => {
    return {
      utils: utils,
      isLoading: false,
      limit: 10,
      search: '',
      page: 1,
      pageCount: 0,
      payments: [],
      headers: [
        {text: 'ID', sortable: false, align: ' d-none', value: 'id'},
        {text: 'Количество', value: 'amount', sortable: false},
        {text: 'Действителен до', value: 'expiredDate', sortable: false},
        {text: 'Статус', value: 'status', sortable: false},
        {text: 'Комментарий', value: 'comment', sortable: false},
        {text: '', value: 'actions', sortable: false}
      ],
    }
  },
  mounted() {
    this.initGetPayments()
  },
  methods: {
    initGetPayments() {
      this.resetPage()
      this.getPayments()
    },
    getPayments() {
      this.isLoading = true
      let offset = this.page - 1
      billingApi.getPayments(this.orgId, this.search, this.limit, offset)
          .then(response => {
            this.pageCount = response.data.count
            this.payments = response.data.content
          })
          .finally(() => this.isLoading = false)
    },
    resetPage() {
      this.page = 1
    },
    cancel(item) {
      this.$refs.canceledPaymentForm.openDialog(item)
    },
    update(item) {
      this.$refs.updatePaymentForm.openDialog(item)
    },
    increaseBalance(amount) {
      this.$emit('increaseBalance', amount)
    },
    updatePayments(updatedPayment) {
      let needIndex = this.payments.findIndex(p => p.id === updatedPayment.id)
      this.payments = [
        ...this.payments.slice(0, needIndex),
        updatedPayment,
        ...this.payments.slice(needIndex + 1,)]
    },
    clearSearch() {
      this.search = ''
      // this.getPayments()
    }
  }
}
</script>

<style scoped>

</style>