<template>
  <div>
    <v-dialog persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-text>
            <v-form ref="addingForm">
              <v-text-field
                  prepend-icon="mdi-cash-multiple"
                  label="Сумма *"
                  v-model="amount"
                  type="number"
                  min="1"
                  :rules="[rules.isRequired, rules.minSum, rules.isInt32]"/>
              <custom-date-picker ref="customDatePicker" label="Срок действия оплаты *"
                                  :min="new Date().toISOString().substr(0, 10)"
                                  @saveDate="saveExpiredDate"/>
              <v-textarea
                  rows="1"
                  auto-grow
                  clearable
                  v-model="comment"
                  :rules="[rules.isStringShort]"
                  prepend-icon="mdi-comment-edit-outline"
                  label="Комментарий"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" small @click="closeDialog">Отменить</v-btn>
            <v-btn color="primary" small class="btn" @click="createPayment" :loading="isLoading">Добавить</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import CustomDatePicker from "../../common/DatePicker";

import billingApi from '../../../scripts/api/billing-api'
import utils from "../../../scripts/utils";
import rules from "../../../scripts/rules";

export default {
  components: {
    CustomDatePicker
  },
  name: "CreatePaymentForm",
  props: ["orgId"],
  data() {
    return {
      isOpenDialog: false,
      amount: '',
      expiredDate: null,
      comment: '',
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT', 'ACTIVATE_INFO_ALERT']),
    ...mapMutations('confirmationModule', ['openConfirmationDialog']),
    createPayment() {
      if (this.$refs.addingForm.validate()) {
        this.isLoading = true
        this.openConfirmationDialog({
          action: () => billingApi.createPayment(this.orgId, {
            status: 'ACTIVE',
            amount: this.amount,
            expiredDate: this.expiredDate,
            comment: this.comment
          }).then(response => {
            this.ACTIVATE_INFO_ALERT('Оплата успешно совершена в размере ' + this.amount);
            this.$emit('increaseBalance', response.data.amount)
            this.closeDialog()
          }).finally(() => this.isLoading = false),
          description: ' добавить оплату в размере ' + utils.viewAmount(this.amount) + ', действующую до ' +
              utils.preparedViewDate(this.expiredDate, 'date')
        })

      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для добавления оплаты, проверьте данные и попробуйте снова.");
      }
    },
    saveExpiredDate(expiredDate) {
      this.expiredDate = expiredDate
    },
    openDialog() {
      this.isOpenDialog = true;
    },
    closeDialog() {
      this.isOpenDialog = false;
      this.comment = ' '
      this.amount = ''
      this.expiredDate = null
      this.$refs.customDatePicker.resetDate()
      this.$refs.addingForm.resetValidation()
    }
  }
}
</script>

<style scoped>

</style>