<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :rules="[rules.isRequired]"
          v-model="viewDate"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        color="primary"
        v-model="valueDate"
        :active-picker.sync="activePicker"
        :min="min"
        :max="max"
        @change="save"/>
  </v-menu>
</template>

<script>
import utils from "../../scripts/utils";
import rules from "../../scripts/rules";

export default {
  name: "DatePicker",
  props: ['label', 'min', 'max', 'startValue'],
  mounted() {
    if (this.startValue !== undefined) {
      this.valueDate = this.startValue
    }
  },
  data: () => {
    return {
      activePicker: null,
      menu: false,
      valueDate: null,
      viewDate: null,
      rules: rules
    }
  },
  methods: {
    save() {
      this.$refs.menu.save(this.valueDate)
      this.$emit('saveDate', this.valueDate)
    },
    updateStartValue(updatedVal) {
      this.valueDate = updatedVal
    },
    resetDate() {
      this.valueDate = this.startValue
    }
  },
  watch: {
    valueDate(newVal) {
      this.viewDate = utils.preparedViewDate(newVal, 'date')
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  }
}
</script>

<style scoped>

</style>