<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <h1 class="display-1 pb-0">Организации</h1>
        <v-card class="pb-10" flat>
          <v-card-title class="pa-0">
            <v-btn small color="primary" @click="openDialogCreateOrg">
              Добавить
            </v-btn>
            <v-spacer/>
            <v-text-field class="shrink"
                          v-model="search"
                          clearable
                          @click:clear="clearSearch"
                          @keydown.enter="initGetOrgs"
                          @click:prepend="initGetOrgs"
                          prepend-icon="mdi-magnify"
                          single-line
                          label="Поиск:"/>
          </v-card-title>
          <v-data-table flat
                        :loading="isOrgsLoading"
                        :headers="headers"
                        :items="orgs"
                        hide-default-footer
                        @click:row="openOrgPage"
          >
            <template v-slot:item.actions="{ item }">
              <v-layout justify-end>
                <v-btn x-small icon @click.stop="openDialogUpdateOrg(item)">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn x-small icon @click.stop="removeOrg(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-layout>
            </template>
          </v-data-table>
          <v-pagination v-if="pageCount>1" @input="getOrgs" v-model="page" :length="pageCount"></v-pagination>
        </v-card>

        <org-add-form @successCreate="createOrg" ref="orgAddForm"/>
        <org-update-form @successUpdate="updateOrg" ref="orgUpdateForm"/>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import {mapMutations} from 'vuex'

import OrgAddForm from "./forms/OrgAddForm.vue";
import OrgUpdateForm from "./forms/OrgUpdateForm.vue";

import orgApi from '../../scripts/api/org-api'

export default {
  components: {
    OrgAddForm,
    OrgUpdateForm
  },
  name: "Organizations",
  data() {
    return {
      page: 1,
      pageCount: 0,
      isOrgsLoading: false,
      search: '',
      limit: 10,
      orgs: [],
      headers: [
        {text: 'ID', sortable: false, align: ' d-none', value: 'id'},
        {text: 'Наименование', value: 'name', sortable: false},
        {text: 'Телефон', value: 'phoneNumber', sortable: false},
        {text: 'Email', value: 'email', sortable: false},
        // {text: 'Юр. адрес', value: 'legalAddress'},
        // {text: 'ИНН', value: 'taxpayerNumber'},
        // {text: 'КПП', value: 'kpp'},
        {text: '', value: 'actions', sortable: false},
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.initGetOrgs()
    })
  },
  methods: {
    ...mapMutations('confirmationModule', ['openConfirmationDialog']),
    resetPage() {
      this.page = 1
    },
    initGetOrgs() {
      this.resetPage()
      this.getOrgs()
    },
    getOrgs() {
      this.isOrgsLoading = true
      let offset = this.page - 1

      orgApi.getOrgs(this.search, this.limit, offset)
          .then(response => {
            this.pageCount = response.data.count
            this.orgs = response.data.content
          })
          .finally(() => this.isOrgsLoading = false)
    },
    clearSearch() {
      this.search = ''
      this.initGetOrgs()
    },
    updateOrg(org) {
      let needIndex = this.orgs.findIndex(o => o.id === org.id)
      this.orgs = [
        ...this.orgs.slice(0, needIndex),
        org,
        ...this.orgs.slice(needIndex + 1,)]
    },
    createOrg(org) {
      if (this.orgs.length === this.limit) {
        this.orgs = [
          org,
          ...this.orgs.slice(1, this.limit - 1)]
      } else {
        this.orgs.push(org)
      }
    },
    removeOrg(org) {
      this.openConfirmationDialog({
        description: ' удалить организацию "' + org.name + '"',
        action: () => orgApi.removeOrg(org).then(() => {
          let needIndex = this.orgs.findIndex(o => o.id === org.id)
          this.orgs = [
            ...this.orgs.slice(0, needIndex),
            ...this.orgs.slice(needIndex + 1,)]
        })
      })
    },
    openDialogCreateOrg() {
      this.$refs.orgAddForm.openDialog();
    },
    openDialogUpdateOrg(org) {
      this.$refs.orgUpdateForm.openDialog(org);
    },
    openOrgPage(org) {
      this.$router.push('/organization/' + org.id);
    }
  }
}
</script>

<style scoped>
>>> .v-data-table tr {
  cursor: pointer !important;
}

>>> .v-data-table th {
  font-size: 1.1em;
  font-weight: 700 !important;
}

>>> .v-data-table td {
  font-size: .85em;
}
</style>
