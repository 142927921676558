export default {
    namespaced: true,
    state: {
        isActiveConfirmationDialog: false,
        actionDescription: '',
        action: null
    },
    mutations: {
        openConfirmationDialog(state, data) {
            state.action = data.action
            state.actionDescription = data.description
            state.isActiveConfirmationDialog = true
        },
        closeConfirmationDialog(state) {
            state.isActiveConfirmationDialog = false
            state.actionDescription = ''
            state.action = null
        }
    }
}
