import Vue from 'vue'
import Vuex from 'vuex'

import customAlertModule from './modules/common/custom-alert-module'
import confirmationModule from './modules/common/confirmation-module'
import UserRoleEnum from "../classes/enums/UserRoleEnum";
import OrganizationDTO from "../classes/dto/OrganizationDTO";
import TestDTO from "../classes/dto/TestDTO";

Vue.use(Vuex);

export default new Vuex.Store({
    strict: false,
    namespaced: true,
    modules: {
        customAlertModule,
        confirmationModule
    },
    state: {
        isMainLoading: false,
        token: null,
        currentUser: {},
        role: null,
        currentViewOrg: new OrganizationDTO(null, null, null, null),
        currentViewTest: new TestDTO('', '', [], '', '', [])
    },
    mutations: {
        setCurrentViewOrg(state, newValue) {
            state.currentViewOrg = newValue
        },
        setCurrentViewTest(state, newValue) {
            state.currentViewTest = newValue
        },
        setIsMainLoading(state, newValue) {
            state.isMainLoading = newValue
        },
        setToken(state, newValue) {
            state.token = newValue
            localStorage.token = newValue
        },
        setCurrentUser(state, newValue) {
            let roles = newValue.roles;
            if (roles.includes(UserRoleEnum.ADMINISTRATOR)) {
                state.role = "admin"
            } else if (roles.includes(UserRoleEnum.SUPER_MANAGER) && !roles.includes(UserRoleEnum.INDIVIDUAL)) {
                state.role = "sup_manager"
            } else if (roles.includes(UserRoleEnum.MANAGER) && !roles.includes(UserRoleEnum.INDIVIDUAL)) {
                state.role = 'manager'
            } else if (roles.includes(UserRoleEnum.INDIVIDUAL)) {
                state.role = "individual"
            } else {
                state.role = "testable"
            }
            state.currentUser = newValue
        },
        clearSession(state) {
            state.token = null
            state.currentUser = {}
            localStorage.token = null
        }
    }
})
