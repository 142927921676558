<template>
  <div>
    <v-dialog persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-title>
            Форма изменения топика
            <v-spacer/>
            <v-btn large icon color="primary" @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>

            <v-form ref="risksForm" v-model="isValidRisksForm">
              <v-card flat>
                <v-text-field :rules="[rules.isRequired, rules.isStringShort]" v-model="topicName" label="Имя темы"/>
                <v-textarea rows="1" auto-grow :rules="[rules.isRequired, rules.isStringLong]" clearable
                            v-model="lowRiskLevel"
                            label="Интерпретация низкого уровня риска"/>
                <br>
                <v-textarea rows="1" auto-grow :rules="[rules.isRequired, rules.isStringLong]" clearable
                            v-model="midRiskLevel"
                            label="Интерпретация среднего уровня риска"/>
                <br>
                <v-textarea rows="1" auto-grow :rules="[rules.isRequired, rules.isStringLong]" clearable
                            v-model="highRiskLevel"
                            label="Интерпретация высокого уровня риска"/>
              </v-card>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn small color="primary" @click="updateTopic" :loading="isLoading">Сохранить</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import TopicDTO from "../../../../classes/dto/TopicDTO";
import topicsApi from "../../../../scripts/api/topics-api";
import rules from "../../../../scripts/rules";

export default {
  name: "TopicUpdateForm",
  computed: {
    listStringStimulus() {
      if (this.currentStep !== 4) {
        return ''
      }
      let stimulus = ''
      this.listStimulus.forEach(item => {
        stimulus += " " + item.stimulus
      })
      return stimulus
    }
  },
  data() {
    return {
      menu: false,
      isOpenDialog: false,
      isValidRisksForm: false,
      topic: '',
      topicName: '',
      highRiskLevel: '',
      midRiskLevel: '',
      lowRiskLevel: '',
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT']),
    openDialog(topic) {
      if (this.$refs.risksForm !== undefined) {
        this.$refs.risksForm.reset()
      }
      this.topic = topic
      this.highRiskLevel = topic.highRiskLevel
      this.midRiskLevel = topic.midRiskLevel
      this.lowRiskLevel = topic.lowRiskLevel
      this.topicName = topic.name
      this.isOpenDialog = true
    },
    updateTopic() {
      this.isLoading = true
      let topicDTO = new TopicDTO(this.topic.id, this.topicName, this.topic.stimulus,
          this.highRiskLevel, this.midRiskLevel, this.lowRiskLevel);
      topicsApi.updateTopic(topicDTO)
          .then(response => {
            console.log("Updated topic. Status: ", response.status)
            this.$emit('successUpdate', topicDTO)
            this.closeDialog()
          }).finally(() => this.isLoading = false)
    },
    closeDialog() {
      this.isOpenDialog = false
      this.topic = {}
      this.$refs.risksForm.reset()
    }
  }
}
</script>

<style scoped>
.v-stepper, .v-stepper__header {
  box-shadow: 0px 0px !important;
}
</style>
