var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"height":"40vh","loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.assignedTests,"hide-default-footer":""},on:{"click:row":_vm.openAssignedTest},scopedSlots:_vm._u([{key:"item.user.login",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.login)+" "),(_vm.utils.isDeletedEntity(item.user))?_c('tooltip-user-deleted',{attrs:{"size":'small',"location":'bottom'}}):_vm._e()]}},{key:"item.user.org.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.org.name)+" "),(_vm.utils.isDeletedEntity(item.user.org))?_c('tooltip-org-deleted',{attrs:{"size":'small',"location":'bottom'}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-end":""}},[(_vm.role!=='admin' && item.status.value ==='ASSIGNED' && !_vm.isDeletedEntities(item))?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.cancelAssignedTest(item)}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Отменить назначенный тест")])]):_vm._e()],1)]}},{key:"item.expiredDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.utils.preparedViewDate(item.expiredDate, 'date'))+" ")]}},{key:"item.status.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.utils.resolveAssignedTestStatus(item.status.value))+" ("+_vm._s(_vm.utils.preparedViewDate(item.status.datetime, 'datetime'))+") ")]}},{key:"top",fn:function(){return [_c('v-row',{staticClass:"pt-2"},[_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",attrs:{"clearable":"","label":"Поиск","prepend-icon":"mdi-magnify"},on:{"click:clear":_vm.clearSearch,"click:prepend":_vm.initRequestAssignedTests,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.initRequestAssignedTests.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}])}),(_vm.pageCount>1)?_c('v-pagination',{attrs:{"length":_vm.pageCount},on:{"input":_vm.requestAssignedTests},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }