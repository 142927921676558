<template>
  <v-container
      class="fill-height"
      fluid
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          style="text-align: center"
          cols="12"
          xl="6"
          lg="6"
          sm="12"
          md="9"
      >
        <v-img contain max-height="120"
               src="/images/PsyScannerLogo.png"/>
        <br>
        <v-layout align-center justify-center>
          <h1 class="headline text-center">404 Ошибка! Страница не найдена!</h1>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "NotFoundPage",

}
</script>

<style scoped>

</style>