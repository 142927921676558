import {get, post, put, remove} from "../../httpclient/http-client";
import store from "../../store";
import ErrorInfo from "../../classes/util/ErrorInfo";

export default {
    getUsers(search, limit, offset) {
        let params = {}
        params.limit = limit ?? 0x7fffffff
        params.offset = offset ?? 0
        if (search !== null && search !== undefined && search.length > 0) {
            params.search = search
        }
        let request = get('/api/manager/users', {params: params});
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    "Произошла ошибка при попытке выгрузить пользователей.",
                    request.id)))
    },
    getUsersForCompany(orgId, search, role, limit, offset) {
        let params = {}
        params.limit = limit ?? 0x7fffffff
        params.offset = offset ?? 0
        params.role = role
        if (search !== null && search !== undefined && search.length > 0) {
            params.search = search
        }
        let request = get('/api/manager/companies/' + orgId + '/users', {params: params});
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    "Произошла ошибка при попытке выгрузить испытуемых.",
                    request.id)))
    },
    updateUser(user) {
        let request = put('/api/manager/users', user);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    "Произошла ошибка при попытке обновить пользователя " + user.login + '"',
                    request.id)))
    },
    removeUser(user) {
        let request = remove('/api/manager/users/' + user.id);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке удалить пользователя "' + user.login + '"',
                    request.id)))
    },
    addUser(user) {
        let request = post('/api/manager/users', user);
        return request.call
            .catch(er => {
                if (er.response.status === 409) {
                    store.commit('customAlertModule/ACTIVATE_ALERT', 'Пользователь с такой почтой или логином уже существует')
                } else {
                    store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                        new ErrorInfo(er.response.status,
                            "Произошла ошибка при попытке добавления нового пользователя.",
                            request.id))
                }
            })
    }
}