<template>
  <div>
    <v-dialog persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-text>
            <v-form ref="addingForm">
              <v-text-field
                  prepend-icon="mdi-lock"
                  label="Старый пароль"
                  type="password"
                  v-model="oldPass"
                  :rules="[rules.isRequired, rules.isStringShort]"/>
              <v-text-field
                  :rules="[rules.isRequired, rules.isStringShort, rules.isValidPassword]"
                  color="primary"
                  label="Новый пароль"
                  name="password"
                  v-model="newPass"
                  :prepend-icon="equalsPass ? 'mdi-lock' :'mdi-lock-alert'"
                  type="password"/>
              <v-text-field
                  :rules="[rules.isRequired, passwordConfirmationRule, rules.isStringShort]"
                  color="primary"
                  label="Повторите пароль"
                  name="repeatedPassword"
                  v-model="rePass"
                  :prepend-icon="equalsPass ? 'mdi-lock' :'mdi-lock-alert'"
                  type="password"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" small dark @click="closeDialog">Отмена</v-btn>
            <v-btn color="primary"
                   small
                   dark
                   class="btn"
                   @click="changePass"
                   :loading="isLoading">
              Изменить
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {mapMutations} from "vuex";
import {post} from "../../../httpclient/http-client";
import rules from "../../../scripts/rules";

export default {
  name: "ChangePassForm",
  computed: {
    equalsPass() {
      if (this.newPass === '') {
        return false
      }
      return this.newPass === this.rePass
    },
    passwordConfirmationRule() {
      return () => this.equalsPass || 'Новые пароли не совпадают'
    },
  },
  data() {
    return {
      isOpenDialog: false,
      oldPass: '',
      newPass: '',
      rePass: '',
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT', 'ACTIVATE_INFO_ALERT']),
    openDialog() {
      this.isOpenDialog = true;
    },
    changePass() {
      if (this.$refs.addingForm.validate()) {
        this.isLoading = true
        let request = post('/api/manager/auth/change-password', {
          oldPassword: this.oldPass,
          newPassword: this.newPass
        });
        request.call
            .then(() => this.ACTIVATE_INFO_ALERT('Пароль успешно изменен.'))
            .catch(er => {
              let erMessage = er.response.status === 400 ? 'Вы указани неверный текущий пароль, попробуйте снова' :
                  'Произошла ошибка при попытке поменять пароль, обратитсь в службу поддержки. Код ошибки: ' + request.id
              this.ACTIVATE_ALERT(erMessage)
            })
            .finally(() => {
              this.isLoading = false
              this.isOpenDialog = false
            })
      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для добавления нового теста, проверьте данные " +
            "и попробуйте снова.");
      }
    },
    closeDialog() {
      this.isOpenDialog = false;
      this.$refs.addingForm.reset()
      this.$emit('resetState')
    }
  }
}
</script>

<style scoped>

</style>
