<template>
  <v-tooltip bottom color="primary">
    <template v-slot:activator="{ on }">
      <v-icon :large="size==='large'" :small="size==='small'" color="primary" v-on="on" >mdi-alpha-p-box</v-icon>
    </template>
    <span class="white--text">Публичный тест</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "TooltipPublicTest",
  props: ['size']
}
</script>

<style scoped>
.v-image {
  display: inline-block;
}
</style>