<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <v-row>
          <v-col class="pb-0" xl="12" lg="12" md="12" sm="12">
            <v-layout align-center justify-start>
              <h1 class="display-1 mr-5">Тема теста: {{ topic.name }}</h1>
            </v-layout>

          </v-col>
          <v-col xl="12" lg="12" md="12" sm="12">
            <v-btn

                small color="primary" @click="openTopicUpdateForm" class="mr-5">
              <v-icon class="pr-2">mdi-pencil-outline</v-icon>
              Редактировать
            </v-btn>
          </v-col>
        </v-row>

        <br>
        <v-row>
          <v-col lg="12" md="12" sm="12">
            <b>Стимулы:</b><br>
            <span v-for="stimul in topic.stimulus" :key="stimul.id">{{ stimul.stimulus }}<br></span><br>

            <b>Интерпретация низкого уровня риска:</b> {{ topic.lowRiskLevel }}<br><br>
            <b>Интерпретация среднего уровня риска</b> {{ topic.midRiskLevel }}<br><br>
            <b>Интерпретация высокого уровня риска</b> {{ topic.highRiskLevel }}<br><br>


          </v-col>
        </v-row>
      </v-container>
    </v-col>

    <topic-update-form @successUpdate="updateTopic" ref="topicUpdateForm"/>
  </v-row>
</template>

<script>
import topicsApi from '../../../scripts/api/topics-api'
import TopicUpdateForm from "./forms/TopicUpdateForm";

export default {
  components: {TopicUpdateForm},
  props: ['topicId'],
  name: "TopicInfoPage",
  data: () => {
    return {
      topic: {}
    }
  },
  mounted() {
    topicsApi.getTopicBy(this.topicId).then(response => this.topic = response.data)
  },
  methods: {
    openTopicUpdateForm() {
      this.$refs.topicUpdateForm.openDialog(this.topic)
    },
    updateTopic(updatedTopic) {
      this.topic = updatedTopic
    }
  }
}
</script>

<style scoped>

</style>