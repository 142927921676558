import {get, post, put, remove} from "../../httpclient/http-client";
import store from "../../store";
import ErrorInfo from "../../classes/util/ErrorInfo";

const defaultParams = {
    params: {
        limit: 0x7fffffff
    }
}

export default {
    getOrgById(id) {
        let request = get('/api/manager/companies/' + id);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить информацию об организации.',
                    request.id)))
    },
    getOrgs(search, limit, offset) {
        let params = {}
        params.limit = limit ?? 0x7fffffff
        params.offset = offset ?? 0
        if (search !== null && search !== undefined && search.length > 0) {
            params.search = search
        }

        let request = get('/api/manager/companies/', {params: params});
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить список организаций.',
                    request.id)))

    },
    //type или CONTRACT, или SELF_SERVICE
    assignTests(orgId, testsType, listTestsId) {
        let request = post('/api/manager/companies/' + orgId + '/tests', {tests: listTestsId, testsType: testsType});
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выдать доступ организации к тестам.',
                    request.id)))
    },
    getTests(orgId, search, limit, offset) {
        let params = {}
        params.limit = limit ?? 0x7fffffff
        params.offset = offset ?? 0
        if (search !== null && search !== undefined && search.length > 0) {
            params.search = search
        }

        let request = get("/api/manager/companies/" + orgId + "/tests", {params: params});
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить тесты, доступные организации.',
                    request.id)))
    },
    getAssignedTests(orgId, search, limit, offset) {
        let params = {}
        params.limit = limit ?? 0x7fffffff
        params.offset = offset ?? 0
        if (search !== null && search !== undefined && search.length > 0) {
            params.search = search
        }
        let request = get("/api/manager/companies/" + orgId + "/tests/assigned", {params: params});
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить назнчаенные тесты на пользователей.',
                    request.id)))
    },
    getCompanyStaff(orgId, role) {
        let url = "/api/manager/companies/" + orgId + "/users";
        if (role !== undefined && role !== null) {
            url += "?role=" + role
        }
        let request = get(url, defaultParams);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить пользователей компании.',
                    request.id)))
    },
    addOrg(org) {
        let request = post('/api/manager/companies', org);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    "Произошла ошибка при попытке добавления новой организации.",
                    request.id)))
    },
    updateOrg(org) {
        let request = put('/api/manager/companies', org);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    "Произошла ошибка при попытке обновления информации об организации: " + org.name,
                    request.id)))
    },
    removeOrg(org) {
        let request = remove('/api/manager/companies/' + org.id);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    "Произошла ошибка при попытке удалить организацию: " + org.name,
                    request.id)))
    }
}