<template>
  <div>
    <v-dialog persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-text>
            <v-form ref="addingForm">
              <v-text-field
                  prepend-icon="mdi-clipboard-check-multiple-outline"
                  label="Количество тестов *"
                  v-model="countTests"
                  type="number"
                  min="1"
                  :rules="[rules.isRequired, rules.minSum]"/>
              <v-text-field
                  prepend-icon="mdi-account-cash-outline"
                  v-model="sum"
                  readonly
                  label="Сумма к оплате"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" small @click="closeDialog">Отменить</v-btn>
            <v-btn color="primary" small class="btn" @click="createPayment" :loading="isLoading">Перейти к оплате
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'

import rules from "../../../scripts/rules";
import acquiringApi from '../../../scripts/api/acquiring-api'

export default {
  name: "CreatePaymentForm",
  props: ["orgId"],
  computed: {
    sum() {
      if (this.countTests === undefined) {
        return 0
      }
      return this.countTests * this.priceBaseTest
    },
    ...mapState(['currentUser'])
  },
  data() {
    return {
      isOpenDialog: false,
      countTests: undefined,
      rules: rules,
      isLoading: false,
      priceBaseTest: 0
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT', 'ACTIVATE_INFO_ALERT']),
    ...mapMutations('confirmationModule', ['openConfirmationDialog']),
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    createPayment() {
      if (this.$refs.addingForm.validate()) {
        this.isLoading = true
        window.location = window.origin + '/files/payment.html?amount=' + this.sum +
            '&orderNumber=' + this.getRandomInt(40000) +
            '&countTests=' + this.countTests +
            '&email=' + this.currentUser.email +
            '&clientInfo=' + this.currentUser.lastName + ' ' + this.currentUser.firstName
        // this.openConfirmationDialog({
        //   action: () => billingApi.createPayment(this.orgId, {
        //     status: 'ACTIVE',
        //     amount: this.amount,
        //     expiredDate: this.expiredDate,
        //     comment: this.comment
        //   }).then(response => {
        //     this.ACTIVATE_INFO_ALERT('Оплата успешно совершена в размере ' + this.amount);
        //     this.$emit('increaseBalance', response.data.amount)
        this.closeDialog()
        //   }).finally(() => this.isLoading = false),
        //   description: ' добавить оплату в размере ' + utils.viewAmount(this.amount) + ', действующую до ' +
        //       utils.preparedViewDate(this.expiredDate, 'date')
        // })

      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для добавления оплаты, проверьте данные и попробуйте снова.");
      }
    },
    saveExpiredDate(expiredDate) {
      this.expiredDate = expiredDate
    },
    openDialog() {
      acquiringApi.getPriceBaseTest().then(response => {
        this.priceBaseTest = response.data.value
        this.isOpenDialog = true;
      })
    },
    closeDialog() {
      this.isOpenDialog = false;
      this.comment = ' '
      this.amount = ''
      this.expiredDate = null
    }
  }
}
</script>

<style scoped>

</style>