<template>
  <v-dialog
      v-model="isOpenDialog"
      width="700"
  >
    <v-card>
      <v-container>
        <v-card-text class="pb-0">
          <h3>Баланс организации: <span v-if="resolveAmount>0" style="color:#008000;">{{ resolveAmount }} </span>
            <span v-else style="color: darkred">{{ resolveAmount }}</span></h3><br>
          <div class="mb-5">Срок действия назначенных тестов 1 месяц</div>
          <v-form ref="assigmentTestForm">
            <v-autocomplete v-model="assignedTests"
                            dense
                            :readonly="listTests == null || listTests.length < 1"
                            :items="listTests"
                            label="Список тестов *"
                            return-object
                            item-text="name"
                            prepend-icon="mdi-clipboard-check-multiple-outline"
                            multiple
                            :rules="[rules.notEmptyList]">
              <template v-slot:item="{ item }">
                <v-layout align-center justify-start>
                  <tooltip-trial-test :size="30" v-if="item.weight === 0"/>
                  <tooltip-public-test v-if="item.isPublic"/>
                  {{ item.name }}
                </v-layout>
              </template>
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small dark @click="closeDialog">
            Отмена
          </v-btn>
          <v-btn
              :disabled="(resolveAmount <= 0 && assignedTests.length === 0) || (assignedTests.length > 0 && resolveAmount<0)"
              color="primary" small
              :dark="resolveAmount>0"
              class="btn"
              @click="assigmentTest"
              :loading="isLoading">
            Назначить
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapMutations} from 'vuex'
import ErrorInfo from "../../../../classes/util/ErrorInfo";
import TooltipPublicTest from "../../../common/tooltips/TooltipPublicTest";
import TooltipTrialTest from "../../../common/tooltips/TooltipTrialTest";

import assignedApi from "../../../../scripts/api/assigned-api";
import billingApi from "../../../../scripts/api/billing-api";
import rules from "../../../../scripts/rules";
import {getDateThatWillBeInMonth} from "../../../../utils/DataUtils";

export default {
  components: {TooltipTrialTest, TooltipPublicTest},
  name: "AssigmentTestForm",
  props: ['assignedUser'],
  computed: {
    resolveAmount() {
      let amountTests = 0
      this.assignedTests.forEach(test => amountTests += test.weight)
      return this.amount - amountTests
    }
  },
  data() {
    return {
      amount: 0,
      listTests: [],
      assignedTests: [],
      isOpenDialog: false,
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT', 'ACTIVATE_ALERT_BASED_ON_CODE', 'ACTIVATE_INFO_ALERT']),
    assigmentTest() {
      if (this.$refs.assigmentTestForm.validate()) {
        this.isLoading = true
        let tests = []
        this.assignedTests.forEach(assTest => tests.push({
          userId: this.assignedUser.id,
          testId: assTest.id,
          company: this.assignedUser.org,
          expiredDate: getDateThatWillBeInMonth()
        }))


        let nameTests = []
        this.assignedTests.forEach(test => nameTests += ' "' + test.name + '"')


        let request = assignedApi.assignTests(tests)
        request.call
            .then(() => {
              this.ACTIVATE_INFO_ALERT('Тесты ' + nameTests + ' успешно назначены!');
              this.$emit('successAssign');
            })
            .catch(er => this.ACTIVATE_ALERT_BASED_ON_CODE(new ErrorInfo(er.response.status,
                'Произошла ошибка при попытке назначить тесты: ' + nameTests + ' на пользователя: ' + this.assignedUser.login,
                request.id)))
            .finally(() => {
              this.closeDialog()
              this.isLoading = false
            })
      } else {
        this.ACTIVATE_ALERT("Вы не указали тест, выберите категорию теста, тест и после кпопробуйте снова.");
      }
    },
    openDialog(assignedUser, listTests) {
      billingApi.getBillingAccount(this.assignedUser.orgId).then(response => {
        this.amount = response.data.amount
        this.listTests = listTests
        this.assignedUser = assignedUser
        this.isOpenDialog = true
      })

    },
    closeDialog() {
      this.isOpenDialog = false
      this.assignedTests = []
      this.listTests = []
      this.$refs.assigmentTestForm.reset()
    },
  }
}
</script>

<style scoped>

</style>
