<template>
  <v-container
      class="fill-height"
      fluid
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          style="text-align: center"
          cols="12"
          xl="6"
          lg="6"
          sm="12"
          md="9"
      >
        <v-img contain max-height="80"
               src="/images/PsyScannerLogo.png"/>
        <v-progress-circular
            v-show="isLoading"
            indeterminate
            width="4"
            color="primary"
            size="35"/>
        <br>
        <v-layout align-center justify-center>
          <p class=" text-center">{{ message }}</p>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import billingApi from '../../scripts/api/billing-api'
import {mapState} from "vuex";

export default {
  name: "SuccessPaymentUrl",
  computed: {
    ...mapState(['currentUser'])
  },
  props: ['countTests'],
  data: () => {
    return {
      isShowBtn: false,
      attempts: 1,
      task: null,
      isLoading: false,
      message: 'Ждем подтверждения оплаты...'
    }
  },
  mounted() {
    let params = (new URL(document.location)).searchParams
    let orderId = params.get('orderId')

    if (orderId === undefined || orderId === null || orderId === '') {
      this.message = 'Номер заказа не указан, обратитесь в поддержку.'
      return
    }

    this.isLoading = true
    this.task = setInterval(() => {
      billingApi.checkAndCreatePayment(this.currentUser.orgId, {
        orderId: orderId,
        amount: this.countTests
      }).then(() => {
        this.isLoading = false
        this.attempts = 0;
        clearInterval(this.task)
        this.message = 'Оплата прошла успешно.'
        this.isShowBtn = true
      }).catch(() => {
        this.attempts = this.attempts - 1
        if (this.attempts === 0) {
          this.isLoading = false
          clearInterval(this.task)
          this.message = 'Оплата не прошла, обратитесь в сервис поддержки. Номер оплаты: ' + orderId
          this.isShowBtn = true
        }
      })
    }, 10000)
  },
}
</script>

<style scoped>

</style>