<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <h1 class="display-1 pb-0">Детализация счета "{{ org.name }}"</h1>
        <v-btn class="mt-5" v-if="role === 'admin'" small color="primary" @click="openCreatePaymentForm">
          <v-icon small class="pr-2">mdi-credit-card-outline</v-icon>
          Добавить оплату
        </v-btn>
        <v-btn v-if="role === 'individual'" class="mt-5" small color="primary" @click="openAcquiringIndividualForm">
          <v-icon small class="pr-2">mdi-credit-card-outline</v-icon>
          Пополнить счет
        </v-btn>
        <h3 class="mt-5">Баланс: <span style="color: #008000">{{ utils.viewAmount(amount) }}</span></h3>
        <v-tabs v-model="currentTab" @change="updateTabInfo" centered grow class="primary mt-5">
          <v-tab>
            <v-icon left>mdi-credit-card-settings-outline</v-icon>
            История
          </v-tab>
          <v-tab>
            <v-icon left>mdi-credit-card-settings-outline</v-icon>
            Состояние счетов
          </v-tab>
          <v-tab-item transition="scale-y-transition">
            <transactional-history-table ref="transactionHistoryTable" :orgId="orgId"/>
          </v-tab-item>
          <v-tab-item transition="scale-y-transition">
            <payments-table @increaseBalance="increaseBalance" ref="paymentsTable" :orgId="orgId"/>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-col>

    <create-payment-form ref="createPaymentForm" @increaseBalance="increaseBalance" :orgId="orgId"/>
    <acquring-individual-form v-if="role === 'individual'" ref="acquiringIndividualForm"
                               @increaseBalance="increaseBalance" :org-id="orgId"/>
  </v-row>
</template>

<script>
import billingApi from "../../scripts/api/billing-api";
import orgApi from "../../scripts/api/org-api";

import utils from "../../scripts/utils";

import CreatePaymentForm from "./forms/CreatePaymentForm";
import PaymentsTable from "./tables/PaymentsTable";
import TransactionalHistoryTable from "./tables/TransactionalHistoryTable";
import AcquringIndividualForm from "../acquiring/forms/AcquiringIndividualForm";
import {mapState} from "vuex";

export default {
  name: "BillingPage",
  components: {PaymentsTable, TransactionalHistoryTable, CreatePaymentForm, AcquringIndividualForm},
  computed: {
    ...mapState(['role'])
  },
  props: ['orgId'],
  data() {
    return {
      utils: utils,
      amount: '',
      org: {},
      currentTab: 0
    }
  },
  methods: {
    getBillingAccount() {
      billingApi.getBillingAccount(this.orgId).then(response => this.amount = response.data.amount)
    },
    updateBillingTransactionsHistory() {
      let transactionHistoryTable = this.$refs.transactionHistoryTable
      if (transactionHistoryTable != null) {
        transactionHistoryTable.initGetTransactionHistory()
      }
    },
    updatePayments() {
      let paymentsTable = this.$refs.paymentsTable
      if (paymentsTable != null) {
        paymentsTable.getPayments()
      }
    },
    getOrg() {
      orgApi.getOrgById(this.orgId).then(response => this.org = response.data)
    },
    updateTabInfo(currentTab) {
      switch (currentTab) {
        case 0: {
          this.updateBillingTransactionsHistory()
          break;
        }
        case 1: {
          this.updatePayments()
          break;
        }
      }
    },
    openCreatePaymentForm() {
      this.$refs.createPaymentForm.openDialog()
    },
    increaseBalance(amount) {
      this.amount += amount
      this.updateTabInfo(this.currentTab)
    },
    openAcquiringIndividualForm() {
      this.$refs.acquiringIndividualForm.openDialog()
    },
  },
  watch: {
    $route(to) {
      if (to.path === '/manager/billing/' + this.orgId) {
        this.getBillingAccount()
        if (this.currentTab === 0) {
          this.updateBillingTransactionsHistory()
        } else {
          this.updatePayments()
        }
        this.getOrg()
      }
    }
  },
  mounted() {
    this.getBillingAccount()
    this.updateBillingTransactionsHistory()
    this.updatePayments()
    this.getOrg()
  }
}
</script>
<style scoped>

</style>