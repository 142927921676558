<template>
  <div>
    <v-dialog :retain-focus="false" persistent max-width="800" v-model="isOpen">
      <v-card>
        <v-container>
          <v-card-title>
            Вы действительно хотите отменить оплату в размере {{ payment.amount }}, действующую до
            {{ payment.expiredDate }}?
          </v-card-title>
          <v-card-text>
            <v-form ref="addingForm">
              <v-textarea
                  rows="1"
                  auto-grow
                  clearable
                  v-model="comment"
                  :rules="[rules.isRequired, rules.isStringShort]"
                  prepend-icon="mdi-comment-edit-outline"
                  label="Комментарий"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" small dark class="btn" @click="confirm" :loading="isLoading">Да</v-btn>
            <v-btn color="primary" small dark @click="closeDialog">Нет</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {mapMutations, mapState} from "vuex";

import billingApi from '../../../scripts/api/billing-api'
import rules from "../../../scripts/rules";

export default {
  name: "ConfirmationDialog",
  computed: {
    ...mapState('confirmationModule', ['action', 'actionDescription', 'isActiveConfirmationDialog'])
  },
  props: ['orgId'],
  data: () => {
    return {
      payment: {},
      comment: '',
      rules: rules,
      isOpen: false,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('confirmationModule', ['closeConfirmationDialog']),
    openDialog(payment) {
      this.payment = payment
      this.isOpen = true
    },
    confirm() {
      if (this.$refs.addingForm.validate()) {
        this.isLoading = true
        billingApi.cancelPayment(this.orgId, this.payment.id, this.comment)
            .then(response => {
              this.$emit('successCancel', response.data)
              this.$emit('increaseBalance', -this.payment.amount)
            })
            .finally(() => {
              this.isLoading = false
              this.closeDialog()
            })
      }
    },
    closeDialog() {
      this.payment = {}
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>
