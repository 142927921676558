<template>
  <div>
    <v-dialog persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-title>
            Заполните информацию о новом сотруднике организации:
          </v-card-title>
          <v-card-text>
            <v-form ref="addingForm">
              <v-text-field label="Логин  *" v-model="login"
                            :rules="[rules.isRequired, rules.isLength128]"/>
              <v-text-field label="Имя *" v-model="firstName"
                            :rules="[rules.isRequired, rules.isStringShort]"/>
              <v-text-field label="Фамилия *" v-model="lastName"
                            :rules="[rules.isRequired, rules.isStringShort]"/>
              <v-text-field label="Адрес электронной почты *" type="" v-model="email"
                            :rules="[rules.isRequired, rules.isSuccessEmail, rules.isLength128]"/>
              <v-text-field label="Телефон *" v-model="phone"
                            :rules="[rules.isRequired, rules.isSuccessPhoneNumber]"/>
              <date-picker label="Дата рождения *" :max="new Date().toISOString().substr(0, 10)"
                           @saveDate="saveDateBirthday"/>
              <v-select label="Роль сотрудника *" single :items="ROLES" v-model="userRole"
                        item-text="textValue" item-value="rawValue" :rules="[rules.isRequired]"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" small @click="closeDialog">Отменить</v-btn>
            <v-btn color="primary" small class="btn" @click="addEmployee" :loading="isLoading">Добавить</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'

import UserRole from "../../../classes/util/UserRole";
import UserDTO from "../../../classes/dto/UserDTO";
import usersApi from "../../../scripts/api/users-api";
import rules from "../../../scripts/rules";

import DatePicker from "../../common/DatePicker";

export default {
  components: {
    DatePicker
  },
  name: "OrgAdminAddForm",
  props: ["orgId"],
  data() {
    return {
      isOpenDialog: false,
      birthday: null,
      login: '',
      firstName: '',
      lastName: '',
      name: '',
      phone: '',
      email: '',
      userRole: null,
      ROLES: [new UserRole('MANAGER'), new UserRole('SUPER_MANAGER')],
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapActions('users', ['ADD_USER_BY_MANAGER']),
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT']),
    addEmployee() {
      if (this.$refs.addingForm.validate()) {
        this.isLoading = true
        usersApi.addUser(new UserDTO(null, this.login, this.firstName, this.lastName, this.birthday,
            this.email, this.phone, [this.userRole], this.orgId)).then(response => {
          this.$emit('successAdded', response.data)
          this.$refs.addingForm.reset();
          this.closeDialog();
        }).finally(() => this.isLoading = false)
      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для добавления нового сотрудника в организацию " +
            "проверьте данные и попробуйте снова.");
      }
    },
    saveDateBirthday(date) {
      this.birthday = date
    },
    openDialog() {
      this.isOpenDialog = true;
    },
    closeDialog() {
      this.isOpenDialog = false;
      this.$refs.addingForm.reset();
    }
  }
}
</script>

<style scoped>

</style>