<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <v-row>
          <v-col class="pb-0" xl="12" lg="12" md="12" sm="12">
            <h1 class="display-1">Учетная запись пользователя
              <tooltip-user-deleted v-if="utils.isDeletedEntity(user)" :location="'bottom'" :size="'large'"/>
            </h1>
          </v-col>
          <v-col xl="12" lg="12" md="12" sm="12">
            <v-btn
                v-if="(role === 'sup_manager' || role === 'admin' || (role === 'manager' && roleCurrentUser === 'testable')) &&
                !utils.isDeletedEntity(user)"
                small color="primary" @click="openUserUpdateForm" class="mr-5">
              <v-icon class="pr-2">mdi-pencil-outline</v-icon>
              Редактировать
            </v-btn>
            <v-btn
                v-if="role === 'admin' &&  user.state !== 'DELETED'"
                small color="primary" @click="resetPassword" class="mr-5">
              <v-icon class="pr-2">mdi-lock-reset</v-icon>
              Сбросить пароль
            </v-btn>
            <v-tooltip v-if="user.roles.includes(testableRole) && !utils.isDeletedEntity(user)"
                       :disabled="countAvailableTests > 0" bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on" class="d-inline-block">
                  <v-btn class="mr-10" :disabled="countAvailableTests === 0" color="primary" small
                         @click="openAssigmentTestForm">
                    <v-icon class="pr-3">mdi-file-check-outline</v-icon>
                    Назначить тест
                  </v-btn>
                </div>
              </template>
              <span>Нету доступных тестов</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4" md="4" sm="4">
            <v-img eager

                   ref="mainAccountImg"
                   class="white--text align-end"
                   height="250"
                   src="/images/instrument.jpg"/>
          </v-col>
          <v-col lg="8" md="8" sm="8">
            <v-row>
              <v-col lg="6" md="6" sm="6">
                <b>Имя: </b>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                {{ user.firstName }}
              </v-col>


              <v-col lg="6" md="6" sm="6">
                <b>Фамилия:</b>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                {{ user.lastName }}
              </v-col>


              <v-col lg="6" md="6" sm="6">
                <b>Дата рождения: </b>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                {{ utils.preparedViewDate(user.birthday, 'date') }}
              </v-col>


              <v-col lg="6" md="6" sm="6">
                <b>Адрес электронной почты: </b>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                <email-user-info v-if="isAvailableSendConfirmMessage && !utils.isDeletedEntity(user)" :user="user"/>
                <div v-else>{{ user.email }}</div>
              </v-col>

              <v-col lg="6" md="6" sm="6">
                <b>Номер телефона:</b>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                {{ user.phoneNumber }}
              </v-col>

              <v-col v-if="role !=='individual'" lg="6" md="6" sm="6">
                <b>Организация</b>
              </v-col>
              <v-col v-if="role !=='individual' && role !=='testable'" lg="6" md="6" sm="6">
                <router-link style="font-size: .9em"
                             :class="utils.isDeletedEntity(user.org)? 'mr-2 deletedColor':'mr-2 successColor'"
                             :to="{name:'organizationsInfo', params: { orgId: orgId, role: role }}">
                  <b> "{{ orgName }}"</b>
                </router-link>
                <tooltip-org-deleted v-if="utils.isDeletedEntity(user.org)" :location="'bottom'"/>
              </v-col>
              <v-col v-else-if="role !=='individual'" lg="6" md="6" sm="6">
                <b>"{{ orgName }}"</b>
                <tooltip-org-deleted :location="'bottom'"/>
              </v-col>


              <v-col v-if="role !== 'testable &&' && role!== 'manager' && role!=='individual'" lg="6" md="6" sm="6">
                <b>Роль:</b>
              </v-col>
              <v-col v-if="role !== 'testable' && role!== 'manager' && role!=='individual'" lg="6" md="6" sm="6">
                {{ translateRoles(user.roles) }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-tabs v-if="user.roles.includes(testableRole)" centered grow v-model="currentTab" class="primary mt-5">
          <v-tab>
            <v-icon left>mdi-file-chart</v-icon>
            Статус тестов
          </v-tab>
          <v-tab-item transition="scale-y-transition">
            <v-card flat>
              <v-card-text>
                <assigned-test-table ref="assignedTestTable" :userId="userId"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-col>
    <assigment-test-form-for-admin v-if="role==='admin'" @successAssign="updateAssignedTestTable"
                                   ref="assigmentTestForm" :assignedUser="user"/>
    <assigment-test-form v-else ref="assigmentTestForm" :assignedUser="user" @successAssign="updateAssignedTestTable"/>
    <user-update-form ref="userUpdateForm"/>
  </v-row>
</template>
<script>
import {mapMutations, mapState} from 'vuex'

import utils from "../../scripts/utils"

import UserDTO from "../../classes/dto/UserDTO";
import UserUpdateForm from "./forms/UserUpdateForm.vue";
import AssignedTestTable from "../tests/assigned/tables/AssignedTestTable.vue";
import AssigmentTestForm from "../tests/assigned/forms/AssigmentTestForm.vue";
import AssigmentTestFormForAdmin from "../tests/assigned/forms/admin/AssigmentTestFormForAdmin";
import EmailUserInfo from "../user/EmailUserInfo.vue";
import {translateRoles} from "../../utils/DataUtils";
import UserRoleEnum from "../../classes/enums/UserRoleEnum";
import orgApi from "../../scripts/api/org-api";
import {get, post} from "../../httpclient/http-client";
import ErrorInfo from "../../classes/util/ErrorInfo";
import TooltipUserDeleted from "../common/tooltips/TooltipUserDeleted.vue";
import TooltipOrgDeleted from "../common/tooltips/TooltipOrgDeleted";

const USER_STUB = new UserDTO(null, null, null, null, null, null,
    null, [], {});

export default {
  name: "UserInfoPage",
  components: {
    AssigmentTestForm,
    AssignedTestTable,
    UserUpdateForm,
    AssigmentTestFormForAdmin,
    EmailUserInfo,
    TooltipUserDeleted,
    TooltipOrgDeleted
  },
  props: ['userId'],
  mounted() {
    let request = get('/api/manager/users/' + this.userId)
    request.call
        .then(response => {
          this.user = response.data;
          if (this.user.roles.includes(this.testableRole))
            orgApi.getTests(response.data.orgId).then(response => this.listTests = response.data.content)
        })
        .catch(er => {
          this.ACTIVATE_ALERT_BASED_ON_CODE(
              new ErrorInfo(er.response.status,
                  "Произошла ошибка при попытке выгрузить информацию о пользователе.",
                  request.id))
          this.$router.back();
        })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.updateAssignedTestTable()
      if (vm.user.orgId !== null && vm.user.roles.includes(vm.testableRole)) {
        orgApi.getTests(vm.user.orgId).then(response => vm.listTests = response.data.content)
      }
    })
  },
  computed: {
    ...mapState(['role', 'currentUser']),
    orgId() {
      if (this.user === undefined || this.user.org === undefined) {
        return ''
      }
      return this.user.org.id
    },
    orgName() {
      if (this.user === undefined || this.user.org === undefined) {
        return ''
      }
      return this.user.org.name
    },
    countAvailableTests() {
      if (this.listTests === null || this.listTests === undefined) {
        return 0
      }
      return this.listTests.length
    },
    isAvailableSendConfirmMessage() {
      if (this.user.roles.length === 0) {
        return false
      }
      let localRole = ''
      if (this.user.roles.includes(UserRoleEnum.ADMINISTRATOR)) {
        localRole = "admin"
        return this.role === localRole;
      } else if (this.user.roles.includes(UserRoleEnum.SUPER_MANAGER)) {
        localRole = "sup_manager"
        return this.role === localRole || this.role === 'admin';
      } else if (this.user.roles.includes(UserRoleEnum.MANAGER)) {
        return this.role === localRole || this.role === 'admin' || this.role === 'sup_manager';
      } else {
        return true
      }
    },
    roleCurrentUser() {
      let roles = this.user.roles;
      if (roles.includes(UserRoleEnum.ADMINISTRATOR)) {
        return "admin"
      } else if (roles.includes(UserRoleEnum.SUPER_MANAGER)) {
        return "sup_manager"
      } else if (roles.includes(UserRoleEnum.MANAGER)) {
        return 'manager'
      } else {
        return "testable"
      }
    }
  },
  data() {
    return {
      utils: utils,
      listTests: [],
      isDisabled: true,
      currentTab: '',
      user: USER_STUB,
      testableRole: UserRoleEnum.TESTABLE,
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT_BASED_ON_CODE', 'ACTIVATE_INFO_ALERT']),
    openUserUpdateForm() {
      this.$refs.userUpdateForm.openDialog(this.user);
    },
    openAssigmentTestForm() {
      orgApi.getTests(this.user.orgId).then(response => {
        this.listTests = response.data.content
        if (this.role === 'admin') {
          this.$refs.assigmentTestForm.openDialog(this.user, this.listTests)
        } else {
          this.$refs.assigmentTestForm.openDialog(this.user, this.listTests)
        }
      })
    },
    updateAssignedTestTable() {
      if (this.$refs.assignedTestTable != null) {
        this.$refs.assignedTestTable.initRequestAssignedTests()
      }
    },
    translateRoles(roles) {
      return translateRoles(roles)
    },
    resetPassword() {
      let request = post('/api/manager/users/' + this.user.id + '/send/reset/password', {});
      request.call
          .then(() => this.ACTIVATE_INFO_ALERT('Письмо с ссылкой сброса пароля успешно отправлено пользователю ' + this.user.login))
          .catch(er =>
              this.ACTIVATE_ALERT_BASED_ON_CODE(new ErrorInfo(er.response.status,
                  'Произошла ошибка при попытке отправить письмо с ссылкой сброса пароля пользователю ' + this.user.login,
                  request.id))
          )
    }
  }
}
</script>

<style scoped>
>>> .scale-y-transition {
  transition: all 2s ease;
}

</style>
