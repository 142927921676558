<template>
  <div>
    <v-dialog persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-title>
            Заполните информацию о новом пользователе:
          </v-card-title>
          <v-card-text>
            <v-form ref="addingForm">
              <v-text-field label="Логин" v-model="login"
                            :rules="[rules.isRequired, rules.isLength128]"/>
              <v-text-field label="Имя *" v-model="firstName"
                            :rules="[rules.isRequired, rules.isStringShort]"/>
              <v-text-field label="Фамилия *" v-model="lastName"
                            :rules="[rules.isRequired, rules.isStringShort]"/>
              <v-text-field label="Адрес электронной почты *" type="" v-model="email"
                            :rules="[rules.isSuccessEmail, rules.isLength128]"/>
              <v-text-field label="Телефон *" v-model="phone"
                            :rules="[rules.isRequired, rules.isSuccessPhoneNumber]"/>
              <v-select label="Организация *" :items="orgs" :rules="[rules.isRequired]" v-model="orgId"
                        item-text="name" item-value="id"/>
              <v-select label="Роль пользователя *" multiple :items="ROLES" v-model="userRoles"
                        :rules="[rules.notEmptyList]"
                        item-text="textValue" item-value="rawValue"/>
              <date-picker label="Дата рождения *" :max="new Date().toISOString().substr(0, 10)"
                           @saveDate="save"/>
              <!--                            <v-file-input show-size counter v-model="avatarFile" label="Аватар профиля"/>-->
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" small dark @click="closeDialog">Отменить</v-btn>
            <v-btn color="primary" small dark class="btn" @click="addUser" :loading="isLoading">Добавить</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import UserDTO from "../../../classes/dto/UserDTO";
import UserRole from "../../../classes/util/UserRole";

import orgApi from "../../../scripts/api/org-api";
import usersApi from "../../../scripts/api/users-api"

import DatePicker from "../../common/DatePicker";
import rules from "../../../scripts/rules";

export default {
  components: {
    DatePicker
  },
  name: "UserCreateForm",
  data() {
    return {
      orgs: [],
      activePicker: null,
      menu: false,
      isOpenDialog: false,
      login: '',
      lastName: '',
      firstName: '',
      email: '',
      birthday: '',
      phone: '',
      orgId: '',
      avatarFile: null,
      ROLES: [new UserRole('TESTABLE'),
        new UserRole('MANAGER'),
        new UserRole('SUPER_MANAGER'),
        new UserRole('ADMINISTRATOR')],
      userRoles: [],
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT']),
    openDialog() {
      this.requestOrgs()
      this.isOpenDialog = true;
    },
    addUser() {
      if (this.$refs.addingForm.validate()) {
        this.isLoading = true
        usersApi.addUser(new UserDTO(null, this.login, this.firstName, this.lastName, this.birthday,
            this.email, this.phone, this.userRoles, this.orgId)).then(response => {
          this.$emit('successAdded', response.data)
          this.closeDialog()
          this.$refs.addingForm.reset();
        }).finally(() => this.isLoading = false)
      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для добавления нового пользователя в систему, " +
            "проверьте данные и попробуйте снова.");
      }
    },
    closeDialog() {
      this.isOpenDialog = false;
    },
    save(date) {
      this.birthday = date
    },
    requestOrgs() {
      orgApi.getOrgs().then(response => this.orgs = response.data.content)
    }
  }
}
</script>

<style scoped>
</style>
