export default class TopicDTO {
    constructor(id, name, stimulus, highRiskLevel, midRiskLevel, lowRiskLevel) {
        this.id = id;
        this.name = name;
        this.stimulus = stimulus;
        this.highRiskLevel = highRiskLevel;
        this.midRiskLevel = midRiskLevel;
        this.lowRiskLevel = lowRiskLevel;
    }
}
