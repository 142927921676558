<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <v-row>
          <v-col class="pb-0" xl="12" lg="12" md="12" sm="12">
            <v-layout align-center justify-start>
              <h1 class="display-1 mr-5">Настройки экварийнга</h1>
            </v-layout>

          </v-col>
        </v-row>

        <br>
        <v-layout justify-start>
          <p class="pt-1 pr-2">Стоимость базового теста <span style="color: green"><b>{{ priceBaseTest }}</b></span></p>
          <v-btn color="primary" icon @click.stop="openChangePriceBaseTestDialog">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </v-layout>
      </v-container>
    </v-col>

    <v-dialog
        persistent
        v-model="isOpenChangeDialog"
        max-width="400"
    >
      <v-card>
        <v-container>
          <v-card-text class="pb-0">
            <v-form ref="updatePriceForm">
              <v-text-field v-model="changedPriceBaseTest"
                            :rules="[rules.isRequired]"
                            label="Стоимость базового теста"
                            append-icon="mdi-pencil-outline"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" small dark @click="isOpenChangeDialog = !isOpenChangeDialog">
              Отмена
            </v-btn>
            <v-btn color="primary" small dark class="btn" @click="changePriceBaseTest">
              Изменить
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import acquiringApi from '../../scripts/api/acquiring-api'
import rules from "../../scripts/rules";
import {mapMutations} from "vuex";

export default {
  name: "AcquiringSettingsPage",
  data: () => {
    return {
      priceBaseTest: undefined,
      changedPriceBaseTest: undefined,
      isOpenChangeDialog: false,
      rules: rules
    }
  },
  mounted() {
    acquiringApi.getPriceBaseTest().then(response => {
      this.priceBaseTest = response.data.value
    })
  },
  methods: {
    ...mapMutations('confirmationModule', ['openConfirmationDialog']),
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT']),
    openChangePriceBaseTestDialog() {
      this.changedPriceBaseTest = this.priceBaseTest
      this.isOpenChangeDialog = true
    },
    changePriceBaseTest() {
      if (this.$refs.updatePriceForm.validate()) {
        this.openConfirmationDialog({
          description: ' изменить цену базового теста с ' + this.priceBaseTest + ' на ' + this.changedPriceBaseTest + '?',
          action: () => {
            acquiringApi.updatePriceBaseTest({value: this.changedPriceBaseTest}).then(() => {
              this.priceBaseTest = this.changedPriceBaseTest
              this.isOpenChangeDialog = false
            })
          }
        })
      } else {
        this.ACTIVATE_ALERT("Вы указали неверную стоимость, попробуйте снова.")
      }
    }
  }
}
</script>

<style scoped>

</style>