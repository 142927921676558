import {get, post, put, remove} from "../../httpclient/http-client";
import store from "../../store";
import ErrorInfo from "../../classes/util/ErrorInfo";

//todo Сделать пагинацию
const defaultParams = {
    params: {
        limit: 0x7fffffff
    }
}

export default {
    getTestById(id) {
        let request = get('/api/manager/tests/' + id);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при выгрузке теста.',
                    request.id)))
    },
    getTests(search, limit, offset) {
        let params = {}
        params.limit = limit ?? 0x7fffffff
        params.offset = offset ?? 0
        if (search !== null && search !== undefined && search.length > 0) {
            params.search = search
        }
        let request = get('/api/manager/tests/', {params: params});
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при выгрузке тестов.',
                    request.id)))
    },
    getResultTests(test) {
        let request = get('/api/manager/tests/' + test.id + '/users', defaultParams);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить список пользователей которые прошли тест: "' + test.name + '"',
                    request.id)))
    },
    addTest(test) {
        let request = post('/api/manager/tests', test);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    "Произошла ошибка при попытке добавления нового теста.",
                    request.id)))
    },
    updateTest(test) {
        let request = put('/api/manager/tests', test);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке обновить тест "' + test.name + '"',
                    request.id)))
    },
    removeTest(test) {
        let request = remove('/api/manager/tests/' + test.id);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке удалить тест ' + test.name + '"',
                    request.id)))
    }
}