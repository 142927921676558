<template>
  <v-list class="pa-0 ma-0">
    <v-list-item-group v-model="activeListItem" color="primary">
      <router-link
          style="text-decoration: none"
          :to="'/organization/' + currentUser.orgId"
      >
        <v-list-item
            v-if="role==='manager' || role==='sup_manager'"
            :class="currentRoutePath.includes('/organization/') && currentRoutePath.length >= '/organization/'.length  ?
           'v-item--active v-list-item--active' : '' ">
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Моя организация</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>


      <router-link
          style="text-decoration: none"
          :to="'/individual/account/'+currentUser.orgId"
      >
        <v-list-item
            v-if="role==='individual'"
            :class="currentRoutePath.includes('/individual/account/') && currentRoutePath.length >= '/individual/account/'.length  ?
           'v-item--active v-list-item--active' : ''">
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Мой кабинет</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <v-divider v-if="role==='manager' || role==='sup_manager' || role==='individual'"/>
      <router-link
          style="text-decoration: none"
          to="/users"
      >
        <v-list-item
            :class="currentRoutePath.includes('users') ? 'v-item--active v-list-item--active' : '' "
            v-if="role==='admin'">
          <v-list-item-icon>
            <v-icon>mdi-account-box-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Пользователи системы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>


      <v-divider v-if="role==='admin'"/>
      <router-link
          style="text-decoration: none"
          to="/admin/organizations"
      >
        <v-list-item
            :class="currentRoutePath.includes('admin/organizations') ? 'v-item--active v-list-item--active' : '' "
            v-if="role==='admin'">
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Организации</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>


      <v-divider v-if="role==='admin'"/>
      <router-link
          style="text-decoration: none"
          to="/admin/topics"
      >
        <v-list-item :class="currentRoutePath.includes('/admin/topics') ? 'v-item--active v-list-item--active' : '' "
                     v-if="role==='admin'">
          <v-list-item-icon>
            <v-icon>mdi-text-shadow</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Темы тестов</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>


      <v-divider v-if="role==='admin'"/>
      <router-link
          style="text-decoration: none"
          to="/admin/tests"
      >
        <v-list-item :class="currentRoutePath.includes('/admin/tests') ? 'v-item--active v-list-item--active' : '' "
                     v-if="role==='admin'">
          <v-list-item-icon>
            <v-icon>mdi-file-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Тесты</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>


      <v-divider v-if="role==='admin'"/>
      <router-link
          style="text-decoration: none"
          :to="'/manager/billing/'+currentUser.orgId"
      >
        <v-list-item
            v-if="role!=='admin'"
            :class="currentRoutePath.includes('/manager/billing')  ?
           'v-item--active v-list-item--active' : '' "
        >
          <v-list-item-icon>
            <v-icon>mdi-credit-card-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Биллинг</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>


      <v-divider v-if="role!=='admin'"/>
      <router-link
          style="text-decoration: none"
          :to='"/manager/assigned-tests/"+currentUser.orgId'
      >
        <v-list-item
            v-if="role!=='admin'"
            :class="currentRoutePath.includes('/manager/assigned')  ?
           'v-item--active v-list-item--active' : '' "
        >
          <v-list-item-icon>
            <v-icon>mdi-clipboard-check-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Назначенные тесты</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <v-divider v-if="role!=='admin'"/>
      <router-link
          style="text-decoration: none"
          :to='"/admin/acquiring-settings"'
      >
        <v-list-item
            v-if="role==='admin'"
            :class="currentRoutePath.includes('/admin/acquiring-settings')  ?
           'v-item--active v-list-item--active' : '' "
        >
          <v-list-item-icon>
            <v-icon>mdi-account-cash-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Настройки эквайринга</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <v-divider v-if="role==='admin'"/>
    </v-list-item-group>
  </v-list>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "AppMenu",
  computed: {
    ...mapState(['role', 'currentUser'])
  },
  data() {
    return {
      activeListItem: '',
      countAssigmentTests: 0,
      currentRoutePath: this.$router.currentRoute.path
    }
  },
  watch: {
    $route(to) {
      this.activeListItem = ''
      this.currentRoutePath = to.path;
    }
  }
}
</script>

<style scoped>

</style>
