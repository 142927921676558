<template>
  <v-tooltip bottom color="primary">
    <template v-slot:activator="{ on }">
      <v-img contain v-on="on" :max-height="size" :max-width="size" :src="require('@/assets/images/free-icon.png')"/>
    </template>
    <span class="white--text">Пробный тест</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "TooltipTrialTest",
  props: ['size']
}
</script>

<style scoped>
.v-image {
  display: inline-block;
}
</style>