function resolveAssignedTestStatus(status) {
    switch (status) {
        case 'ASSIGNED':
            return 'Назначен'
        case 'TIMEOUT':
            return 'Вышел срок ожидания прохождения'
        case 'CANCELED':
            return 'Отменен'
        case 'PASSED':
            return 'Пройден'
        case 'ANALYSED':
            return 'Проанализирован'
    }
}

function preparedViewDate(data, type) {
    if (data === null || data === undefined) {
        return ''
    }
    let dateObject = new Date(data)
    let strDate

    let rawMonth = dateObject.getMonth() + 1
    let resultViewMonth = rawMonth < 10 ? '0' + rawMonth : rawMonth
    let rawDate = dateObject.getDate()
    let resultViewDate = rawDate < 10 ? '0' + rawDate : rawDate
    switch (type) {
        case 'date': {
            strDate = resultViewDate + '.' + resultViewMonth + '.' + dateObject.getFullYear()
            break
        }
        case 'datetime': {

            let rawHours = dateObject.getHours()
            let resultViewHours = rawHours < 10 ? '0' + rawHours : rawHours
            let rawMinutes = dateObject.getMinutes()
            let resultViewMinutes = rawMinutes < 10 ? '0' + rawMinutes : rawMinutes
            strDate = resultViewDate + '.' + resultViewMonth + '.' + dateObject.getFullYear() + ' ' +
                resultViewHours + ':' + resultViewMinutes
            break
        }
        default: {
            strDate = ''
            break
        }
    }

    return strDate
}

function viewAmount(amount) {
    return amount.toLocaleString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
}

function isDeletedEntity(obj) {
    return obj !== undefined && obj.state === "DELETED"
}

export default {viewAmount, resolveAssignedTestStatus, preparedViewDate, isDeletedEntity}