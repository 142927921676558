<template>
  <v-card>
    <v-card-title>
      <v-icon class="pr-2">mdi-cash-multiple</v-icon>
      <span style="font-size: 1.1em">{{ role === "individual" ? 'Личный счет' : 'Счет организации' }}</span>
    </v-card-title>
    <v-card-text><h3>Баланс: <span style="color: #008000"> {{ utils.viewAmount(amount) }}</span></h3></v-card-text>
    <v-card-actions class="pl-0 ml-0">
      <v-layout justify-space-around>
        <v-btn v-if="role === 'individual'" x-small color="primary" @click="openAcquiringIndividualForm">
          <v-icon small class="pr-2">mdi-credit-card-outline</v-icon>
          Пополнить счет
        </v-btn>
        <v-btn :to="'/manager/billing/'+orgId" x-small color="primary">
          <v-icon small class="pr-2">mdi-information-variant</v-icon>
          Детальная информация
        </v-btn>
        <v-btn v-if="role === 'admin'" x-small color="primary" @click="openCreatePaymentForm">
          <v-icon small class="pr-2">mdi-credit-card-outline</v-icon>
          Добавить оплату
        </v-btn>
      </v-layout>
    </v-card-actions>

    <create-payment-form v-if="role === 'admin'" ref="createPaymentForm" @increaseBalance="increaseBalance"
                         :orgId="orgId"/>
    <acquiring-individual-form v-if="role === 'individual'" ref="acquiringIndividualForm"
                               @increaseBalance="increaseBalance" :org-id="orgId"/>
  </v-card>
</template>

<script>
import billingApi from '../../scripts/api/billing-api'
import utils from "../../scripts/utils";

import CreatePaymentForm from "./forms/CreatePaymentForm";
import AcquiringIndividualForm from "../acquiring/forms/AcquiringIndividualForm";
import {mapState} from "vuex";

export default {
  name: "BillingAccountCard",
  components: {
    CreatePaymentForm,
    AcquiringIndividualForm
  },
  computed: {
    ...mapState(['role']),
  },
  props: ['orgId'],
  data: () => {
    return {
      amount: '',
      utils: utils
    }
  },
  methods: {
    getBillingAccount() {
      return billingApi.getBillingAccount(this.orgId).then(response => this.amount = response.data.amount)
    },
    openCreatePaymentForm() {
      this.$refs.createPaymentForm.openDialog()
    },
    openAcquiringIndividualForm() {
      this.$refs.acquiringIndividualForm.openDialog()
    },
    increaseBalance(amount) {
      this.amount += amount
    }
  }
}
</script>

<style scoped>

</style>