import UserRoleDTO from "../classes/util/UserRole";

function updateItemInListById(list, item) {
    const needIndex = list.findIndex(s => s.id === item.id);
    list = [
        ...list.slice(0, needIndex),
        item,
        ...list.slice(needIndex + 1,)
    ];
    return list;
}

function removeItemFromListById(list, item) {
    const needIndex = list.findIndex(s => s.id === item.id);
    list = [
        ...list.slice(0, needIndex),
        ...list.slice(needIndex + 1)
    ];
    return list;
}

function translateRoles(roles) {
    return roles.map(role => new UserRoleDTO(role).textValue).join(", ")
}

function getDateThatWillBeInMonth() {
    let lastDayIsActive = new Date()
    lastDayIsActive.setMonth(lastDayIsActive.getMonth() + 1)
    return lastDayIsActive
}

export {updateItemInListById, removeItemFromListById, translateRoles, getDateThatWillBeInMonth}