<template>
  <v-dialog persistent max-width="800" v-model="isOpenDialog">
    <v-card>
      <v-container>
        <v-card-title>
          Заполните информацию о новой организации:
        </v-card-title>
        <v-card-text>
          <v-form ref="addingForm">
            <v-text-field label="Наименование *" v-model="name"
                          :rules="[rules.isRequired]"/>
            <v-text-field label="Телефон *" v-model="phone"
                          :rules="[rules.isRequired, rules.isSuccessPhoneNumber]"/>
            <v-text-field label="Адрес электронной почты *:" v-model="email"
                          :rules="[rules.isRequired, rules.isSuccessEmail]"/>
            <!--            <v-text-field label="Юридический адрес:" v-model="legalAddress"-->
            <!--                          :rules="[rules.notEmptyRul]"/>-->
            <!--            <v-text-field label="ИНН:" v-model="taxpayerNumber"/>-->
            <!--            <v-text-field label="КПП:" v-model="kpp"/>-->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" small @click="closeDialog">Отменить</v-btn>
          <v-btn color="primary" small class="btn" @click="addOrg" :loading="isLoading">Добавить</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import OrganizationDTO from "../../../classes/dto/OrganizationDTO";
import rules from "../../../scripts/rules";
import orgApi from '../../../scripts/api/org-api'
import {mapMutations} from "vuex";

export default {
  name: "OrgAddForm",
  data() {
    return {
      isOpenDialog: false,
      name: '',
      phone: '',
      email: '',
      // legalAddress: '',
      // taxpayerNumber: '',
      // kpp: '',
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT']),
    addOrg() {
      if (this.$refs.addingForm.validate()) {
        this.isLoading = true
        let newOrg = new OrganizationDTO(null, this.name, this.email, this.phone, null,
            null, null);
        orgApi.addOrg(newOrg).then(response => {
          this.$emit('successCreate', response.data)
          this.closeDialog();
        }).finally(() => this.isLoading = false)
      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для добавления новой организации в систему, " +
            "проверьте данные и попробуйте снова.");
      }
    },
    openDialog() {
      this.isOpenDialog = true
    },
    closeDialog() {
      this.isOpenDialog = false
      this.$refs.addingForm.reset()
    }
  }
  ,
  mounted() {
    window.addEventListener('keydown', event => {
      if (event.key === 'Escape') {
        this.closeDialog();
      }
    });
  }
}
</script>

<style scoped>

</style>
