<template>
  <div>
    <v-dialog persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-title>
            Заполните информацию о новом тестируемом:
          </v-card-title>
          <v-card-text>
            <v-form ref="addingForm">
              <v-text-field label="Имя *" v-model="firstName" :rules="[rules.isRequired]"/>
              <v-text-field label="Фамилия *" v-model="lastName" :rules="[rules.isRequired]"/>
              <v-text-field label="Адрес электронной почты" type="" v-model="email" :rules="[rules.isSuccessEmail]"/>
              <v-text-field label="Телефон *" v-model="phone" :rules="[rules.isRequired, rules.isSuccessPhoneNumber]"/>
              <date-picker label="Дата рождения *" :max="new Date().toISOString().substr(0, 10)"
                           @saveDate="saveDateBirthday"/>
            </v-form>
            <v-select label="Роль пользователя *" readonly multiple disabled :items="roles" v-model="userRoles"
                      item-text="textValue" item-value="rawValue"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" small @click="closeDialog">Отменить</v-btn>
            <v-btn color="primary" small class="btn" @click="addTestable" :loading="isLoading">Добавить</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import usersApi from "../../../scripts/api/users-api"

import UserRole from "../../../classes/util/UserRole";
import UserDTO from "../../../classes/dto/UserDTO";
import UserRoleEnum from "../../../classes/enums/UserRoleEnum";
import rules from "../../../scripts/rules";

import DatePicker from "../../common/DatePicker";

export default {
  components: {
    DatePicker
  },
  name: "OrgAdminAddForm",
  props: ["orgId"],
  data() {
    return {
      isOpenDialog: false,
      birthday: null,
      firstName: '',
      lastName: '',
      name: '',
      phone: '',
      email: '',
      userRoles: [UserRoleEnum.TESTABLE],
      roles: [new UserRole('TESTABLE')],
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT']),
    addTestable() {
      if (this.$refs.addingForm.validate()) {
        this.isLoading = true
        usersApi.addUser(new UserDTO(null, null, this.firstName, this.lastName, this.birthday,
            this.email, this.phone, this.userRoles, this.orgId))
            .then(response => {
              this.$emit('successAdded', response.data)
              this.$refs.addingForm.reset();
              this.closeDialog();
            }).finally(() => this.isLoading = false)
      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для добавления нового тестируемого, " +
            "проверьте данные и попробуйте снова.");
      }
    },
    saveDateBirthday(date) {
      this.birthday = date
    },
    openDialog() {
      this.isOpenDialog = true;
    },
    closeDialog() {
      this.isOpenDialog = false;
      this.$refs.addingForm.reset();
    }
  }
}
</script>

<style scoped>

</style>