<template>
  <v-row class="pb-5">
    <v-col lg="10" offset-lg="1">
      <v-container class="pb-5">
        <h1 class="display-1 pb-0">Темы</h1>
        <v-card class="pb-10" flat>
          <v-card-title class="pa-0">
            <v-btn v-if="role==='admin'" small color="primary" @click="openDialogCreateTopic">
              Добавить
            </v-btn>
            <v-spacer/>
            <v-text-field class="shrink"
                          v-model="search"
                          @click:clear="clearSearch"
                          @keydown.enter="initGetTopics"
                          @click:prepend="initGetTopics"
                          clearable
                          prepend-icon="mdi-magnify"
                          single-line
                          label="Поиск:"/>
          </v-card-title>
          <v-data-table flat
                        :loading="isTopicsLoading"
                        :headers="headers"
                        :items="topics"
                        @click:row="openTopicPage"
                        hide-default-footer>
            <template v-if="role==='admin'" v-slot:item.actions="{ item }">
              <v-layout justify-end>
                <v-btn x-small icon @click.stop="openTopicUpdateForm(item)">
                  <v-icon small>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn x-small icon @click.stop="remove(item)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </v-layout>
            </template>
            <template v-slot:item.stimulus="{item}">
              <span v-for="(stimulus, index) in item.stimulus" :key="index">{{ stimulus.stimulus }}<br></span>
            </template>
          </v-data-table>
        </v-card>

        <topic-update-form @successUpdate="updateTopic" ref="topicUpdateForm"/>
        <topic-create-form @successCreate="createTopic" ref="topicCreateForm"/>
      </v-container>
    </v-col>
    <v-bottom-navigation
        absolute
        hide-on-scroll
        horizontal
        grow
    >
      <v-pagination v-if="pageCount>1" @input="getTopics" v-model="page" :length="pageCount"/>
    </v-bottom-navigation>
  </v-row>
</template>

<script>
import TopicCreateForm from "./forms/TopicCreateForm.vue";
import TopicUpdateForm from "./forms/TopicUpdateForm";
import {mapActions, mapMutations, mapState} from "vuex";

import topicsApi from '../../../scripts/api/topics-api'

export default {
  components: {TopicCreateForm, TopicUpdateForm},
  computed: {
    ...mapState(['role']),
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.initGetTopics()
    })
  },
  data: () => {
    return {
      search: '',
      headers: [
        {text: 'ID', sortable: false, align: ' d-none', value: 'id'},
        {text: 'Имя темы', value: 'name', sortable: false},
        {text: 'Список стимулов', value: 'stimulus', sortable: false},
        {text: 'Интерпретация низкого уровня риска', value: 'lowRiskLevel', sortable: false},
        {text: 'Интерпретация среднего уровня риска', value: 'midRiskLevel', sortable: false},
        {text: 'Интерпретация высокого уровня риска', value: 'highRiskLevel', sortable: false},
        {text: '', value: 'actions', sortable: false}
      ],
      limit: 8,
      isTopicsLoading: false,
      topics: [],
      page: 1,
      pageCount: 0,
    }
  },
  name: "Topics",
  methods: {
    ...mapMutations('confirmationModule', ['openConfirmationDialog']),
    ...mapActions('topics', ['removeTopic']),
    resetPage() {
      this.page = 1
    },
    initGetTopics() {
      this.resetPage()
      this.getTopics()
    },
    getTopics() {
      this.isTopicsLoading = true
      let offset = this.page - 1
      topicsApi.getTopics(this.search, this.limit, offset)
          .then(response => {
            this.pageCount = response.data.count
            this.topics = response.data.content
          })
          .finally(() => this.isTopicsLoading = false)
    },
    clearSearch() {
      this.search = ''
      this.initGetTopics()
    },
    openDialogCreateTopic() {
      this.$refs.topicCreateForm.openDialog();
    },
    openTopicUpdateForm(item) {
      this.$refs.topicUpdateForm.openDialog(item)
    },
    updateTopic(topic) {
      let needIndex = this.topics.findIndex(t => t.id === topic.id)
      this.topics = [
        ...this.topics.slice(0, needIndex),
        topic,
        ...this.topics.slice(needIndex + 1,)]
    },
    createTopic(topic) {
      if (this.topics.length === this.limit) {
        this.topics = [
          topic,
          ...this.topics.slice(0, this.limit - 1)]
      } else {
        this.topics.push(topic)
      }
    },
    remove(topic) {
      this.openConfirmationDialog({
        description: ' удалить топик "' + topic.name + '"',
        action: () => topicsApi.removeTopic(topic).then(() => {
          let needIndex = this.topics.findIndex(t => t.id === topic.id)
          this.topics = [
            ...this.topics.slice(0, needIndex),
            ...this.topics.slice(needIndex + 1,)]
        })
      })
    },
    openTopicPage(topic) {
      this.$router.push('/admin/topic-info/' + topic.id)
    }
  }
}
</script>

<style scoped>
>>> .v-data-table tr {
  cursor: pointer !important;
}

>>> .v-data-table th {
  font-size: 1.1em;
  font-weight: 700 !important;
}

>>> .v-data-table td {
  font-size: .85em;
}
</style>