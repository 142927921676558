import {get, post, put} from "../../httpclient/http-client";
import store from "../../store";
import ErrorInfo from "../../classes/util/ErrorInfo";

export default {
    getBillingAccount(orgId) {
        let request = get('/api/manager/companies/' + orgId + '/billing-account');
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить billing аккаунт.',
                    request.id)))
    },
    getPayments(orgId, search, limit, offset) {
        let params = {}
        params.limit = limit ?? 0x7fffffff
        params.offset = offset ?? 0
        if (search !== null && search !== undefined && search.length > 0) {
            params.search = search
        }

        let request = get('/api/manager/companies/' + orgId + '/payments', {params: params});
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить детализацию счета.',
                    request.id)))
    },
    getTransactionHistory(orgId, search, limit, offset) {
        let params = {}
        params.limit = limit ?? 0x7fffffff
        params.offset = offset ?? 0
        if (search !== null && search !== undefined && search.length > 0) {
            params.search = search
        }

        let request = get('/api/manager/companies/' + orgId + '/billing/history', {params: params});
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить историю обращения со счетом.',
                    request.id)))
    },
    createPayment(orgId, paymentDto) {
        let request = post('/api/manager/companies/' + orgId + '/billing/payments/', paymentDto)
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке добавить оплату.',
                    request.id)))
    },
    checkAndCreatePayment(ordId, paymentDto) {
        let request = post('/api/manager/companies/' + ordId + '/payments', paymentDto)
        return request.call
    },
    updatePayment(orgId, paymentDto) {
        let request = put('/api/manager/companies/' + orgId + '/billing/payments/', paymentDto)
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке обновить оплату.',
                    request.id)))
    },
    cancelPayment(orgId, paymentId, comment) {
        var config = {
            headers: {
                'Content-Type': 'text/plain'
            }
        }
        let request = post('/api/manager/companies/' + orgId + '/billing/payments/' + paymentId + '/cancel', comment, config)

        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке отменить оплату.',
                    request.id)))
    }
}