<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <h1 class="display-1 pb-3">Назначенные тесты</h1>
        <v-card flat>
          <v-card-title class="pa-0">
            <v-spacer/>
            <v-text-field
                class="ml-10 shrink"
                v-model="search"
                prepend-icon="mdi-magnify"
                clearable
                @click:clear="clearSearch"
                @keydown.enter="initRequestAssignedTests"
                @click:prepend="initRequestAssignedTests"
                single-line
                label="Поиск:"/>
          </v-card-title>
          <v-data-table flat
                        :loading="isTestsLoading"
                        class="mt-5 mb-5"
                        :headers="headers"
                        :items="assignedTests"
                        hide-default-footer
                        @click:row="openAssignedTest"
          >
            <template v-slot:item.actions="{ item }">
              <v-layout justify-end>
                <v-tooltip v-if="item.status.value ==='ASSIGNED' && !isDeletedEntities(item)" bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn small icon @click.stop="cancelAssignedTest(item)" v-on="on">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span class="white--text">Отменить назначенный тест</span>
                </v-tooltip>
              </v-layout>
            </template>
            <template v-slot:item.user.login="{item}">
              {{ item.user.login }}
              <tooltip-user-deleted :size="'small'" v-if="utils.isDeletedEntity(item.user)" :location="'bottom'"/>
            </template>
            <template v-slot:item.test.name="{item}">
              <v-layout align-center justify-start>
                {{ item.test.name }}
                <tooltip-trial-test v-if="item.test.weight===0" :size="20"/>
                <tooltip-test-deleted :size="'small'" v-if="utils.isDeletedEntity(item.test)" :location="'bottom'"/>
                <tooltip-public-test v-if="item.test.isPublic" :size="25"/>
              </v-layout>
            </template>
            <template v-slot:item.status.value="{item}">
              {{ utils.resolveAssignedTestStatus(item.status.value) }}
              ({{ utils.preparedViewDate(item.status.datetime, 'datetime') }})
            </template>
          </v-data-table>
        </v-card>
        <confirmation-dialog ref="confirmationDialog"/>
      </v-container>
    </v-col>
    <v-bottom-navigation
        absolute
        hide-on-scroll
        horizontal
        grow
    >
      <v-pagination v-if="pageCount>1" @input="requestAssignedTests" v-model="page" :length="pageCount"/>
    </v-bottom-navigation>
  </v-row>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'

import ConfirmationDialog from "../../common/ConfirmationDialog.vue";
import TooltipUserDeleted from "../../common/tooltips/TooltipUserDeleted";
import TooltipTestDeleted from "../../common/tooltips/TooltipTestDeleted";
import TooltipTrialTest from "../../common/tooltips/TooltipTrialTest";
import TooltipPublicTest from "../../common/tooltips/TooltipPublicTest";

import orgApi from "../../../scripts/api/org-api"
import utils from "../../../scripts/utils"
import assignedApi from "../../../scripts/api/assigned-api";

export default {
  components: {ConfirmationDialog, TooltipUserDeleted, TooltipTestDeleted, TooltipTrialTest, TooltipPublicTest},
  name: "AssignedTests",
  props: ['orgId'],
  computed: {
    ...mapState(['role']),
  },
  mounted() {
    this.initRequestAssignedTests()
  },
  data() {
    return {
      isTestsLoading: false,
      utils: utils,
      limit: 10,
      assignedTests: [],
      search: '',
      page: 1,
      pageCount: 0,
      headers: [
        {text: 'ID', sortable: false, align: ' d-none', value: 'id'},
        {text: 'Фамилия', value: 'user.lastName', sortable: false},
        {text: 'Имя', value: 'user.firstName', sortable: false},
        {text: 'Тест', value: 'test.name', sortable: false},
        {text: 'Текущий статус', value: 'status.value', sortable: false},
        {text: '', value: 'actions', sortable: false}
      ]
    }
  },
  methods: {
    ...mapMutations('confirmationModule', ['openConfirmationDialog']),
    ...mapActions('managerTests', ['REMOVE_TEST_BY_MANAGER']),
    initRequestAssignedTests() {
      this.resetPage()
      this.requestAssignedTests()
    },
    requestAssignedTests() {
      this.isTestsLoading = true
      let offset = this.page - 1
      orgApi.getAssignedTests(this.orgId, this.search, this.limit, offset)
          .then(response => {
            this.pageCount = response.data.count
            this.assignedTests = response.data.content
          })
          .finally(() => this.isTestsLoading = false)
    },
    resetPage() {
      this.page = 1
    },
    clearSearch() {
      this.search = ''
      this.initRequestAssignedTests()
    },
    topicsToStr(topics) {
      return topics !== null && topics.length > 0 ? topics.map(topic => topic.name).reduce((ac, cur) => ac + ", " + cur) : "..."
    },
    cancelAssignedTest(item) {
      let action = () => assignedApi.cancelAssignedTest(item.id)
          .then(response => {
            let needIndex = this.assignedTests.findIndex(at => at.id === item.id)
            this.assignedTests = [
              ...this.assignedTests.slice(0, needIndex),
              response.data,
              ...this.assignedTests.slice(needIndex + 1,)]
          })

      this.openConfirmationDialog({
        action: action,
        description: ' отменить назначенный тест "' + item.test.name + '" на пользователя "' + item.user.lastName + " " + item.user.firstName +
            '", который был назначен ' + utils.preparedViewDate(item.statuses[0].datetime, 'datetime')
      })
    },
    openAssignedTest(assignedTest) {
      this.$router.push('/manager/assigned-test/' + assignedTest.id);
    },
    isDeletedEntities(assignedTest) {
      return utils.isDeletedEntity(assignedTest.user) || utils.isDeletedEntity(assignedTest.test)
    },
  },
  watch: {
    $route(to) {
      if (to.path === '/manager/assigned-tests/' + this.orgId) {
        this.initRequestAssignedTests()
      }
    }
  }
}
</script>

<style scoped>

</style>
