<template>
  <v-app>
    <v-app-bar
        app
        clipped-left
        v-if="isShowAllContent"
    >
      <v-img @click="openPsyScanSite" contain max-height="55" max-width="180" id="emblemImg"
             src="/images/PsyScanner.png">
      </v-img>
      <v-spacer/>
      <v-btn text color="black" @click="openUserInfo" class="mr-5 pl-2">
        <v-avatar class="pr-2">
          <v-img
              tile
              contain
              alt="Avatar"
              src="/images/instrument.jpg"
              max-height="48"
              max-width="80"
          />
        </v-avatar>
        <span style="font-size: .9em">{{ currentUser.lastName }} {{ currentUser.firstName }}</span>
      </v-btn>
      <!--      <v-divider vertical/>-->
      <!--      <v-tooltip max-width="350" bottom color="grey darken-1">-->
      <!--        <template v-slot:activator="{ on }">-->
      <!--          <v-btn v-on="on" @click="openHelpDialog" class="mr-2 ml-2" icon>-->
      <!--            <v-icon>mdi-help</v-icon>-->
      <!--          </v-btn>-->
      <!--        </template>-->
      <!--        <span class="white&#45;&#45;text">Возник вопрос?</span>-->
      <!--      </v-tooltip>-->
      <v-divider vertical/>
      <v-btn class="ml-2 btn" icon @click="exit">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <!--    Основной контент    -->
    <v-main>
      <v-progress-linear v-show="isMainLoading" indeterminate/>
      <transition name="fade" mode="out-in">
        <keep-alive>
          <router-view :key="$route.fullPath"/>
        </keep-alive>
      </transition>
    </v-main>

    <v-footer
        app
        text-center
        max-height="40px"
        v-if="isShowAllContent"
    >
      <a class="pr-2" style="font-size:0.8em;color: grey !important;"
         href="https://lk.psyscan.ru/files/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf"
         target="_blank">Договор оказания услуг</a>
      <v-divider vertical/>
      <a class="pl-2 pr-2" style="font-size:0.8em;color: grey !important;"
         href="https://lk.psyscan.ru/files/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf"
         target="_blank">Согласие на обработку персональных данных</a>
      <!--        todo заменить на целевую ссылку-->
      <v-divider vertical/>
      <a class="pl-2 pr-2" style="font-size:0.8em;color: grey !important;"
         href="https://lk.psyscan.ru/files/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf"
         target="_blank">Политика в области обработки персональных данных</a>
      <v-spacer/>
    </v-footer>

    <!--    Левая навигационная панель     -->
    <v-navigation-drawer
        style="background-color: #f8f8f8"

        permanent
        v-if="isShowAllContent"
        v-model="isActiveLeftNavigationPanel"
        app
        left
        width="300"
        clipped
    >
      <app-menu/>
      <template v-slot:append>
        <div class="mr-7 ml-10 pl-2 mb-2">
          <v-btn href="/files/PsyScanInstaller.exe" class="mr-1" small text color="primary">
            <v-icon>mdi-download</v-icon>
            Скачать клиента
          </v-btn>
          <v-tooltip max-width="340" right color="grey darken-1">
            <template v-slot:activator="{ on }">
              <v-btn href="/files/Руководство_PsyScan.pdf" target="_blank"
                     v-on="on"
                     elevation="2"
                     x-small
                     class="mb-2"
                     icon>
                <v-icon small>mdi-help</v-icon>
              </v-btn>
            </template>
            <span class="white--text">Скачать инструкцию по эксплуатации клиента.</span>
          </v-tooltip>
        </div>

      </template>
    </v-navigation-drawer>

    <!--    Custom alert    -->
    <custom-alert/>

    <confirmation-dialog/>
    <v-tooltip v-if="isShowAllContent" max-width="280" left color="grey darken-1">
      <template v-slot:activator="{ on }">
        <v-btn href="/files/Руководство_по_эксплуатации_ЛК_PsyScan.pdf" target="_blank" large
               v-on="on"
               class="btn-Help"
               elevation="2"
               icon>
          <v-icon>mdi-help</v-icon>
        </v-btn>
      </template>
      <span class="white--text">Скачать инструкцию по эксплуатации личного кабинета.</span>
    </v-tooltip>

    <help-dialog ref="helpDialog"/>
  </v-app>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import AppMenu from './components/common/AppMenu.vue'
import CustomAlert from "./components/common/CustomAlert.vue";
import HelpDialog from "./components/common/HelpDialog.vue";
import ConfirmationDialog from "./components/common/ConfirmationDialog";
import {get} from "./httpclient/http-client";

export default {
  components: {
    AppMenu,
    CustomAlert,
    ConfirmationDialog,
    HelpDialog
  },
  computed: {
    ...mapState(['token', 'currentUser', 'isMainLoading']),
    isShowAllContent() {
      return Object.keys(this.currentUser).length > 0 &&
          !this.currentRoutePath.includes('failed-payment') &&
          !this.currentRoutePath.includes('success-payment')
    }
  },
  props: {},
  data() {
    return {
      isActiveLeftNavigationPanel: true,
      refreshTokenTask: null,
      currentRoutePath: ''
    }
  },
  methods: {
    ...mapMutations(['clearSession']),
    ...mapMutations(['setToken', 'setCurrentUser']),
    openUserInfo() {
      this.$router.push('/about-me')
    },
    openHelpDialog() {
      this.$refs.helpDialog.handleShow();
    },
    exit() {
      get("/api/manager/auth/logout").call
          .then(() => {
            this.$router.push({path: '/login', addToHistory: false})
            this.clearSession()
            this.$forceUpdate()
          })
    },
    openPsyScanSite() {
      window.open("https://psyscan.ru/", "_self")
    }
  },
  watch: {
    token(newVal, oldVal) {
      if (oldVal === null && newVal !== null) {
        this.refreshTokenTask = setInterval(() => {
          get('/api/manager/auth/refresh').call
              .then(response => this.setToken(response.data.token))
        }, 58_000)
      }
      if (oldVal !== null && newVal === null) {
        clearInterval(this.refreshTokenTask)
      }
    },
    $route(to,) {
      this.currentRoutePath = to.path;
    }
  }

}
</script>
<style>
#emblemImg {
  cursor: pointer;
  opacity: .75;
  transition: .8s;
}

#emblemImg:hover {
  opacity: 1;
  transition: .8s;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.v-item-group.v-bottom-navigation {
  box-shadow: 0px 0px white !important;
}

.grecaptcha-badge {
  display: none;
}

.v-autocomplete__content.v-menu__content {
  min-width: auto !important;
}

.v-menu__content--fixed.menuable__content__active {
  min-width: auto !important;
}

.v-data-table td {
  padding-top: .5em !important;
  padding-bottom: .5em !important;
}

.deletedColor {
  color: #e00000 !important;
}

.successColor {
  color: #008000 !important;
}

.btn-Help {
  z-index: 1000;
  position: absolute;
  bottom: 40px;
  right: 20px;
}
</style>
