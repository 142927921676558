<template>
  <div>
    <v-dialog persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-title>
            Заполните информацию о новом тесте:
          </v-card-title>
          <v-card-text>
            <v-form ref="addingForm">
              <v-text-field label="Наименование *"
                            class="pb-3"
                            v-model="name"
                            :rules="[rules.isRequired, rules.isStringShort]"/>
              <v-autocomplete
                  multiple
                  dense
                  class="pb-3"
                  topics
                  return-object
                  :items="topics"
                  v-model="selectedTopics"
                  item-text="name"
                  :rules="[rules.notEmptyList]"
                  label="Список тем *"/>
              <v-text-field
                  readonly
                  label="Длительность теста *"
                  v-model="estimateTime"
                  type="number"
                  suffix="мин."
              />
              <v-textarea v-model="description"
                          auto-grow
                          rows="1"
                          :rules="[rules.isRequired, rules.isStringLong]"
                          clearable
                          clear-icon="mdi-close"
                          class="pb-3"
                          label="Описание *"/>
              <v-text-field
                  :disabled="isFree"
                  label="Вес *"
                  v-model="weight"
                  min="0"
                  class="pb-3"
                  :rules="[rules.notNegative, rules.isInt32]"
                  type="number"
              />
              <v-layout align-center justify-start>
                <v-img contain :max-height="40" :max-width="40" :src="require('@/assets/images/free-icon.png')"/>
                <v-checkbox v-model="isFree" class=" ml-1 mr-10" label="Пробный тест"/>
                <v-icon large color="primary">mdi-alpha-p-box</v-icon>
                <v-checkbox v-model="isPublic" class="ml-1 " label="Публичный тест"/>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" small dark @click="closeDialog">Отмена</v-btn>
            <v-btn color="primary" small dark class="btn" @click="loadTest" :loading="isLoading">Создать</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import TestDTO from '../../../../classes/dto/TestDTO'

import apiTopics from '../../../../scripts/api/topics-api'
import apiTests from '../../../../scripts/api/tests-api'
import rules from "../../../../scripts/rules";

export default {
  name: "TestAddForm",
  computed: {
    estimateTime() {
      return this.selectedTopics.length
    }
  },
  data() {
    return {
      isOpenDialog: false,
      isFree: false,
      isPublic: false,
      name: '',
      description: '',
      weight: 1,
      topics: [],
      selectedTopics: [],
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT']),
    openDialog() {
      this.requestTopics();
      this.isOpenDialog = true;
    },
    requestTopics() {
      apiTopics.getTopics().then((response) => this.topics = response.data.content)
    },
    loadTest() {
      if (this.$refs.addingForm.validate()) {
        this.isLoading = true
        let testDTO = new TestDTO(null, this.name, this.selectedTopics.map(topic => topic.id), this.description, this.estimateTime,
            [], this.weight, this.isPublic);
        apiTests.addTest(testDTO)
            .then(response => {
              this.$emit('successCreate', response.data)
              this.closeDialog()
            }).finally(() => this.isLoading = false)
      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для добавления нового теста, проверьте данные " +
            "и попробуйте снова.");
      }
    },
    clearForm() {
      this.name = ''
      this.description = ''
      this.weight = 1
      this.isPublic = false
      this.isFree = false
      this.selectedTopics = []
      this.$refs.addingForm.resetValidation()
    },
    closeDialog() {
      this.isOpenDialog = false
      this.clearForm()
    }
  },
  watch: {
    isFree(newVal) {
      if (newVal) {
        this.weight = 0
      } else {
        this.weight = 1
      }
    },
    weight(newVal) {
      if (newVal == 0 && newVal !== '') {
        this.isFree = true
      }
    }
  }
}
</script>

<style scoped>

</style>
