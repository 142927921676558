export default {
    namespaced: true,
    state: {
        messageAlert: '',
        isActiveAlert: false,
        color: 'red lighten-1'
    },
    mutations: {
        ACTIVATE_ALERT(state, message) {
            state.color = 'red lighten-1';
            state.messageAlert = message;
            state.isActiveAlert = true;
        },
        ACTIVATE_ALERT_BASED_ON_CODE(state, data) {
            state.color = 'red lighten-1';
            switch (data.code) {
                case 404: {
                    state.messageAlert = "Ресурс не найден"
                    break
                }
                case 408: {
                    state.messageAlert = "Истекло время ожидания от сервера, повторите попытку позже"
                    break
                }
                case 503:
                case 504: {
                    state.messageAlert = "Сервер недоступен, повторите попытку позже"
                    break
                }
                case 401: {
                    state.messageAlert = "Пользователь не авторизован"
                    break
                }
                case 403: {
                    state.messageAlert = "Доступ запрещен"
                    break
                }
                case 409: {
                    state.messageAlert = "Ресурс уже существует"
                    break
                }
                case 429: {
                    state.messageAlert = "Не пройдена проверка ReCaptcha, попробуйте еще раз"
                    break
                }
                case 402: {
                    state.messageAlert = "Недостаточно средств на балансе"
                    break
                }
                default: {
                    let infoError = data.message === undefined ? "" : "\nОписание: " + data.message;
                    state.messageAlert = "Неожиданная ошибка. Отправьте код ошибки в поддержку. \n" +
                        "Код ошибки: " + data.requestId + ". " + infoError
                }
            }
            state.isActiveAlert = true;
        },
        ACTIVATE_INFO_ALERT(state, message) {
            state.color = 'primary';
            state.messageAlert = message;
            state.isActiveAlert = true;
            setTimeout(() => {
                state.isActiveAlert = false;
                state.messageAlert = '';
            }, 3_000)
        },
        DEACTIVATE_ALERT(state) {
            state.isActiveAlert = false;
            state.messageAlert = '';
        }
    }
}
