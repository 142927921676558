<template>
  <div>
    <v-dialog persistent max-width="800" v-model="isOpenDialog">
      <v-card>
        <v-container>
          <v-card-text>
            <v-form ref="updateForm">
              <v-text-field
                  prepend-icon="mdi-cash-multiple"
                  label="Сумма *"
                  v-model="amount"
                  type="number"
                  min="1"
                  :rules="[rules.isRequired, rules.minSum, rules.isInt32]"/>
              <date-picker label="Срок действия оплаты *" :min="new Date().toISOString().substr(0, 10)"
                           @saveDate="saveExpiredDate" :startValue="expiredDate" ref="datePicker"/>
              <v-textarea
                  rows="1"
                  auto-grow
                  clearable
                  :rules="[rules.isStringShort]"
                  v-model="comment"
                  prepend-icon="mdi-comment-edit-outline"
                  label="Комментарий"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" small @click="closeDialog">Отменить</v-btn>
            <v-btn color="primary" small class="btn" @click="updatePayment" :loading="isLoading">Сохранить</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import billingApi from '../../../scripts/api/billing-api'
import rules from "../../../scripts/rules";

import DatePicker from "../../common/DatePicker";

export default {
  components: {DatePicker},
  name: "UpdatePaymentForm",
  props: ["orgId"],
  data() {
    return {
      isOpenDialog: false,
      payment: {},
      amount: '',
      activePicker: null,
      menu: false,
      expiredDate: null,
      comment: '',
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapActions('users', ['ADD_USER_BY_MANAGER']),
    updatePayment() {
      if (this.$refs.updateForm.validate()) {
        this.isLoading = true
        billingApi.updatePayment(this.orgId, {
          id: this.payment.id,
          status: this.payment.status,
          creationDate: this.payment.creationDate,
          amount: this.amount,
          expiredDate: this.expiredDate,
          comment: this.comment
        }).then(response => {
          this.$emit('successUpdate', response.data)
          this.$emit('increaseBalance', response.data.amount - this.payment.amount)
          this.closeDialog()
        }).finally(() => this.isLoading = false)
      } else {
        this.ACTIVATE_ALERT("Заданы неверные данные для добавления нового сотрудника в организацию " +
            "проверьте данные и попробуйте снова.");
      }
    },
    saveExpiredDate(expiredDate) {
      this.expiredDate = expiredDate
    },
    openDialog(payment) {
      this.payment = payment
      this.expiredDate = payment.expiredDate
      this.amount = payment.amount
      this.comment = payment.comment
      if (this.$refs.datePicker !== undefined) {
        this.$refs.datePicker.updateStartValue(payment.expiredDate)
      }
      this.isOpenDialog = true
    },
    closeDialog() {
      this.isOpenDialog = false
      this.payment = {}
    }
  }
}
</script>

<style scoped>

</style>