<template>
  <div class="d-inline-block">
    <v-btn
        :disabled="isLoading"
        block
        color="primary"
        elevation="2"
        small
        @click.native.stop="downloadReport"
    >
      <div class="pr-2" v-if="isLoading">
        <v-progress-circular
            indeterminate
            width="2"
            size="20"/>
      </div>
      <div class="pr-2" v-else>
        <v-icon>mdi-file-pdf</v-icon>
      </div>
      Скачать отчет
    </v-btn>
  </div>

  <!--    <v-btn-->
  <!--        block-->
  <!--        color="accent"-->
  <!--        elevation="2"-->
  <!--        x-large-->
  <!--        x-small-->
  <!--        @click.native.stop="downloadReport"-->
  <!--    >Скачать PDF отчет-->
  <!--    </v-btn>-->
</template>

<script>
import reportApi from '../../../scripts/api/report-api'

export default {
  name: "DownloadReportButton",
  props: ['assignedTestId'],
  data: () => {
    return {isLoading: false}
  },
  methods: {
    getReport() {
      this.isLoading = true
      reportApi.getReport(this.assignedTestId)
          .then(response => {
            const file = new Blob(
                [response.data],
                {type: 'application/pdf'});

            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, "_blank");
          })
          .finally(() => this.isLoading = false)
    },
    downloadReport() {
      reportApi.downloadReport(this.assignedTestId)
          .then(response => {
            let downloadLink = document.createElement('a');
            downloadLink.target = '_blank';
            let blob = new Blob([response.data], {type: 'application/pdf'});
            downloadLink.download = decodeURIComponent(response.headers["x-file-name"])
            // create an object URL from the Blob
            let URL = window.URL || window.webkitURL;
            let downloadUrl = URL.createObjectURL(blob);
            // set object URL as the anchor's href
            downloadLink.href = downloadUrl;
            // append the anchor to document body
            document.body.appendChild(downloadLink);
            // fire a click event on the anchor
            downloadLink.click();
            // cleanup: remove element and revoke object URL
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(downloadUrl);
          })
    },
  }
}
</script>

<style scoped>
</style>