import {get, post, put} from "../../httpclient/http-client";
import store from "../../store";
import ErrorInfo from "../../classes/util/ErrorInfo";

//todo Сделать пагинацию
const defaultParams = {
    params: {
        limit: 0x7fffffff
    }
}

export default {
    getUsersAssignedToTest(test, search, limit, offset) {
        let params = {}
        if (search !== null && search !== undefined && search.length > 0) {
            params.search = search
        }
        params.limit = limit ?? 0x7fffffff
        params.offset = offset ?? 0
        let request = get('/api/manager/tests/' + test.id + '/users', {params: params});
        return request.call
            .catch(er =>
                store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE', new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить список пользователей на которых назачен тест: "' +
                    test.name + '".', request.id)))
    },
    getTestsAssignedToUser(userId, search, limit, offset) {
        let params = {}
        if (search !== null && search !== undefined && search.length > 0) {
            params.search = search
        }
        params.limit = limit ?? 0x7fffffff
        params.offset = offset ?? 0
        let request = get('/api/manager/users/' + userId + '/tests/assigned', {params: params});
        return request.call
            .catch(er =>
                store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE', new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке выгрузить список тестов, назначенных на пользователя.', request.id
                )))
    },
    getAssignedTestsToUsers(assignedTests, erMessage) {
        let request = post('/api/manager/tests/assigned', assignedTests, defaultParams);
        return request.call
            .catch(er => {
                store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE', new ErrorInfo(er.response.status, erMessage, request.id))
            })
    },
    assignTests(tests) {
        return post('/api/manager/tests/assigned', tests)
    },
    cancelAssignedTest(id) {
        let request = post('/api/manager/tests/assigned/' + id + '/cancel');
        return request.call
            .catch(er => {
                store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE', new ErrorInfo(er.response.status,
                    "Произошла ошибка при попытке отменить назначенный тест. ", request.id))
            })
    },
    refreshCodeAssignTest(id) {
        let request = put('/api/manager/tests/assigned/' + id + '/codes/refresh');
        return request.call
            .catch(er => {
                store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE', new ErrorInfo(er.response.status,
                    "Произошла ошибка при попытке обновить код доступа к назначенному тесту.", request.id))
            })
    },
    getAssignedTest(id) {
        let request = get('/api/manager/tests/assigned/' + id);
        return request.call
            .catch(er => {
                store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE', new ErrorInfo(er.response.status,
                    "Произошла ошибка при попытке получить информацию о назначенном тесте.", request.id))
            })
    }
}


