import {get, post, put, remove} from "../../httpclient/http-client";
import store from "../../store";
import ErrorInfo from "../../classes/util/ErrorInfo";

export default {
    getTopicBy(id) {
        let request = get('/api/manager/topics/' + id);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при выгрузке темы теста.',
                    request.id)))
    },
    getTopics(search, limit, offset) {
        let params = {}
        params.limit = limit ?? 0x7fffffff
        params.offset = offset ?? 0
        if (search !== null && search !== undefined && search.length > 0) {
            params.search = search
        }

        let request = get('/api/manager/topics', {params: params});
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при выгрузке тем тестов.',
                    request.id)))
    },
    addTopic(topic) {
        let request = post('/api/manager/topics', topic);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    "Произошла ошибка при попытке добавления новой темы теста.",
                    request.id)))
    },
    updateTopic(topic) {
        let request = put('/api/manager/topics', topic);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке обновления информации о теме теста "' + topic.name + '"',
                    request.id)))
    },
    removeTopic(topic) {
        let request = remove('/api/manager/topics/' + topic.id);
        return request.call
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке удалить организацию "' + topic.name + '"',
                    request.id)))
    }
}