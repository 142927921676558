import UserRoleEnum from "../enums/UserRoleEnum";

export default class UserRoleDTO {
    constructor(rawValue) {
        this.rawValue = rawValue;
        this.textValue = this.translateValue(rawValue)
    }

    translateValue(val) {
        switch (val) {
            case UserRoleEnum.TESTABLE: {
                return "Испытуемый"
            }
            case UserRoleEnum.MANAGER: {
                return "Менеджер"
            }
            case UserRoleEnum.SUPER_MANAGER: {
                return "Супер менеджер"
            }
            case UserRoleEnum.ADMINISTRATOR: {
                return "Администратор"
            }
            case UserRoleEnum.INDIVIDUAL: {
                return "Физическое лицо"
            }
        }
    }
}
