<template>
  <v-dialog
      persistent
      v-model="isOpenDialog"
      width="700"
  >
    <v-card>
      <v-container>
        <v-card-text class="pb-0">
          <v-form ref="giveOutTestsForm">
            <v-autocomplete v-model="availableTests"
                            dense
                            :readonly="closedTests == null || closedTests.length < 1"
                            :items="closedTests"
                            label="Список тестов *"
                            return-object
                            item-text="name"
                            multiple
                            :rules="[rules.notEmptyList]">
              <template v-slot:prepend-item>
                <v-list-item
                    ripple
                    @click="toggle"
                >
                  <v-list-item-action>
                    <v-icon>
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Выбрать все
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:item="{ item }">
                <v-layout align-center justify-start>
                  <tooltip-trial-test :size="25" v-if="item.weight === 0"/>
                  <tooltip-public-test v-if="item.isPublic"/>
                  {{ item.name }}
                </v-layout>
              </template>
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small dark @click="closeDialog">
            Отмена
          </v-btn>
          <v-btn color="primary" small dark class="btn" @click="assignTestsToCompany" :loading="isLoading">
            Выдать
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapMutations} from "vuex";
import testsApi from '../../../../scripts/api/tests-api'
import orgApi from '../../../../scripts/api/org-api'
import rules from "../../../../scripts/rules";

import TooltipPublicTest from "../../../common/tooltips/TooltipPublicTest";
import TooltipTrialTest from "../../../common/tooltips/TooltipTrialTest";

export default {
  components: {
    TooltipTrialTest, TooltipPublicTest
  },
  name: "AssignTestsToCompanyForm",
  computed: {
    isSelectedAllTests() {
      return this.availableTests.length === this.listTests.length
    },
    isSelectedSomeTests() {
      return this.availableTests.length > 0 && !this.isSelectedAllTests
    },
    icon() {
      if (this.isSelectedAllTests) return 'mdi-close-box'
      if (this.isSelectedSomeTests) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    closedTests() {
      return this.listTests
    }
  },
  props: ['orgId'],
  data() {
    return {
      listTests: [],
      availableTests: [],
      isOpenDialog: false,
      selectAllTests: false,
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT', 'ACTIVATE_ALERT_BASED_ON_CODE', 'ACTIVATE_INFO_ALERT']),
    requestTests() {
      testsApi.getTests().then(response => this.listTests = response.data.content)
    },
    requestTestsCompany() {
      orgApi.getTests(this.orgId)
          .then(response => this.availableTests = response.data.content)
    },
    assignTestsToCompany() {
      this.isLoading = true
      orgApi.assignTests(this.orgId, "CONTRACT", this.availableTests.map(test => test.id))
          .then(() => {
            let strTests = this.availableTests.map(test => test.name).reduce((ac, cur) => ac + ", " + cur)
            this.ACTIVATE_INFO_ALERT("Тесты : " + strTests + " успешно выданы.")
            this.$emit('assignTest')
            this.closeDialog()
          }).finally(() => this.isLoading = false)
    },
    openDialog() {
      this.requestTests()
      this.requestTestsCompany()
      this.isOpenDialog = true
    },
    closeDialog() {
      this.isOpenDialog = false
      this.availableTests = []
      this.listTests = []
      this.$refs.giveOutTestsForm.reset();
    },
    toggle() {
      this.$nextTick(() => {
        if (this.isSelectedAllTests) {
          this.availableTests = []
        } else {
          this.availableTests = this.listTests.slice()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
