<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <h1 class="display-1 pb-0">Пользователи системы</h1>
        <v-card class="pb-10" flat>
          <v-card-title class="pa-0">
            <v-btn small color="primary" @click="openDialogCreateUser">
              Добавить
            </v-btn>
            <v-spacer/>
            <v-text-field clearable
                          @click:clear="clearSearch"
                          @keydown.enter="initGetUsers"
                          @click:prepend="initGetUsers"
                          class="ml-10 shrink"
                          v-model="search"
                          prepend-icon="mdi-magnify"
                          single-line
                          label="Поиск:"/>
          </v-card-title>
          <v-data-table flat
                        :loading="isUsersLoading"
                        :headers="headers"
                        :items="userList"
                        hide-default-footer
                        @click:row="openUserPage"
          >
            <template v-slot:item.org.name="{item}">
              {{ item.org.name }}
              <tooltip-org-deleted v-if="utils.isDeletedEntity(item.org)" :size="'small'" :location="'bottom'"/>
            </template>
            <template v-slot:item.birthday="{item}">
              {{ utils.preparedViewDate(item.birthday, 'date') }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-layout justify-end>
                <v-btn x-small icon @click.stop="openDialogUpdateUser(item)">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn x-small icon @click.stop="deleteUser(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-layout>
            </template>
            <template v-slot:item.roles="{item}">
              {{ translateRoles(item.roles) }}
            </template>
          </v-data-table>
        </v-card>
        <user-create-form @successAdded="addUserInList" ref="userCreateForm"/>
        <user-update-form @successUpdate="updateUserList" ref="userUpdateForm"/>
        <confirmation-dialog ref="confirmationDialog"/>
      </v-container>
    </v-col>
    <v-bottom-navigation
        absolute
        hide-on-scroll
        horizontal
        grow
    >
      <v-pagination v-if="pageCount>1" @input="getUsers" v-model="page" :length="pageCount"/>
    </v-bottom-navigation>
  </v-row>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import utils from "../../scripts/utils";

import UserCreateForm from "./forms/UserCreateForm.vue";
import UserUpdateForm from "./forms/UserUpdateForm.vue";
import {translateRoles} from "../../utils/DataUtils";
import ConfirmationDialog from "../common/ConfirmationDialog";
import usersApi from "../../scripts/api/users-api"
import TooltipOrgDeleted from "../common/tooltips/TooltipOrgDeleted";

export default {
  components: {UserCreateForm, UserUpdateForm, ConfirmationDialog, TooltipOrgDeleted},
  computed: {
    ...mapState(['currentUser', 'role'])
  },
  name: "Users",
  data() {
    return {
      utils: utils,
      page: 1,
      pageCount: 0,
      search: '',
      isUsersLoading: false,
      userList: [],
      limit: 10,
      headers: [
        {text: 'ID', sortable: false, align: ' d-none', value: 'id'},
        {text: 'Логин', value: 'login', sortable: false},
        {text: 'Фамилия', value: 'lastName', sortable: false},
        {text: 'Имя', value: 'firstName', sortable: false},
        {text: 'Роль', value: 'roles', sortable: false},
        {text: 'Организация', value: 'org.name', sortable: false},
        {text: 'Дата рождения', value: 'birthday', sortable: false},
        {text: 'Email', value: 'email', sortable: false},
        {text: 'Номер телефона', value: 'phoneNumber', sortable: false},
        {text: '', value: 'actions', sortable: false},
      ],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (Object.keys(vm.currentUser).length === 0) {
        return
      }
      vm.initGetUsers()
    })
  },
  methods: {
    ...mapMutations('confirmationModule', ['openConfirmationDialog']),
    deleteUser(user) {
      this.openConfirmationDialog({
        action: () => usersApi.removeUser(user).then(() => {
          let needIndex = this.userList.findIndex(at => at.id === user.id)
          this.userList = [
            ...this.userList.slice(0, needIndex),
            ...this.userList.slice(needIndex + 1,)]
        }), description: ' удалить пользователя "' + user.lastName + " " + user.firstName + '"'
      })
    },
    openUserPage(user) {
      this.$router.push('/manager/user-info/' + user.id);
    },
    openDialogCreateUser() {
      this.$refs.userCreateForm.openDialog();
    },
    openDialogUpdateUser(user) {
      this.$refs.userUpdateForm.openDialog(user);
    },
    translateRoles(roles) {
      return translateRoles(roles)
    },
    updateUserList(user) {
      let needIndex = this.userList.findIndex(us => us.id === user.id)
      this.userList = [
        ...this.userList.slice(0, needIndex),
        user,
        ...this.userList.slice(needIndex + 1,)]
    },
    addUserInList(user) {
      if (this.userList.length === this.limit) {
        this.userList = [
          user,
          ...this.userList.slice(0, this.limit - 1)]
      } else {
        this.userList.push(user)
      }
    },
    clearSearch() {
      this.search = ''
      this.initGetUsers()
    },
    resetPage() {
      this.page = 1
    },
    initGetUsers() {
      this.resetPage()
      this.getUsers()
    },
    getUsers() {
      this.isUsersLoading = true
      let offset = this.page - 1
      usersApi.getUsers(this.search, this.limit, offset)
          .then(response => {
            this.pageCount = response.data.count
            this.userList = response.data.content
          })
          .finally(() => this.isUsersLoading = false)
    }
  }
}
</script>

<style scoped>
>>> .v-data-table tr {
  cursor: pointer !important;
}

>>> .v-data-table th {
  font-size: 1.1em;
  font-weight: 700 !important;
}

>>> .v-data-table td {
  font-size: .85em;
}
</style>
