var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pb-5 ma-0",attrs:{"flat":"","min-height":"500"}},[_c('v-data-table',{attrs:{"flat":"","loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.tests,"hide-default-footer":""},on:{"click:row":_vm.openAssignedTest},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",attrs:{"prepend-icon":"mdi-magnify","label":"Поиск","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.initRequestAssignedTests.apply(null, arguments)},"click:prepend":_vm.initRequestAssignedTests,"click:clear":_vm.clearSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.test.name",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"align-center":"","justify-start":""}},[_vm._v(" "+_vm._s(item.test.name)+" "),(item.test.weight === 0)?_c('tooltip-trial-test',{attrs:{"size":20}}):_vm._e(),(item.test.isPublic)?_c('tooltip-public-test',{attrs:{"size":'small'}}):_vm._e(),(item.test.state==='DELETED')?_c('tooltip-test-deleted',{attrs:{"size":'small',"location":'bottom'}}):_vm._e()],1)]}},(_vm.role!=='admin')?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-end":""}},[(item.status.value ==='ASSIGNED' && !_vm.isDeletedEntities(item))?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.cancelAssignedTest(item)}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Отменить назначенный тест")])]):_vm._e()],1)]}}:null,{key:"item.expiredDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.utils.preparedViewDate(item.expiredDate, 'date'))+" ")]}},{key:"item.status.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.utils.resolveAssignedTestStatus(item.status.value))+" ("+_vm._s(_vm.utils.preparedViewDate(item.status.datetime, 'datetime'))+") ")]}}],null,true)}),_c('v-bottom-navigation',{attrs:{"absolute":"","hide-on-scroll":"","horizontal":"","grow":""}},[(_vm.pageCount>1)?_c('v-pagination',{attrs:{"length":_vm.pageCount},on:{"input":_vm.requestAssignedTests},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }