var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"mt-5 mb-5",attrs:{"height":"50vh","loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.transactions,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-layout',{attrs:{"justify-end":"","align-center":""}},[_c('v-text-field',{staticClass:"ml-10 shrink",attrs:{"clearable":"","prepend-icon":"mdi-magnify","single-line":"","label":"Поиск:"},on:{"click:clear":_vm.clearSearch,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.initGetTransactionHistory.apply(null, arguments)},"click:prepend":_vm.initGetTransactionHistory},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.test.test.name",fn:function(ref){
var item = ref.item;
return [(item.test!==null)?_c('router-link',{staticStyle:{"color":"black","text-decoration":"none"},attrs:{"to":'/manager/assigned-test/'+item.test.id}},[_c('v-layout',{attrs:{"align-center":"","justify-start":""}},[_vm._v(" "+_vm._s(item.test.test.name)+" "),(item.test.test.weight === 0)?_c('tooltip-trial-test',{attrs:{"size":30}}):_vm._e(),(item.test.test.isPublic)?_c('tooltip-public-test'):_vm._e(),(_vm.utils.isDeletedEntity(item.test.test))?_c('tooltip-test-deleted',{attrs:{"size":'small',"location":'bottom'}}):_vm._e()],1)],1):_vm._e()]}},{key:"item.test.user",fn:function(ref){
var item = ref.item;
return [(item.test!==null)?_c('router-link',{staticStyle:{"color":"black","text-decoration":"none"},attrs:{"to":'/manager/user-info/'+item.test.user.id}},[_vm._v(" "+_vm._s(item.test.user.lastName)+" "+_vm._s(item.test.user.firstName)+" "),(_vm.utils.isDeletedEntity(item.test.user))?_c('tooltip-user-deleted',{attrs:{"size":'small',"location":'bottom'}}):_vm._e()],1):_vm._e()]}},{key:"item.manager",fn:function(ref){
var item = ref.item;
return [(item.manager!==null)?_c('router-link',{staticStyle:{"color":"black","text-decoration":"none"},attrs:{"to":'/manager/user-info/'+item.manager.id}},[_vm._v(" "+_vm._s(item.manager.lastName)+" "+_vm._s(item.manager.firstName)+" "),(_vm.utils.isDeletedEntity(item.manager))?_c('tooltip-user-deleted',{attrs:{"size":'small',"location":'bottom'}}):_vm._e()],1):_vm._e()]}},{key:"item.datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.utils.preparedViewDate(item.datetime, 'datetime'))+" ")]}},{key:"item.test.user.lastName",fn:function(ref){
var item = ref.item;
return [(item.test!==null)?_c('span',{attrs:{"id":"lastNameTestableUser"}},[_vm._v(_vm._s(item.test.user.lastName))]):_vm._e()]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount>0)?_c('span',{staticStyle:{"color":"#008000"}},[_vm._v("+"+_vm._s(_vm.utils.viewAmount(item.amount))+" ")]):_c('span',{staticStyle:{"color":"darkred"}},[_vm._v(_vm._s(_vm.utils.viewAmount(item.amount)))])]}}])}),(_vm.pageCount>1)?_c('v-pagination',{attrs:{"length":_vm.pageCount},on:{"input":_vm.getTransactionHistory},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }