<template>
  <div>
    <v-data-table height="45vh"
                  :loading="isLoading"
                  :headers="headers"
                  :items="availableTests"
                  hide-default-footer
                  @click:row="openTestPage">
      <template v-slot:top>
        <v-layout justify-space-between align-center>
          <v-btn v-if="role==='admin' && !utils.isDeletedEntity(currentViewOrg)" class="mr-5" color="primary" small
                 @click="openTestsIssuanceCompanyForm">
            Выдать тесты
          </v-btn>
          <v-btn v-if="!utils.isDeletedEntity(currentViewOrg)" :to='"/manager/assigned-tests/" + orgId' color="primary"
                 small>
            Назначенные тесты
          </v-btn>

          <v-spacer/>
          <v-text-field class="ml-10 shrink"
                        v-model="search"
                        clearable
                        @click:clear="clearSearch"
                        @click:prepend="initRequestTestsCompany"
                        @keydown.enter="initRequestTestsCompany"
                        prepend-icon="mdi-magnify"
                        single-line
                        label="Поиск:"/>
        </v-layout>
      </template>
      <template v-slot:item.statuses="{ item }">
        <v-layout align-center justify-start>
          <tooltip-trial-test :size="25" v-if="item.weight === 0"/>
          <tooltip-public-test v-if="item.isPublic"/>
        </v-layout>
      </template>
      <template v-slot:item.topics="{item}">
        {{ topicsToStr(item.topics) }}
      </template>
    </v-data-table>
    <v-pagination v-if="pageCount>1" @input="requestTestsCompany" v-model="page" :length="pageCount"/>
    <assign-tests-to-company-form @assignTest="requestTestsCompany" :orgId="orgId" ref="testsIssuanceCompanyForm"/>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import orgApi from "../../../../scripts/api/org-api";
import utils from "../../../../scripts/utils";

import AssignTestsToCompanyForm from "../forms/AssignTestsToCompanyForm.vue";
import TooltipTrialTest from "../../../common/tooltips/TooltipTrialTest";
import TooltipPublicTest from "../../../common/tooltips/TooltipPublicTest";

export default {
  name: "AvailableTestsTable",
  components: {
    AssignTestsToCompanyForm,
    TooltipTrialTest,
    TooltipPublicTest
  },
  computed: {
    ...mapState(['role', 'currentViewOrg'])
  },
  props: ['orgId'],
  data() {
    return {
      utils: utils,
      isLoading: false,
      page: 1,
      pageCount: 0,
      limit: 7,
      availableTests: [],
      headers: [
        {text: 'ID', sortable: false, align: ' d-none', value: 'id'},
        {text: '', value: 'statuses', sortable: false},
        {text: 'Тест', value: 'name', sortable: false},
        {text: 'Темы', value: 'topics', sortable: false},
        {text: '', value: 'actions', sortable: false}
      ],
      search: '',
    }
  },
  mounted() {
    this.requestTestsCompany()
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT_BASED_ON_CODE']),
    openTestsIssuanceCompanyForm() {
      this.$refs.testsIssuanceCompanyForm.openDialog(this.orgId)
    },
    openTestPage(test) {
      this.$router.push('/manager/test-info/' + test.id);
    },
    topicsToStr(topics) {
      return topics !== null && topics.length > 0 ? topics.map(topic => topic.name).reduce((ac, cur) => ac + ", " + cur) : "..."
    },
    clearSearch() {
      this.search = ''
      this.initRequestTestsCompany()
    },
    initRequestTestsCompany() {
      this.resetPage()
      this.requestTestsCompany()
    },
    resetPage() {
      this.page = 1
    },
    requestTestsCompany() {
      if (this.orgId === undefined) {
        return
      }
      let offset = this.page - 1
      this.isLoading = true
      orgApi.getTests(this.orgId, this.search, this.limit, offset)
          .then(response => {
            this.pageCount = response.data.count
            this.availableTests = response.data.content
          })
          .finally(() => this.isLoading = false)
    }
  }
}
</script>

<style scoped>
</style>
