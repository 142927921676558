import {post} from "../../httpclient/http-client";
import store from "../../store";
import ErrorInfo from "../../classes/util/ErrorInfo";

export default {
    sendEmailConfirmation(userId) {
        let request = post('/api/manager/users/' + userId + '/send/email/confirmation')
        return request.call
            .then(() =>
                this.ACTIVATE_INFO_ALERT("Письмо с подтверждением отправлено на почту."))
            .catch(er => store.commit('customAlertModule/ACTIVATE_ALERT_BASED_ON_CODE',
                new ErrorInfo(er.response.status,
                    'Произошла ошибка при попытке отправить сообщение для подтверждения почты.',
                    request.id)))
    },
    confirmEmail(uuid) {
        return post('/api/manager/operations/' + uuid + '/confirm/email')
    }

}