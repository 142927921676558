<template>
  <v-row>
    <v-col lg="10" offset-lg="1">
      <v-container>
        <v-row>
          <v-col class="pb-0" xl="12" lg="12" md="12" sm="12">
            <h1 class="display-1">{{ currentUser.login }}</h1>
          </v-col>
          <v-col xl="12" lg="12" md="12" sm="12">
            <v-btn class="mr-5" small color="primary" @click="openUserUpdateForm">
              <v-icon class="pr-2">mdi-pencil-outline</v-icon>
              Редактировать
            </v-btn>
            <v-btn small color="primary" @click="openChangePassForm">
              <v-icon class="pr-2">mdi-lock-reset</v-icon>
              Сменить пароль
            </v-btn>
            <v-spacer/>
          </v-col>
        </v-row>

        <v-row>
          <v-col lg="4" md="4" sm="4">
            <v-img eager
                   ref="mainAccountImg"
                   class="white--text align-end"
                   height="250"
                   src="/images/instrument.jpg"/>
          </v-col>
          <v-col lg="8" md="8" sm="8">
            <v-row>
              <v-col lg="6" md="6" sm="6">
                <b>Имя: </b>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                {{ currentUser.firstName }}
              </v-col>
              <v-col lg="6" md="6" sm="6">
                <b>Фамилия:</b>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                {{ currentUser.lastName }}
              </v-col>
              <v-col lg="6" md="6" sm="6">
                <b>Дата рождения: </b>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                {{ currentUser.birthday }}
              </v-col>
              <v-col lg="6" md="6" sm="6">
                <b>Адрес электронной почты: </b>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                <email-user-info :user="currentUser"/>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                <b>Номер телефона:</b>
              </v-col>
              <v-col lg="6" md="6" sm="6">
                {{ currentUser.phoneNumber }}
              </v-col>
              <v-col lg="6" md="6" sm="6" v-if="role!=='individual'">
                <b>Организация</b>
              </v-col>
              <v-col lg="6" md="6" sm="6" v-if="role!=='individual'">
                <router-link style="font-size: .9em"
                             :class="utils.isDeletedEntity(organization)? 'mr-2 deletedColor':'mr-2 successColor'"
                             :to="{name:'organizationsInfo', params: { orgId: organization.id, role: role }}">
                  <b> "{{ organization.name }}"</b>
                </router-link>
                <tooltip-org-deleted v-if="utils.isDeletedEntity(organization)" :location="'bottom'"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>


    </v-col>
    <user-update-form @successUpdate="successUpdate" ref="userUpdateForm"/>
    <change-pass-form ref="changePassForm"/>
  </v-row>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";

import utils from "../../scripts/utils";

import UserUpdateForm from "../users/forms/UserUpdateForm";
import ChangePassForm from "../auth/forms/ChangePassForm.vue";
import EmailUserInfo from "./EmailUserInfo.vue";
import TooltipOrgDeleted from "../common/tooltips/TooltipOrgDeleted";

export default {
  name: "AboutMePage",
  components: {UserUpdateForm, ChangePassForm, EmailUserInfo, TooltipOrgDeleted},
  computed: {
    ...mapState(['currentUser', 'role']),
    ...mapGetters('organizations', ['GET_ORG_BY_ID']),
    organization() {
      if (Object.keys(this.currentUser).length === 0) {
        return ''
      }
      let org = this.currentUser.org;
      if (org === null || org === undefined) {
        return ''
      }
      return org

    }
  },
  data: () => {
    return {
      utils: utils,
      org: {},
    }
  },
  methods: {
    ...mapMutations(['setCurrentUser']),
    successUpdate(user) {
      this.currentUser.birthday = user.birthday
      this.currentUser.firstName = user.firstName
      this.currentUser.lastName = user.lastName
      this.currentUser.phoneNumber = user.phoneNumber
    },
    openUserUpdateForm() {
      this.$refs.userUpdateForm.openDialog(this.currentUser);
    },
    openChangePassForm() {
      this.$refs.changePassForm.openDialog();
    }
  }
}
</script>

<style scoped>

</style>