<template>
  <v-dialog
      persistent
      v-model="isOpenDialog"
      max-width="400"
  >
    <v-card>
      <v-container>
        <v-card-text class="pb-0">
          <v-form ref="giveOutTestForm">
            <v-autocomplete v-model="selectedCompanies"
                            dense
                            :readonly="listOrgs == null || listOrgs.length < 1"
                            :items="listOrgs"
                            label="Список организаций *"
                            return-object
                            item-text="name"
                            multiple
                            :rules="[rules.notEmptyList]">
              <template v-slot:prepend-item>
                <v-list-item
                    ripple
                    @click="toggle"
                >
                  <v-list-item-action>
                    <v-icon>
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Выбрать все
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small dark @click="closeDialog">
            Отмена
          </v-btn>
          <v-btn color="primary" small dark class="btn" @click="giveOutTests" :loading="isLoading">
            Выдать
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapMutations} from "vuex";
import orgApi from '../../../../scripts/api/org-api'
import rules from "../../../../scripts/rules";

export default {
  name: "AssignTestsToCompanyForm",
  computed: {
    isSelectedAllTests() {
      return this.selectedCompanies.length === this.listOrgs.length
    },
    isSelectedSomeTests() {
      return this.selectedCompanies.length > 0 && !this.isSelectedAllTests
    },
    icon() {
      if (this.isSelectedAllTests) return 'mdi-close-box'
      if (this.isSelectedSomeTests) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  data() {
    return {
      listOrgs: [],
      testId: null,
      selectedCompanies: [],
      isOpenDialog: false,
      selectAllTests: false,
      rules: rules,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('customAlertModule', ['ACTIVATE_ALERT', 'ACTIVATE_ALERT_BASED_ON_CODE', 'ACTIVATE_INFO_ALERT']),
    giveOutTests() {
      this.isLoading = true
      alert("В будущем выдадим доступ к тестам данной организации")
      this.isLoading = false
    },
    requestOrgs() {
      orgApi.getOrgs().then(response => this.listOrgs = response.data.content)
    },
    openDialog(testId) {
      this.requestOrgs()
      this.testId = testId
      this.isOpenDialog = true
    },
    closeDialog() {
      this.isOpenDialog = false
      this.selectedCompanies = []
      this.listOrgs = []
      this.testId = null
      this.$refs.giveOutTestForm.reset();
    },
    toggle() {
      this.$nextTick(() => {
        if (this.isSelectedAllTests) {
          this.selectedCompanies = []
        } else {
          this.selectedCompanies = this.listOrgs.slice()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
