import axios from "axios"
import store from '@/store'
import router from '@/router'
import {v4 as uuidv4} from 'uuid'

const instance = axios.create({
    timeout: 30_000
})

instance.interceptors.request.use(function (config) {
    if (store.state.token !== null) {
        config.headers.Authorization = "Bearer " + store.state.token
    }
    return config
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    if (response?.config?.method !== 'get') {
        store.commit('customAlertModule/DEACTIVATE_ALERT')
    }
    return response;
}, error => {
    let finalError
    if (error.message?.includes("timeout of 30000")) {
        finalError = {
            response: {
                status: 408,
                message: error.message
            }
        }
    } else {
        finalError = error
    }

    if (finalError.response.status === 401 && router.currentRoute.path !== 'login') {
        router.push({path: '/login', addToHistory: false})
        store.commit('clearSession', null, {root: true})
    }

    return Promise.reject(finalError);
});

function get(url, config) {
    let requestId = uuidv4()
    let preparedConfig = prepareRequestConfig(config, requestId)
    return {
        call: instance.get(url, preparedConfig), id: requestId
    }
}

function post(url, data, config) {
    let requestId = uuidv4()
    let preparedConfig = prepareRequestConfig(config, requestId)
    return {
        call: instance.post(url, data, preparedConfig), id: requestId
    }
}

function put(url, data, config) {
    let requestId = uuidv4()
    let preparedConfig = prepareRequestConfig(config, requestId)
    return {
        call: instance.put(url, data, preparedConfig), id: requestId
    }
}

function remove(url, config) {
    let requestId = uuidv4()
    let preparedConfig = prepareRequestConfig(config, requestId)
    return {
        call: instance.delete(url, preparedConfig), id: requestId
    }
}

function prepareRequestConfig(sourceConfig, requestId) {
    if (sourceConfig === null || sourceConfig === undefined) {
        sourceConfig = {headers: {}}
    } else if (sourceConfig.headers === undefined) {
        sourceConfig.headers = {}
    }
    sourceConfig.headers.RequestId = requestId
    return sourceConfig
}

export {get, post, put, remove}