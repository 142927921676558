<template>
  <v-dialog
      v-model="isShow"
      max-width="750"
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" small @click="isShow = false">
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HelpDialog",
  data: () => {
    return {isShow: false}
  },
  methods: {
    handleShow() {
      this.isShow = !this.isShow
    }
  }
}
</script>

<style scoped>

</style>