var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"lg":"10","offset-lg":"1"}},[_c('v-container',[_c('h1',{staticClass:"display-1 pb-0"},[_vm._v("Пользователи системы")]),_c('v-card',{staticClass:"pb-10",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.openDialogCreateUser}},[_vm._v(" Добавить ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ml-10 shrink",attrs:{"clearable":"","prepend-icon":"mdi-magnify","single-line":"","label":"Поиск:"},on:{"click:clear":_vm.clearSearch,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.initGetUsers.apply(null, arguments)},"click:prepend":_vm.initGetUsers},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"flat":"","loading":_vm.isUsersLoading,"headers":_vm.headers,"items":_vm.userList,"hide-default-footer":""},on:{"click:row":_vm.openUserPage},scopedSlots:_vm._u([{key:"item.org.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.org.name)+" "),(_vm.utils.isDeletedEntity(item.org))?_c('tooltip-org-deleted',{attrs:{"size":'small',"location":'bottom'}}):_vm._e()]}},{key:"item.birthday",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.utils.preparedViewDate(item.birthday, 'date'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDialogUpdateUser(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteUser(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translateRoles(item.roles))+" ")]}}])})],1),_c('user-create-form',{ref:"userCreateForm",on:{"successAdded":_vm.addUserInList}}),_c('user-update-form',{ref:"userUpdateForm",on:{"successUpdate":_vm.updateUserList}}),_c('confirmation-dialog',{ref:"confirmationDialog"})],1)],1),_c('v-bottom-navigation',{attrs:{"absolute":"","hide-on-scroll":"","horizontal":"","grow":""}},[(_vm.pageCount>1)?_c('v-pagination',{attrs:{"length":_vm.pageCount},on:{"input":_vm.getUsers},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }